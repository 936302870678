import React, { useCallback } from "react";
import { FlatList, View, Text, TouchableOpacity } from "react-native";
import "react-loading-skeleton/dist/skeleton.css";

//styles
import styles from "../../../../styles/filter.feed.style";
import BreakingFilterLoading from "../breaking/breaking.feed.loading";
import { MemoizedMensFashionCard } from "../../../cards/fashion/MensFashionCard";

function FashionFilterFeed({
  //data
  topFashionData,
  mensFashionData,
  womensFashionData,

  //error
  error,

  //onPress
  onTopFashionPress,
  onMensFashionPress,
  onWomensFashionPress,

  //loading
  loading,

  //show
  showTopFashion,
  showMensFashion,
  showWomensFashion,
}) {
  const renderItem = ({ item }) => <MemoizedMensFashionCard mens={item} />;

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const ITEM_HEIGHT = 350;

  const getItemLayout = useCallback(
    (data, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    []
  );

  if (loading) {
    return <BreakingFilterLoading />;
  }

  if (error === true) {
    return null;
  }
  return (
    <View style={styles.wrap}>
      <View>
        <Text style={styles.headerText}>FASHION</Text>
      </View>
      <View style={styles.headerWrap}>
        <View style={styles.filterButtonWrap}>
          <TouchableOpacity
            onPress={onTopFashionPress}
            style={[
              styles.button,
              {
                backgroundImage: showTopFashion
                  ? "linear-gradient(to top right, rgba(0,0,0) 60%,rgb(247, 220, 111  ))"
                  : null,
                shadowOffset: showTopFashion
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showTopFashion ? 0.98 : 0,
                shadowRadius: showTopFashion ? 3.0 : 0,
                elevation: showTopFashion ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showTopFashion ? "#fff" : "#fff" },
              ]}
            >
              Trending
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onWomensFashionPress}
            style={[
              styles.button,
              {
                backgroundImage: showWomensFashion
                  ? "linear-gradient(to top right, rgba(0,0,0), rgba(91, 44, 111))"
                  : null,
                shadowOffset: showWomensFashion
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showWomensFashion ? 0.98 : 0,
                shadowRadius: showWomensFashion ? 3.0 : 0,
                elevation: showWomensFashion ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showWomensFashion ? "#fff" : "#fff" },
              ]}
            >
              Womens
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={onMensFashionPress}
            style={[
              styles.button,
              {
                backgroundImage: showMensFashion
                  ? "linear-gradient(to top right, rgba(0,0,0) 44%, rgba(27, 79, 114))"
                  : null,
                shadowOffset: showMensFashion
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showMensFashion ? 0.98 : 0,
                shadowRadius: showMensFashion ? 3.0 : 0,
                elevation: showMensFashion ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showMensFashion ? "#fff" : "#fff" },
              ]}
            >
              Mens
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      {showTopFashion && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          data={topFashionData}
          indicatorStyle
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showWomensFashion && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          renderToHardwareTextureAndroid
          data={womensFashionData}
          indicatorStyle
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showMensFashion && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          data={mensFashionData}
          indicatorStyle
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
    </View>
  );
}

export default FashionFilterFeed;
