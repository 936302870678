import React, { useCallback, Fragment } from "react";
import { FlatList, View, Text, TouchableOpacity } from "react-native";
import "react-loading-skeleton/dist/skeleton.css";

//styles
import styles from '../../../../styles/filter.feed.style';
import BreakingFilterLoading from "../breaking/breaking.feed.loading";

import { MemoizedBookCard } from "../../../cards/books/BookCard";

function BookFilterFeed({
  //data
  topBooksData,
  newReleasesBookData,
  comingSoonBookData,

  //error
  error,

  //onPress
  onTopBooksPress,
  onNewReleasesPress,
  onComingSoonPress,

  //loading
  loading,

  //show
  showTopBooks,
  showNewReleases,
  showComingSoon,
}) {
  const renderItem = ({ item }) => <MemoizedBookCard book={item} />;

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const ITEM_HEIGHT = 350;

  const getItemLayout = useCallback(
    (data, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    []
  );

  if (loading) {
    return <BreakingFilterLoading />;
  }

  if (error === true) {
    return null;
  }
  return (
    <View style={styles.wrap}>
      <View>
        <Text style={styles.headerText}>BOOKS</Text>
      </View>
      <View style={styles.headerWrap}>
        <View style={styles.filterButtonWrap}>
          <TouchableOpacity
            onPress={onTopBooksPress}
            style={[
              styles.button,
              {
                backgroundImage: showTopBooks
                  ? "linear-gradient(to top right, rgba(0,0,0) 60%,rgb(247, 220, 111  ))"
                  : null,
                shadowOffset: showTopBooks
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showTopBooks ? 0.98 : 0,
                shadowRadius: showTopBooks ? 3.0 : 0,
                elevation: showTopBooks ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[styles.header, { color: showTopBooks ? "#fff" : "#fff" }]}
            >
              Top 50
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onNewReleasesPress}
            style={[
              styles.button,
              {
                backgroundImage: showNewReleases
                  ? "linear-gradient(to top right, rgba(0,0,0), rgba(91, 44, 111))"
                  : null,
                shadowOffset: showNewReleases
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showNewReleases ? 0.98 : 0,
                shadowRadius: showNewReleases ? 3.0 : 0,
                elevation: showNewReleases ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showNewReleases ? "#fff" : "#fff" },
              ]}
            >
              New Releases
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={onComingSoonPress}
            style={[
              styles.button,
              {
                backgroundImage: showComingSoon
                  ? "linear-gradient(to top right, rgba(0,0,0) 44%, rgba(27, 79, 114))"
                  : null,
                shadowOffset: showComingSoon
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showComingSoon ? 0.98 : 0,
                shadowRadius: showComingSoon ? 3.0 : 0,
                elevation: showComingSoon ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showComingSoon ? "#fff" : "#fff" },
              ]}
            >
              Coming Soon
            </Text>
          </TouchableOpacity>
        </View>

        {/* <TouchableOpacity
                    onPress={onRedditPress}
                    style={[styles.button,]}
                >
                    <Text style={[styles.header, { color: showReddit ? '#000' : '#dadada' }]}>Reddit</Text>
                </TouchableOpacity> */}
      </View>
      {showTopBooks && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={topBooksData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showNewReleases && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={newReleasesBookData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          //keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showComingSoon && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={comingSoonBookData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          //keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
    </View>
  );
}

export default BookFilterFeed;
