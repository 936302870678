import React, { useState, useEffect, Fragment } from "react";
import { ScrollView, Text, StyleSheet, View } from "react-native";
import TopHeader from "../navigation/TopHeader";
import MensFashionFeed from "../components/feeds/fashion/MensFashionFeed";
export default function MensScreen() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isMensFashionScreenActive, setIsMensFashionScreenActive] =
    useState(true);
  const [bestSellerMensClothing, setBestSellerMensClothing] = useState([]);
  const [newMensClothing, setNewMensClothing] = useState([]);
  const [topMensJeans, setTopMensJeans] = useState([]);
  const [bestSellerAccessories, setBestSellerAccessories] = useState([]);
  const [bestSellerShoes, setBestSellerShoes] = useState([]);
  const [topActiveWear, setTopActiveWear] = useState([]);
  const [topDesigner, setTopDesigner] = useState([]);
  const [topHoodie, setTopHoodie] = useState([]);
  const [topJackets, setTopJackets] = useState([]);
  const [topLoungewear, setTopLoungewear] = useState([]);
  const [topPants, setTopPants] = useState([]);
  const [topPolos, setTopPolos] = useState([]);
  const [topShirts, setTopShirts] = useState([]);
  const [topShorts, setTopShorts] = useState([]);
  const [topSocks, setTopSocks] = useState([]);
  const [topSuits, setTopSuits] = useState([]);
  const [topSweaters, setTopSweaters] = useState([]);
  const [topSwimwear, setTopSwimwear] = useState([]);
  const [topTracksuits, setTopTracksuits] = useState([]);
  const [topTshirts, setTopTshirts] = useState([]);
  const [topTwoPiece, setTopTwoPiece] = useState([]);
  const [topUnderwear, setTopUnderwear] = useState([]);

  //Fetch Products
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://youtapia-temp8.uc.r.appspot.com/v1/api/feed/1301,1302,1303,1304,1306,1308,1309,1310,1311,1312,1313,1314,1315,1316,1317,1318,1319,1320,1321,1322,1323,1324`,
        {
          method: "GET",
          credentials: "same-origin",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      const mensBestSellers = data[0].cards;
      const newMens = data[1].cards;
      const jeansMen = data[2].cards;
      const mensBestSellerAccessoriesData = data[3].cards;
      const mensBestSellerShoesData = data[4].cards;
      const topActiveWearData = data[5].cards;
      const topDesignerData = data[6].cards;
      const topHoodieData = data[7].cards;
      const topJacketData = data[8].cards;
      const topLoungewearData = data[9].cards;
      const topPantsData = data[10].cards;
      const topPolosData = data[11].cards;
      const topShirtsData = data[12].cards;
      const topShortsData = data[13].cards;
      const topSocksData = data[14].cards;
      const topSuitsData = data[15].cards;
      const topSweatersData = data[16].cards;
      const topSwimwearData = data[17].cards;
      const topTracksuitsData = data[18].cards;
      const topTshirtsData = data[19].cards;
      const topTwoPieceData = data[20].cards;
      const topUnderwearData = data[21].cards;

      //console.log(data);
      setBestSellerMensClothing(mensBestSellers);
      setNewMensClothing(newMens);
      setTopMensJeans(jeansMen);
      setBestSellerAccessories(mensBestSellerAccessoriesData);
      setBestSellerShoes(mensBestSellerShoesData);
      setTopActiveWear(topActiveWearData);
      setTopDesigner(topDesignerData);
      setTopHoodie(topHoodieData);
      setTopJackets(topJacketData);
      setTopLoungewear(topLoungewearData);
      setTopPants(topPantsData);
      setTopPolos(topPolosData);
      setTopPants(topPantsData);
      setTopShirts(topShirtsData);
      setTopShorts(topShortsData);
      setTopSocks(topSocksData);
      setTopSuits(topSuitsData);
      setTopSweaters(topSweatersData);
      setTopSwimwear(topSwimwearData);
      setTopTracksuits(topTracksuitsData);
      setTopTshirts(topTshirtsData);
      setTopTwoPiece(topTwoPieceData);
      setTopUnderwear(topUnderwearData);

      setLoading(false);
      setError(false);
    } catch (e) {
      setError(true);
      setLoading(false);
      //console.error(e);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  if (error) {
    return (
      <Fragment>
        <TopHeader onLogoPress={fetchProducts} onRefreshPress={fetchProducts} />
        <ScrollView
          key={"App Scrollview"}
          style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 180,
            }}
          >
            <Text>
              Unable to fetch feeds. Please hit mens fashion icon to refresh
            </Text>
          </View>
        </ScrollView>
      </Fragment>
    );
  }
  return (
    <>
    <TopHeader onLogoPress={fetchProducts} onRefreshPress={fetchProducts} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        key={"App Scrollview"}
        style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
        contentContainerStyle={{ alignItems: "center" }}
      >
        <MensFashionFeed
          data={bestSellerMensClothing}
          loading={loading}
          error={error}
          headerTitle={"Best Sellers"}
        />
        <MensFashionFeed
          data={newMensClothing}
          loading={loading}
          error={error}
          headerTitle={"New Arrivals"}
        />
        <MensFashionFeed
          data={bestSellerAccessories}
          loading={loading}
          error={error}
          headerTitle={"Top Accessories"}
        />
        <MensFashionFeed
          data={topActiveWear}
          loading={loading}
          error={error}
          headerTitle={"Top Activewear"}
        />
        <MensFashionFeed
          data={topDesigner}
          loading={loading}
          error={error}
          headerTitle={"Top Designer"}
        />
        <MensFashionFeed
          data={topHoodie}
          loading={loading}
          error={error}
          headerTitle={"Top Hoodie"}
        />
        <MensFashionFeed
          data={topJackets}
          loading={loading}
          error={error}
          headerTitle={"Top Jacket"}
        />
        <MensFashionFeed
          data={topLoungewear}
          loading={loading}
          error={error}
          headerTitle={"Top Loungewear"}
        />
        <MensFashionFeed
          data={topPants}
          loading={loading}
          error={error}
          headerTitle={"Top Pants"}
        />
        <MensFashionFeed
          data={topPolos}
          loading={loading}
          error={error}
          headerTitle={"Top Polos"}
        />
        <MensFashionFeed
          data={topMensJeans}
          loading={loading}
          error={error}
          headerTitle={"Top Jeans"}
        />
        <MensFashionFeed
          data={topShirts}
          loading={loading}
          error={error}
          headerTitle={"Top Shirts"}
        />
        <MensFashionFeed
          data={bestSellerShoes}
          loading={loading}
          error={error}
          headerTitle={"Top Shoes"}
        />
        <MensFashionFeed
          data={topShorts}
          loading={loading}
          error={error}
          headerTitle={"Top Shorts"}
        />
        <MensFashionFeed
          data={topSocks}
          loading={loading}
          error={error}
          headerTitle={"Top Socks"}
        />
        <MensFashionFeed
          data={topSuits}
          loading={loading}
          error={error}
          headerTitle={"Top Suits"}
        />
        <MensFashionFeed
          data={topSweaters}
          loading={loading}
          error={error}
          headerTitle={"Top Sweaters"}
        />
        <MensFashionFeed
          data={topSwimwear}
          loading={loading}
          error={error}
          headerTitle={"Top Swimwear"}
        />
        <MensFashionFeed
          data={topTracksuits}
          loading={loading}
          error={error}
          headerTitle={"Top Track Suits"}
        />
        <MensFashionFeed
          data={topTshirts}
          loading={loading}
          error={error}
          headerTitle={"Top T-Shirts"}
        />
        <MensFashionFeed
          data={topTwoPiece}
          loading={loading}
          error={error}
          headerTitle={"Top Two Piece"}
        />

        <MensFashionFeed
          data={topUnderwear}
          loading={loading}
          error={error}
          headerTitle={"Top Underwear"}
        />
        <View style={{ marginBottom: 120 }} />
      </ScrollView>
    </>
  );
}
const styles = StyleSheet.create({
  bottomWrap: {
    marginBottom: 180,
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 10,
    borderRadius: 30,
    padding: 10,
  },
});
