import React from "react";
import { Image, Linking, View, Text, TouchableOpacity } from "react-native";

//Navigation
import { useNavigation } from "@react-navigation/native";

//Styles
//Styles
import styles from "../../../styles/app.card.styles";
import LinkerView from "../../linking/LinkerView";

function AppCard({ app }) {
  const links = app.links;
  return (
    <View style={styles.linearGradientContainer}>
      <TouchableOpacity
        onPress={() => {
          Linking.openURL(`https://www.google.com/search?q=${app.title}+ app`);
        }}
      >
        <View style={styles.bottomContainer}>
          <Image
            style={styles.image}
            source={{
              uri: app.imageUrl
                ? app.imageUrl
                : "https://static8.depositphotos.com/1044234/879/i/450/depositphotos_8790321-stock-photo-retro-microphone-on-stage.jpg",
            }}
          />
          <View style={{ flexDirection: "column" }}>
            <View style={styles.titleWrap}>
              <Text style={styles.title} numberOfLines={4}>
                {app.title}
              </Text>
              <Text style={styles.type} numberOfLines={1}>
                app
              </Text>
            </View>
            <View style={{ paddingTop: 20 }}>
              <Text style={styles.linksText} numberOfLines={1}>
                More Links:
              </Text>
              <LinkerView links={links} />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

export const MemoizedAppCard = React.memo(AppCard);
