import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Fragment,
  useContext,
} from "react";
import {
  Alert,
  Button,
  Linking,
  PermissionsAndroid,
  Platform,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  ToastAndroid,
  View,
  Modal,
  Pressable,
  TouchableOpacity,
} from "react-native";
import TopHeader from "../navigation/TopHeader";
import useGeoLocation from "../hooks/useGeolocation";
import EventsFeed from "../components/feeds/local/EventsFeed";
import LocalFeed from "../components/feeds/local/LocalFeed";
import LocationContext from "../components/context/Location";
import NoWrapLocalFeed from "../components/feeds/local/NoWrapLocalFeed";

export default function LocalScreen() {
  //   const location = useGeoLocation()
  //  console.log('loc',location)
  const location = useContext(LocationContext);
  // console.log('loc',location)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isLocalScreenActive, setIsLocalScreenActive] = useState(true);
  const [isBottomFeedActive, setIsBottomFeedActive] = useState(true);
  const [topBars, setTopBars] = useState([]);
  const [topHotels, setTopHotels] = useState([]);
  const [topSalons, setTopSalons] = useState([]);
  const [topGyms, setTopGyms] = useState([]);
  const [topThai, setTopThai] = useState([]);
  const [topMassage, setTopMassage] = useState([]);
  const [topNightlife, setTopNightlife] = useState([]);
  const [topRestaurants, setTopRestaurants] = useState([]);
  const [topDelivery, setTopDelivery] = useState([]);
  const [topReservations, setTopReservations] = useState([]);
  const [topBurgers, setTopBurgers] = useState([]);
  const [topJapanese, setTopJapanese] = useState([]);
  const [topChinese, setTopChinese] = useState([]);
  const [topMexican, setTopMexican] = useState([]);
  const [topItalian, setTopItalian] = useState([]);
  const [topCarDealers, setTopCarDealers] = useState([]);
  const [topOilChange, setTopOilChange] = useState([]);
  const [topParking, setTopParking] = useState([]);
  const [topCarWash, setTopCarWash] = useState([]);
  const [topAutoServices, setTopAutoServices] = useState([]);
  const [topDryCleaning, setTopDryCleaning] = useState([]);
  const [topPhoneRepair, setTopPhoneRepair] = useState([]);
  const [currentLongitude, setCurrentLongitude] = useState(
    location.coordinates.lng
  );
  const [currentLatitude, setCurrentLatitude] = useState(
    location.coordinates.lat
  );
  const [locationStatus, setLocationStatus] = useState("");

  //Fetching Data
  const fetchLocal = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://youtapia-temp8.uc.r.appspot.com/v1/api/feed/801,803,804,805,806,807,808,809?latitude=${location.coordinates.lat}&longitude=${location.coordinates.lng}`,
        {
          // `https://youtapia-temp8.uc.r.appspot.com/v1/api/feed/801,802,803,804,805,806,807,808,809,829,831,832,833,834,839,821,823,826,830,819,825?latitude=${location.coordinates.lat}&longitude=${location.coordinates.lng}`,
          // {
          method: "GET",
          credentials: "same-origin",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setTopRestaurants(data[0].cards);
      setTopReservations(data[1].cards);
      setTopBurgers(data[2].cards);
      setTopJapanese(data[3].cards);
      setTopChinese(data[4].cards);
      setTopMexican(data[5].cards);
      setTopItalian(data[6].cards);
      setTopThai(data[7].cards);
      // setTopSalons(data[9].cards);
      // setTopGyms(data[10].cards);
      // setTopBars(data[11].cards);
      // setTopMassage(data[12].cards);
      // setTopNightlife(data[13].cards);
      // setTopHotels(data[14].cards);
      // setTopCarDealers(data[15].cards);
      // setTopOilChange(data[16].cards);
      // setTopCarWash(data[17].cards);
      // setTopPhoneRepair(data[18].cards);
      // setTopAutoServices(data[19].cards);
      // setTopParking(data[20].cards);
      setLoading(false);
      setError(false);
      //console.log(data);
    } catch (e) {
      setError(true);
      setLoading(true);
      //console.error(e);
    }
  };

  useEffect(() => {
    if (location) {
      fetchLocal();
    }
  }, [location]);

  //Loading & Error Logic
  if (error) {
    return (
      <Fragment>
        <TopHeader onLogoPress={fetchLocal} onRefreshPress={fetchLocal} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          key={"App Scrollview"}
          style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
          contentContainerStyle={{ alignItems: "center" }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 180,
            }}
          >
            <Text>Unable to fetch feeds. Please enable your location</Text>
          </View>
        </ScrollView>
      </Fragment>
    );
  }

  return (
    <>
      <TopHeader onLogoPress={fetchLocal} onRefreshPress={fetchLocal} />
      <ScrollView
        style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
        contentContainerStyle={{ alignItems: "center" }}
      >
        {/* <LocalFeed
          data={topDelivery}
          isLoading={loading}
          isError={error}
          headerTitle={'Top Delivery'}
          TypeOfBusiness={'Active'}
        /> */}
        <LocalFeed
          data={topRestaurants}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Restaurants"}
          TypeOfBusiness={"Active"}
        />
        <LocalFeed
          data={topReservations}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Reservations"}
          TypeOfBusiness={"Bar"}
        />
        {/* <LocalFeed
          data={topBars}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Bars"}
          TypeOfBusiness={"Bar"}
        /> */}
        {/* <LocalFeed
          data={topNightlife}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Nightlife"}
          TypeOfBusiness={"Bar"}
        /> */}
        <LocalFeed
          data={topBurgers}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Burgers"}
          TypeOfBusiness={"Art"}
        />
        <LocalFeed
          data={topChinese}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Chinese"}
          TypeOfBusiness={"Art"}
        />
        <LocalFeed
          data={topItalian}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Italian"}
          TypeOfBusiness={"Art"}
        />
        <LocalFeed
          data={topJapanese}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Japanese"}
          TypeOfBusiness={"Art"}
        />

        <LocalFeed
          data={topMexican}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Mexican"}
          TypeOfBusiness={"Art"}
        />
        <View style={styles.bottomWrap}>
          <NoWrapLocalFeed
            data={topThai}
            isLoading={loading}
            isError={error}
            headerTitle={"Top Thai"}
            TypeOfBusiness={"Restaurant"}
          />
        </View>
        {/* <LocalFeed
          data={topHotels}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Hotels + Travel"}
          TypeOfBusiness={"Hotel"}
        />
        <LocalFeed
          data={topSalons}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Salons"}
          TypeOfBusiness={"Hotel"}
        />
        <LocalFeed
          data={topGyms}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Gyms"}
          TypeOfBusiness={"Hotel"}
        />
        <LocalFeed
          data={topMassage}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Massage"}
          TypeOfBusiness={"Hotel"}
        />

        <LocalFeed
          data={topCarDealers}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Car Dealers"}
          TypeOfBusiness={"Car"}
        />
        <LocalFeed
          data={topOilChange}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Oil Change"}
          TypeOfBusiness={"Car"}
        />
        <LocalFeed
          data={topCarWash}
          isLoading={loading}
          isError={error}
          headerTitle={"Top Car Wash"}
          TypeOfBusiness={"Car"}
        />
        <View style={styles.bottomWrap}>
          <LocalFeed
            data={topParking}
            isLoading={loading}
            isError={error}
            headerTitle={"Top Parking"}
            TypeOfBusiness={"Parking"}
          />
        </View> */}
        <View style={{ marginBottom: 120 }} />
      </ScrollView>
    </>
  );
}
const styles = StyleSheet.create({
  bottomWrap: {
    marginBottom: 180,
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 10,
    borderRadius: 30,
    padding: 10,
  },
});
