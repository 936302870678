import React, { useCallback, Fragment } from "react";
import {
  FlatList,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
} from "react-native";

//cards

//styles
import styles from "../../../../styles/filter.feed.style";
import { MemoizedUniversalSocialCard } from "../../../cards/universal/UniversalSocialCard";
import FilterLoading from "../filter.feed.loading";
import { MemoizedYouTubeCard } from "../../../cards/video/YouTubeCard";

function YouTubeFilterFeed({
  //data
  youTubeTopData,
  youTubeNewsData,
  youTubeEntertainmentData,
  youTubePeopleData,

  //on press
  onYouTubeTopPress,
  onYouTubeNewsPress,
  onYouTubeEntPress,
  OnYouTubePeoplePress,

  //show
  showYouTubeTop,
  showYouTubeNews,
  showYouTubeEnt,
  showYouTubePeople,

  //loading + error
  loading,
  error,
}) {
  const renderItem = ({ item }) => <MemoizedYouTubeCard video={item} />;

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const ITEM_HEIGHT = 350;

  const getItemLayout = useCallback(
    (data, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    []
  );

  if (loading === true) {
    return <FilterLoading />;
  }
  if (
    error === true ||
    youTubeNewsData.length < 0 ||
    youTubeTopData.length < 0 ||
    youTubeEntertainmentData.length < 0 ||
    youTubePeopleData.length < 0
  ) {
    return null;
  }

  return (
    <View style={styles.wrap}>
    <View>
      <Text style={styles.headerText}>YOUTUBE</Text>
    </View>
    <View style={styles.headerWrap}>
      <View style={styles.filterButtonWrap}>
          {showYouTubeTop ? (
            <TouchableOpacity
              onPress={onYouTubeTopPress}
              style={styles.linearButton}
            >
              <Text style={[styles.header, { color: "#fff" }]}>Trending</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              onPress={onYouTubeTopPress}
              style={[styles.button]}
            >
              <Text style={[styles.header, { color: "#fff" }]}>Trending</Text>
            </TouchableOpacity>
          )}
          {showYouTubeNews ? (
            <TouchableOpacity
              onPress={onYouTubeNewsPress}
              style={styles.linearButton}
            >
              <Text style={[styles.header, { color: "#fff" }]}>News</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              onPress={onYouTubeNewsPress}
              style={[styles.button]}
            >
              <Text style={[styles.header, { color: "#fff" }]}>News</Text>
            </TouchableOpacity>
          )}
          {showYouTubePeople ? (
            <TouchableOpacity
              onPress={OnYouTubePeoplePress}
              style={styles.linearButton}
            >
              <Text style={[styles.header, { color: "#fff" }]}>Sports</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              onPress={OnYouTubePeoplePress}
              style={[styles.button]}
            >
              <Text style={[styles.header, { color: "#fff" }]}>Sports</Text>
            </TouchableOpacity>
          )}
          {showYouTubeEnt ? (
            <TouchableOpacity
              onPress={onYouTubeEntPress}
              style={styles.linearButton}
            >
              <Text style={[styles.header, { color: "#fff" }]}>Ent</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              onPress={onYouTubeEntPress}
              style={[styles.button]}
            >
              <Text style={[styles.header, { color: "#fff" }]}>Ent</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
      {showYouTubeTop && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          keyExtractor={keyExtractor}
          data={youTubeTopData}
          renderItem={renderItem}
        />
      )}
      {showYouTubeNews && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={youTubeNewsData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showYouTubeEnt && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={youTubeEntertainmentData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}

      {showYouTubePeople && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={youTubePeopleData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
    </View>
  );
}

export default YouTubeFilterFeed;
