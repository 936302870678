import React, { useCallback } from "react";
import { FlatList, View, Text, TouchableOpacity } from "react-native";
import "react-loading-skeleton/dist/skeleton.css";

//styles
import styles from "../../../../styles/filter.feed.style";
import BreakingFilterLoading from "../breaking/breaking.feed.loading";

import { MemoizedMusicCard } from "../../../cards/music/MusicCard";
import { MemoizedYouTubeImgCard } from "../../../cards/video/YouTubeImgCard";

function MusicFilterFeed({
  //data
  topMusicData,
  newMusicData,
  topAlbumsData,
  musicVideosData,

  //error
  error,

  //onPress
  onTopMusicPress,
  onNewMusicPress,
  onTopAlbumsPress,
  onMusicVideosPress,

  //loading
  loading,

  //show
  showTopMusic,
  showNewMusic,
  showTopAlbums,
  showMusicVideos,
}) {
  const renderItem = ({ item }) => <MemoizedMusicCard song={item} />;

  const renderVideoItem = ({ item }) => <MemoizedYouTubeImgCard card={item} />;

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const ITEM_HEIGHT = 350;

  const getItemLayout = useCallback(
    (data, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    []
  );

  if (loading) {
    return <BreakingFilterLoading />;
  }

  if (error === true) {
    return null;
  }
  return (
    <View style={styles.wrap}>
    <View>
      <Text style={styles.headerText}>MUSIC</Text>
    </View>
    <View style={styles.headerWrap}>
      <View style={styles.filterButtonWrap}>
          <TouchableOpacity
            onPress={onTopMusicPress}
            style={[
              styles.button,
              {
                backgroundImage: showTopMusic
                  ? "linear-gradient(to top right, rgba(0,0,0) 60%,rgb(247, 220, 111  ))"
                  : null,
                shadowOffset: showTopMusic
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showTopMusic ? 0.98 : 0,
                shadowRadius: showTopMusic ? 3.0 : 0,
                elevation: showTopMusic ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[styles.header, { color: showTopMusic ? "#fff" : "#fff" }]}
            >
              Top 50
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onNewMusicPress}
            style={[
              styles.button,
              {
                backgroundImage: showNewMusic
                  ? "linear-gradient(to top right, rgba(0,0,0) 60%,rgb(247, 220, 111  ))"
                  : null,
                shadowOffset: showNewMusic
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showNewMusic ? 0.98 : 0,
                shadowRadius: showNewMusic ? 3.0 : 0,
                elevation: showNewMusic ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[styles.header, { color: showNewMusic ? "#fff" : "#fff" }]}
            >
              New
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onTopAlbumsPress}
            style={[
              styles.button,
              {
                backgroundImage: showTopAlbums
                  ? "linear-gradient(to top right, rgba(0,0,0), rgba(91, 44, 111))"
                  : null,
                shadowOffset: showTopAlbums
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showTopAlbums ? 0.98 : 0,
                shadowRadius: showTopAlbums ? 3.0 : 0,
                elevation: showTopAlbums ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showTopAlbums ? "#fff" : "#fff" },
              ]}
            >
              Albums
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={onMusicVideosPress}
            style={[
              styles.button,
              {
                backgroundImage: showMusicVideos
                  ? "linear-gradient(to top right, rgba(0,0,0) 44%, rgba(27, 79, 114))"
                  : null,
                shadowOffset: showMusicVideos
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showMusicVideos ? 0.98 : 0,
                shadowRadius: showMusicVideos ? 3.0 : 0,
                elevation: showMusicVideos ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showMusicVideos ? "#fff" : "#fff" },
              ]}
            >
              Videos
            </Text>
          </TouchableOpacity>
          {/* <TouchableOpacity
            onPress={onHuluPress}
            style={[
              styles.button,
              {
                backgroundImage: showHulu
                  ? "linear-gradient(to top right, rgba(0,0,0) 44%, rgba(27, 79, 114))"
                  : null,
                shadowOffset: showHulu
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showHulu ? 0.98 : 0,
                shadowRadius: showHulu ? 3.0 : 0,
                elevation: showHulu ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[styles.header, { color: showHulu ? "#fff" : "#fff" }]}
            >
              Top Hulu
            </Text>
          </TouchableOpacity> */}
        </View>
      </View>
      {showNewMusic && (
        <FlatList
          showsHorizontalScrollIndicator={false}
          disableVirtualization={true}
          horizontal
          data={newMusicData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showTopMusic && (
        <FlatList
          showsHorizontalScrollIndicator={false}
          disableVirtualization={true}
          horizontal
          data={topMusicData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showTopAlbums && (
        <FlatList
          showsHorizontalScrollIndicator={false}
          disableVirtualization={true}
          horizontal
          data={topAlbumsData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showMusicVideos && (
        <FlatList
          showsHorizontalScrollIndicator={false}
          disableVirtualization={true}
          horizontal
          data={musicVideosData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderVideoItem}
        />
      )}
      {/* {showHulu && (
        <FlatList
          showsHorizontalScrollIndicator={false}
          disableVirtualization={true}
          horizontal
          data={topHuluData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )} */}
    </View>
  );
}

export default MusicFilterFeed;
