import React, { useState, useEffect, Fragment } from "react";
import { ScrollView, Text, StyleSheet, View } from "react-native";
import TopHeader from "../navigation/TopHeader";
import AppFeed from "../components/feeds/apps/AppFeed";
import TopAppNewsFeed from "../components/feeds/apps/AppNewsFeed";
import FilmFeed from "../components/feeds/film/FilmFeed";
import TopFilmNewsFeed from "../components/feeds/film/FilmNewsFeed";
import TopPodcastNewsFeed from "../components/feeds/podcast/PodcastNewsFeed";
import PodcastFeed from "../components/feeds/podcast/PodcastFeed";

export default function PodcastsScreen() {
  const [loading, setLoading] = useState(true);
  const [topPodcasts, setTopPodcast] = useState(false);
  const [error, setError] = useState(false);
  const [isPodcastScreenActive, setIsPodcastScreenActive] = useState(true);
  const [topArt, setTopArt] = useState([]);
  const [topBusiness, setTopBusiness] = useState([]);
  const [topComedy, setTopComedy] = useState([]);
  const [topEducation, setTopEducation] = useState([]);
  const [topFiction, setTopFiction] = useState([]);
  const [topGovernment, setTopGovernment] = useState([]);
  const [topHealth, setTopHealth] = useState([]);
  const [topHistory, setTopHistory] = useState([]);
  const [topKids, setTopKids] = useState([]);
  const [topLeisure, setTopLeisure] = useState([]);
  const [topMusic, setTopMusic] = useState([]);
  const [topNews, setTopNews] = useState([]);
  const [topReligion, setTopReligion] = useState([]);
  const [topScience, setTopScience] = useState([]);
  const [topRomance, setTopRomance] = useState([]);
  const [topSports, setTopSports] = useState([]);
  const [topTech, setTopTech] = useState([]);
  const [topTrueCrime, setTopTrueCrime] = useState([]);
  const [topTvFilm, setTopTvFilm] = useState([]);
  const [topPodcastNews, setTopPodcastNews] = useState([]);

  //Fetch logic per feed
  const fetchPodcast = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://youtapia-temp8.uc.r.appspot.com/v1/api/feed/701,702,703,704,705,706,707,708,709,710,711,712,713,714,715,716,718,719,720,1215,717`,
        {
          method: "GET",
          credentials: "same-origin",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setTopPodcast(data[0].cards);
      setTopArt(data[1].cards);
      setTopBusiness(data[2].cards);
      setTopComedy(data[3].cards);
      setTopEducation(data[4].cards);
      setTopFiction(data[5].cards);
      setTopGovernment(data[6].cards);
      setTopHealth(data[7].cards);
      setTopHistory(data[8].cards);
      setTopKids(data[9].cards);
      setTopLeisure(data[10].cards);
      setTopMusic(data[11].cards);
      setTopNews(data[12].cards);
      setTopReligion(data[13].cards);
      setTopScience(data[14].cards);
      setTopSports(data[15].cards);
      setTopTech(data[16].cards);
      setTopTrueCrime(data[17].cards);
      setTopTvFilm(data[18].cards);
      setTopPodcastNews(data[19].cards);
      setLoading(false);
      setError(false);
      //console.log(data);
      setError(false);
    } catch (e) {
      setError(true);
      setLoading(false);
      //console.error(e);
    }
  };

  //Render Data
  useEffect(() => {
    fetchPodcast();
  }, []);

  //Error Logic
  if (error) {
    return (
      <Fragment>
        <TopHeader onLogoPress={fetchPodcast} onRefreshPress={fetchPodcast} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          key={"App Scrollview"}
          style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
          contentContainerStyle={{ alignItems: "center" }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 180,
            }}
          >
            <Text>
              Unable to fetch feeds. Please hit app tab icon to refresh
            </Text>
          </View>
        </ScrollView>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <TopHeader onLogoPress={fetchPodcast} onRefreshPress={fetchPodcast} />
      <ScrollView
        style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
        contentContainerStyle={{ alignItems: "center" }}
      >
        <PodcastFeed
          data={topPodcasts}
          isError={error}
          isLoading={loading}
          headerTitle="Top 100"
        />
        <PodcastFeed
          data={topArt}
          isError={error}
          isLoading={loading}
          headerTitle="Art"
        />
        <PodcastFeed
          data={topComedy}
          isError={error}
          isLoading={loading}
          headerTitle="Comedy"
        />
        <PodcastFeed
          data={topEducation}
          isError={error}
          isLoading={loading}
          headerTitle="Education"
        />
        <PodcastFeed
          data={topFiction}
          isError={error}
          isLoading={loading}
          headerTitle="Fiction"
        />
        <PodcastFeed
          data={topGovernment}
          isError={error}
          isLoading={loading}
          headerTitle="Government"
        />
        <PodcastFeed
          data={topHealth}
          isError={error}
          isLoading={loading}
          headerTitle="Health"
        />
        <PodcastFeed
          data={topHistory}
          isError={error}
          isLoading={loading}
          headerTitle="History"
        />
        <PodcastFeed
          data={topKids}
          isError={error}
          isLoading={loading}
          headerTitle="Kids"
        />
        <PodcastFeed
          data={topLeisure}
          isError={error}
          isLoading={loading}
          headerTitle="Leisure"
        />
        <PodcastFeed
          data={topMusic}
          isError={error}
          isLoading={loading}
          headerTitle="Music"
        />
        <PodcastFeed
          data={topNews}
          isError={error}
          isLoading={loading}
          headerTitle="News"
        />
        <PodcastFeed
          data={topReligion}
          isError={error}
          isLoading={loading}
          headerTitle="Religion"
        />
        <PodcastFeed
          data={topScience}
          isError={error}
          isLoading={loading}
          headerTitle="Science"
        />
        <PodcastFeed
          data={topSports}
          isError={error}
          isLoading={loading}
          headerTitle="Sports"
        />
        <PodcastFeed
          data={topTech}
          isError={error}
          isLoading={loading}
          headerTitle="Tech"
        />
        <PodcastFeed
          data={topTrueCrime}
          isError={error}
          isLoading={loading}
          headerTitle="True Crime"
        />

        <PodcastFeed
          data={topTvFilm}
          isError={error}
          isLoading={loading}
          headerTitle="TV + Film"
        />
        <View style={{ marginBottom: 120 }} />
      </ScrollView>
    </Fragment>
  );
}
const styles = StyleSheet.create({
  bottomWrap: {
    marginBottom: 180,
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 10,
    borderRadius: 30,
    padding: 10,
  },
});
