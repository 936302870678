import React from "react";
import { Image, Linking, View, Text, TouchableOpacity } from "react-native";

//Styles
import styles from "../../../styles/tv.card.styles";
import LinkerView from "../../linking/LinkerView";

function TelevisionCard({ tv }) {
  const links = tv.links;
  return (
    <View style={styles.linearGradientContainer}>
      <TouchableOpacity
        onPress={() => {
          Linking.openURL(
            `https://www.google.com/search?q=${tv.title}+ tv series`
          );
        }}
      >
        <View style={styles.bottomContainer}>
          <Image
            style={styles.image}
            source={{
              uri: tv.imageUrl
                ? tv.imageUrl
                : "https://static8.depositphotos.com/1044234/879/i/450/depositphotos_8790321-stock-photo-retro-microphone-on-stage.jpg",
            }}
          />
          <View style={{ flexDirection: "column" }}>
            <View style={styles.titleWrap}>
              <Text style={styles.title} numberOfLines={4}>
                {tv.title}
              </Text>
              <Text style={styles.type} numberOfLines={1}>
                tv series
              </Text>
            </View>
            <View style={{ paddingTop: 20 }}>
              <Text style={styles.linksText} numberOfLines={1}>
                More Links:
              </Text>
              <LinkerView links={links} />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}
export const MemoizedTelevisionCard = React.memo(TelevisionCard);
