import React from "react";
import { Image, View } from "react-native";
import assets from "../../assets";

const Linker = ({ link }) => {
  if (link === "Amazon") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.Amazon_Link} style={{ height: 48, width: 48 }} />
      </View>
    );
  }
  if (link === "Fandango") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.fandango_icon}
          style={{ height: 48, width: 48 }}
        />
      </View>
    );
  }
  if (link === "ASOS") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.asos_shopping_icon}
          style={{ height: 52, width: 52 }}
        />
      </View>
    );
  }
  if (link === "IMDb") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.IMDb_Link} style={{ height: 52, width: 52 }} />
      </View>
    );
  }
  if (link === "AppleTv") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.apple_tv_icon}
          style={{ height: 52, width: 52 }}
        />
      </View>
    );
  }
  if (link === "Vudu") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.vudu_icon} style={{ height: 52, width: 52 }} />
      </View>
    );
  }
  if (link === "Google Shopping") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.google_shopping_icon}
          style={{ height: 52, width: 52 }}
        />
      </View>
    );
  }
  if (link === "Twitter") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.twitter_icon} style={{ height: 48, width: 48 }} />
      </View>
    );
  }
  if (link === "NetFlix") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.netflix_icon} style={{ height: 52, width: 52 }} />
      </View>
    );
  }
  if (link === "Rotten Tomatoes") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.Rotten_Tomatoes_Link}
          style={{ height: 48, width: 48 }}
        />
      </View>
    );
  }
  if (link === "UberEats") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.uber_eats_icon}
          style={{ height: 56, width: 56 }}
        />
      </View>
    );
  }
  if (link === "DoorDash") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.door_dash_icon}
          style={{ height: 52, width: 52 }}
        />
      </View>
    );
  }
  if (link === "Instagram") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.instagram_icon}
          style={{ height: 48, width: 48 }}
        />
      </View>
    );
  }
  if (link === "DirectTv") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.direct_tv_icon}
          style={{ height: 52, width: 52 }}
        />
      </View>
    );
  }
  if (link === "AmazonPrime") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.amazon_prime_icon}
          style={{ height: 52, width: 52 }}
        />
      </View>
    );
  }
  if (link === "GooglePlay") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.google_play_link}
          style={{ height: 48, width: 48 }}
        />
      </View>
    );
  }
  if (link === "espn") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.espn_icon}
          style={{ height: 48, width: 48 }}
        />
      </View>
    );
  }
  if (link === "home") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.home_icon}
          style={{ height: 48, width: 48 }}
        />
      </View>
    );
  }
  if (link === "ParamountPlus") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.parmaount_plus_icon}
          style={{ height: 48, width: 48 }}
        />
      </View>
    );
  }
  if (link === "eBay") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.ebay_icon} style={{ height: 48, width: 48 }} />
      </View>
    );
  }
  if (link === "ITunes") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.itunes_link} style={{ height: 52, width: 52 }} />
      </View>
    );
  }
  if (link === "iTunes") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.itunes_link} style={{ height: 52, width: 52 }} />
      </View>
    );
  }
  if (link === "Roku") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.roku_link}
          style={{ height: 50, width: 52, borderRadius: 50 }}
        />
      </View>
    );
  }
  if (link === "Spotify") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.spotify_link}
          style={{ height: 50, width: 52, borderRadius: 50 }}
        />
      </View>
    );
  }
  if (link === "BestBuy") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.bestbuy_link} style={{ height: 50, width: 50 }} />
      </View>
    );
  }
  if (link === "TwitterHashtag") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.twitter_hashtag_icon}
          style={{ height: 50, width: 50 }}
        />
      </View>
    );
  }
  if (link === "Walmart") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.walmart_link} style={{ height: 48, width: 48 }} />
      </View>
    );
  }
  if (link === "Showtime") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.showtime_icon}
          style={{ height: 48, width: 48 }}
        />
      </View>
    );
  }
  if (link === "Target") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.target_link} style={{ height: 50, width: 52 }} />
      </View>
    );
  }
  if (link === "Yelp") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.yelp_icon} style={{ height: 48, width: 48 }} />
      </View>
    );
  }
  if (link === "Barnes and Noble") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.barnesnoble_link}
          style={{ height: 48, width: 48, borderRadius: 30 }}
        />
      </View>
    );
  }
  if (link === "HBOMax") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.hbo_icon} style={{ height: 48, width: 48 }} />
      </View>
    );
  }
  if (link === "Wikipedia") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.wikipedia_icon}
          style={{ height: 48, width: 48, borderRadius: 30 }}
        />
      </View>
    );
  }
  if (link === "YouTube") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.youtube_icon} style={{ height: 52, width: 52 }} />
      </View>
    );
  }
  if (link === "Google News") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.google_news_icon}
          style={{ height: 48, width: 48, borderRadius: 30 }}
        />
      </View>
    );
  }
  if (link === "GoogleNews") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.google_news_icon}
          style={{ height: 48, width: 48, borderRadius: 30 }}
        />
      </View>
    );
  }
  if (link === "Hulu") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.hulu_icon} style={{ height: 48, width: 48 }} />
      </View>
    );
  }
  if (link === "Redbox") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.redbox_icon} style={{ height: 48, width: 48 }} />
      </View>
    );
  }
  if (link === "Steam") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.steam_icon} style={{ height: 48, width: 48 }} />
      </View>
    );
  }
  if (link === "FuboTv") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.fubotv_icon} style={{ height: 48, width: 48 }} />
      </View>
    );
  }
  if (link === "Twitch") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.twitch_icon} style={{ height: 48, width: 48 }} />
      </View>
    );
  }
  if (link === "AmcPlus") {
    return (
      <View style={{ flex: 1 }}>
        <Image source={assets.amcplus_icon} style={{ height: 48, width: 48 }} />
      </View>
    );
  }
  if (link === "Spectrum") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.spectrum_icon}
          style={{ height: 48, width: 48 }}
        />
      </View>
    );
  }
  if (link === "Facebook") {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={assets.facebook_icon}
          style={{
            height: 48,
            width: 48,
            borderRadius: 30,
          }}
        />
      </View>
    );
  }
  return null;
};

export default Linker;
