import React, { useCallback, Fragment } from "react";
import { FlatList, View, Text, TouchableOpacity } from "react-native";
import "react-loading-skeleton/dist/skeleton.css";

//styles
import styles from "../../../../styles/filter.feed.style";
import BreakingFilterLoading from "../breaking/breaking.feed.loading";
import { MemoizedProductCard } from "../../../cards/products/ProductCard";

function ProductFilterFeed({
  //data
  amazonTrendingData,
  topProductsData,

  //error
  error,

  //onPress
  onAmazonTrendingPress,
  onTopProductsPress,

  //loading
  loading,

  //show
  showAmazonTrending,
  showTopProducts,
}) {
  const renderItem = ({ item }) => <MemoizedProductCard product={item} />;

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const ITEM_HEIGHT = 350;

  const getItemLayout = useCallback(
    (data, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    []
  );

  if (loading) {
    return <BreakingFilterLoading />;
  }

  if (error === true) {
    return null;
  }
  return (
    <View style={styles.wrap}>
    <View>
      <Text style={styles.headerText}>PRODUCTS</Text>
    </View>
    <View style={styles.headerWrap}>
      <View style={styles.filterButtonWrap}>
          <TouchableOpacity
            onPress={onAmazonTrendingPress}
            style={[
              styles.button,
              {
                backgroundImage: showAmazonTrending
                  ? "linear-gradient(to top right, rgba(0,0,0) 60%,rgb(247, 220, 111  ))"
                  : null,
                shadowOffset: showAmazonTrending
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showAmazonTrending ? 0.98 : 0,
                shadowRadius: showAmazonTrending ? 3.0 : 0,
                elevation: showAmazonTrending ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showAmazonTrending ? "#fff" : "#fff" },
              ]}
            >
              Top Amazon
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onTopProductsPress}
            style={[
              styles.button,
              {
                backgroundImage: showTopProducts
                  ? "linear-gradient(to top right, rgba(0,0,0), rgba(91, 44, 111))"
                  : null,
                shadowOffset: showTopProducts
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showTopProducts ? 0.98 : 0,
                shadowRadius: showTopProducts ? 3.0 : 0,
                elevation: showTopProducts ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showTopProducts ? "#fff" : "#fff" },
              ]}
            >
              Best Sellers
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      {showAmazonTrending && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={amazonTrendingData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showTopProducts && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={topProductsData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          //keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
    </View>
  );
}

export default ProductFilterFeed;
