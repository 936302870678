import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Linking,
  Image,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import assets from "../../../assets";

function RedditCard({ reddit }) {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      onPress={() => {
        Linking.openURL(`${reddit.links[0].link}`);
      }}
    >
      <View style={styles.descriptionContainer}>
        <View style={styles.bottomContainer}>
          <Image style={styles.image} source={assets.reddit_yellow_icon} />
          <View style={styles.titleWrap}>
            <Text style={styles.title} numberOfLines={3}>
              {reddit.title}
            </Text>
            {/* <Text style={styles.type} numberOfLines={2}>{news.description}</Text> */}
          </View>
          {/* <ScrollView 
                    horizontal 
                    showsHorizontalScrollIndicator={false} 
                    style={styles.linkContainer}
                >
                    {news.links.map((link, index) => {
                        return (
                            <TouchableOpacity key={index.toString()} onPress={ ()=>{ Linking.openURL(`${link.link}`)}}  style={{padding: 2}}>
                                <Linker link={link.type} />
                            </TouchableOpacity>
                            )
                    })}
                </ScrollView> */}
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    backgroundColor: "#fff",
  },
  title: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 16,
    color: "#FFF",
    padding: 6,
  },
  header: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 18,
    color: "#dadada",
    padding: 6,
  },
  titleWrap: {
    flex: 1,
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  headerWrap: {
    marginTop: 20,
    paddingLeft: 20,
  },
  type: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 14,
    color: "#DADADA",
  },
  image: {
    height: 40,
    width: 40,
    borderTopLeftRadius: 9,
    borderTopRightRadius: 9,
  },
  descriptionContainer: {
    alignItems: "center",
    alignContent: "center",
    margin: 6,
    shadowColor: "#000",
    backgroundImage:
      "linear-gradient(to top right, rgba(0,0,0) 44%,rgba(255,0,0), rgba(255,128,0))",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.58,
    shadowRadius: 2.0,
    elevation: 6,
    height: 120,
    borderRadius: 9,
  },
  linkContainer: {
    padding: 12,
  },
  bottomContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    paddingBottom: 2,
    paddingTop: 8,
    paddingLeft: 12,
    width: 282,
    borderRadius: 20,
  },
});

export const MemoizedRedditCard = React.memo(RedditCard);
