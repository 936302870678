import React, { useCallback, Fragment } from "react";
import { FlatList, StyleSheet, View, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";
import NewsLoadingBar from "../../cards/loading/NewsLoadingBar";
import { MemoizedNewsCard } from "../../cards/news/NewsCard";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function TopAppNewsFeed({ data, headerTitle, isError, isLoading }) {
  const navigation = useNavigation();

  const renderItem = ({ item }) => <MemoizedNewsCard news={item} />;

  const keyExtractor = useCallback((item) => item.id.toString(), []);
  if (isLoading === true) {
    return (
      <Fragment>
        <View style={{ paddingTop: 20, flexDirection: "row" }}>
          <NewsLoadingBar />
          <NewsLoadingBar />
          <NewsLoadingBar />
        </View>
      </Fragment>
    );
  }
  if (isError === true) {
    return null;
  }
  return (
    <View style={styles.wrap}>
      <View style={styles.headerWrap}>
        <Text style={styles.header}>{headerTitle}</Text>
      </View>
      <FlatList
        horizontal
        data={data}
        removeClippedSubviews={true}
        lazy={true}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    backgroundColor: "#fff",
  },
  title: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 18,
    color: "#FFF",
    padding: 6,
  },
  header: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 18,
    color: "#dadada",
    padding: 6,
  },
  titleWrap: {
    alignItems: "center",
    alignContent: "center",
  },
  headerWrap: {
    marginTop: 10,
    paddingLeft: 20,
  },
});

export default TopAppNewsFeed;
