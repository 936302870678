import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Linking,
  ImageBackground,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import Linker from "../../linking/linker";

function YouTubeImgCard({ card }) {
  const navigation = useNavigation();

  const image = { uri: card.imageUrl };

  return (
    <TouchableOpacity style={styles.wrap}>
      <ImageBackground source={image} resizeMode="cover" style={styles.image}>
        {/* <View
          style={{
            backgroundColor: "#00000080",
            height: 25,
            width: "31%",
            alignItems: "center",
            paddingVertical: 4,
            paddingHorizontal: 12,
            borderRadius: 10,
            margin: 10,
          }}
        >
          <Text style={styles.cardType} numberOfLines={1}>
            
          </Text>
        </View> */}
        <View style={styles.bottomContainer}>
          <View style={styles.titleWrap}>
            <Text style={styles.title} numberOfLines={3}>
              {card.title}
            </Text>
          </View>
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{
              alignContent: "center",
              justifyContent: "center",
            }}
            style={styles.linkContainer}
          >
            {card.links.map((link, index) => {
              return (
                <TouchableOpacity
                  key={index.toString()}
                  onPress={() => {
                    Linking.openURL(`${link.link}`);
                  }}
                  style={{ padding: 2 }}
                >
                  <Linker link={link.type} />
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </View>
      </ImageBackground>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    margin: 10,
    height: 376,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
  },
  title: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 16,
    color: "#FFF",
    padding: 6,
  },
  cardType: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 12,
    color: "yellow",
  },
  header: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 18,
    color: "#dadada",
    padding: 6,
  },
  titleWrap: {
    alignItems: "center",
    alignContent: "center",
    width: "95%",
  },
  headerWrap: {
    marginTop: 20,
    paddingLeft: 20,
  },
  type: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 14,
    color: "#DADADA",
    padding: 6,
  },
  image: {
    height: 380,
    width: 240,
    borderRadius: 50,
  },
  descriptionContainer: {
    alignItems: "center",
    alignContent: "center",
    margin: 6,
    shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.58,
    // shadowRadius: 1.0,
    elevation: 6,
  },
  linkContainer: {
    paddingTop: 15,
    //margin: 10,

    width: "97%",
  },
  bottomContainer: {
    flex: 1,
    flexDirection: "column",
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: 10,
    width: "100%",
    backgroundColor: "#00000098",
  },
});

export const MemoizedYouTubeImgCard = React.memo(YouTubeImgCard);
