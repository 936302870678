import React, { useState, useEffect, Fragment } from "react";
import { ScrollView, Text, StyleSheet, View } from "react-native";
import TopHeader from "../navigation/TopHeader";
import AppFeed from "../components/feeds/apps/AppFeed";
import TopAppNewsFeed from "../components/feeds/apps/AppNewsFeed";

export default function Applications() {
  const [loading, setLoading] = useState(false);
  const [appIsLoading, setAppIsLoading] = useState(true);
  const [newsLoading, setNewsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isAppScreenActive, setIsAppScreenActive] = useState(true);
  const [topApps, setTopApps] = useState([]);
  const [topAppNews, setTopAppNews] = useState([]);
  const [topFreeAll, setTopFreeAll] = useState([]);
  const [topFreeBooks, setTopFreeBooks] = useState([]);
  const [topFreeBusiness, setTopFreeBusiness] = useState([]);
  const [topFreeDevelopmentTools, setTopFreeDevelopmentTools] = useState([]);
  const [topFreeEducation, setTopFreeEducation] = useState([]);
  const [topFreeEntertainment, setTopFreeEntertainment] = useState([]);
  const [topFreeFinance, setTopFreeFinance] = useState([]);
  const [topFreeFoodDrink, setTopFreeFoodDrink] = useState([]);
  const [topFreeGames, setTopFreeGames] = useState([]);
  const [topFreeDesign, setTopFreeDesign] = useState([]);
  const [topFreeHealth, setTopFreeHealth] = useState([]);
  const [topFreeKids, setTopFreeKids] = useState([]);
  const [topFreeLifestyle, setTopFreeLifestyle] = useState([]);
  const [topFreeMedical, setTopFreeMedical] = useState([]);
  const [topFreeMusic, setTopFreeMusic] = useState([]);
  const [topFreeNavigation, setTopFreeNavigation] = useState([]);
  const [topFreeNews, setTopFreeNews] = useState([]);
  const [topFreePhoto, setTopFreePhoto] = useState([]);
  const [topFreeProductivity, setTopFreeProductivity] = useState([]);
  const [topFreeReference, setTopFreeReference] = useState([]);
  const [topFreeShopping, setTopFreeShopping] = useState([]);
  const [topFreeSocialNetworking, setTopFreeSocialNetworking] = useState([]);
  const [topFreeSports, setTopFreeSports] = useState([]);
  const [topFreeTop, setTopFreeTop] = useState([]);
  const [topFreeTravel, setTopFreeTravel] = useState([]);
  const [topFreeUtilities, setTopFreeUtilities] = useState([]);
  const [topFreeWeather, setTopFreeWeather] = useState([]);
  const [topPaidAll, setTopPaidAll] = useState([]);
  const [topGrossingAll, setTopGrossingAll] = useState([]);

  //Fetch logic per feed
  const fetchApps = async () => {
    setAppIsLoading(true);
    try {
      const response = await fetch(
        `https://youtapia-temp8.uc.r.appspot.com/v1/api/feed/601,602,603,604,605,606,607,608,609,610,611,612,613,614,615,616,617,618,619,620,621,622,623,624,625,626,627,628,655,1217`,
        {
          method: "GET",
          credentials: "same-origin",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setTopFreeAll(data[0].cards);
      setTopFreeBooks(data[1].cards);
      setTopFreeBusiness(data[2].cards);
      setTopFreeDevelopmentTools(data[3].cards);
      setTopFreeEducation(data[4].cards);
      setTopFreeEntertainment(data[5].cards);
      setTopFreeFinance(data[6].cards);
      setTopFreeFoodDrink(data[7].cards);
      setTopFreeGames(data[8].cards);
      setTopFreeDesign(data[9].cards);
      setTopFreeHealth(data[10].cards);
      setTopFreeKids(data[11].cards);
      setTopFreeLifestyle(data[12].cards);
      setTopFreeMedical(data[13].cards);
      setTopFreeMusic(data[14].cards);
      setTopFreeNavigation(data[15].cards);
      setTopFreeNews(data[16].cards);
      setTopFreePhoto(data[17].cards);
      setTopFreeProductivity(data[18].cards);
      setTopFreeReference(data[19].cards);
      setTopFreeShopping(data[20].cards);
      setTopFreeSocialNetworking(data[21].cards);
      setTopFreeSports(data[22].cards);
      setTopFreeTop(data[23].cards);
      setTopFreeTravel(data[24].cards);
      setTopFreeUtilities(data[25].cards);
      setTopFreeWeather(data[26].cards);
      setTopPaidAll(data[27].cards);
      setTopGrossingAll(data[28].cards);
      setTopAppNews(data[29].cards);
      setLoading(false);
      setError(false);
      //console.log(data);
      setAppIsLoading(false);
      setError(false);
    } catch (e) {
      setError(true);
      setAppIsLoading(false);
      //console.error(e);
    }
  };

  //Render Data
  useEffect(() => {
    fetchApps();
  }, []);

  //Error Logic
  if (error) {
    return (
      <Fragment>
        <TopHeader onLogoPress={fetchApps} onRefreshPress={fetchApps} />
        <ScrollView
          key={"App Scrollview"}
          style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 180,
            }}
          >
            <Text>
              Unable to fetch feeds. Please hit app tab icon to refresh
            </Text>
          </View>
        </ScrollView>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <TopHeader onLogoPress={fetchApps} onRefreshPress={fetchApps} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        key={"App Scrollview"}
        style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
        contentContainerStyle={{ alignItems: "center" }}
      >
        {/* <View style={styles.headerWrap}>
        <Text style={styles.header}>Top Charts</Text>
        </View> */}
        {/* <TopAppNewsFeed
          data={topAppNews}
          isLoading={appIsLoading}
          error={error}
          headerTitle={'News'}
        /> */}
        <AppFeed
          data={topFreeAll}
          isError={error}
          isLoading={appIsLoading}
          headerTitle="Top Free"
        />
        <AppFeed
          data={topPaidAll}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Paid Apps"}
        />
        <AppFeed
          data={topGrossingAll}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Grossing Apps"}
        />
        <AppFeed
          data={topFreeBooks}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Books"}
        />
        <AppFeed
          data={topFreeBusiness}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Business"}
        />
        <AppFeed
          data={topFreeDevelopmentTools}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Dev Tools"}
        />
        <AppFeed
          data={topFreeEducation}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Education"}
        />
        <AppFeed
          data={topFreeEntertainment}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Entertainment"}
        />
        <AppFeed
          data={topFreeFoodDrink}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Food and Drink"}
        />
        <AppFeed
          data={topFreeGames}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Games"}
        />
        <AppFeed
          data={topFreeDesign}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Design"}
        />
        <AppFeed
          data={topFreeHealth}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Health"}
        />
        <AppFeed
          data={topFreeKids}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Kids"}
        />
        <AppFeed
          data={topFreeLifestyle}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Lifestyle"}
        />
        <AppFeed
          data={topFreeMedical}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Medical"}
        />
        <AppFeed
          data={topFreeMusic}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Music"}
        />
        <AppFeed
          data={topFreeNavigation}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Navigation"}
        />
        <AppFeed
          data={topFreeNews}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top News"}
        />
        <AppFeed
          data={topFreePhoto}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Photo"}
        />
        <AppFeed
          data={topFreeProductivity}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Productivity"}
        />
        <AppFeed
          data={topFreeReference}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Reference"}
        />
        <AppFeed
          data={topFreeShopping}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Shopping"}
        />
        <AppFeed
          data={topFreeSocialNetworking}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Social"}
        />
        <AppFeed
          data={topFreeSports}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Sports"}
        />
        <AppFeed
          data={topFreeTravel}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Travel"}
        />

        <AppFeed
          data={topFreeUtilities}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Utilities"}
        />
        <View style={{ marginBottom: 120 }} />
      </ScrollView>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  wrap: {
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 20,
    borderRadius: 30,
    padding: 40,
  },
  bottomWrap: {
    marginBottom: 180,
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 10,
    borderRadius: 30,
    padding: 10,
  },
  title: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 18,
    color: "#FFF",
    padding: 6,
  },
  header: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 16,
    color: "#393d3d",
    padding: 6,
  },
  titleWrap: {
    alignItems: "center",
    alignContent: "center",
  },
  headerWrap: {
    paddingLeft: 20,
  },
});
