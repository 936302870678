import React, { useState, useEffect, Fragment } from "react";
import { ScrollView, Text, StyleSheet, View } from "react-native";
import TopHeader from "../navigation/TopHeader";
import AppFeed from "../components/feeds/apps/AppFeed";
import TopAppNewsFeed from "../components/feeds/apps/AppNewsFeed";
import FilmFeed from "../components/feeds/film/FilmFeed";
import TopFilmNewsFeed from "../components/feeds/film/FilmNewsFeed";

const FilmScreen = () => {
  const [loading, setLoading] = useState(false);
  const [appIsLoading, setAppIsLoading] = useState(true);
  const [mostPopular, setMostPopular] = useState(false);
  const [error, setError] = useState(false);
  const [isFilmScreenActive, setIsFilmScreenActive] = useState(true);
  const [topAction, setTopAction] = useState([]);
  const [topAdventure, setTopAdventure] = useState([]);
  const [topAnimation, setTopAnimation] = useState([]);
  const [topBiography, setTopBiography] = useState([]);
  const [topComedy, setTopComedy] = useState([]);
  const [topCrime, setTopCrime] = useState([]);
  const [topDocumentary, setTopDocumentary] = useState([]);
  const [topDrama, setTopDrama] = useState([]);
  const [topFamily, setTopFamily] = useState([]);
  const [topFantasy, setTopFantasy] = useState([]);
  const [topHistory, setTopHistory] = useState([]);
  const [topHorror, setTopHorror] = useState([]);
  const [topMusical, setTopMusical] = useState([]);
  const [topMystery, setTopMystery] = useState([]);
  const [topRomance, setTopRomance] = useState([]);
  const [topSciFi, setTopSciFi] = useState([]);
  const [topSport, setTopSport] = useState([]);
  const [topThriller, setTopThriller] = useState([]);
  const [topWar, setTopWar] = useState([]);
  const [topWestern, setTopWestern] = useState([]);
  const [topFilmNews, setTopFilmNews] = useState([]);
  const [topNowPlaying, setNowPlaying] = useState([]);
  const [topRated, setTopRated] = useState([]);

  //Fetch logic per feed
  const fetchFilms = async () => {
    setAppIsLoading(true);
    try {
      const response = await fetch(
        `https://youtapia-temp8.uc.r.appspot.com/v1/api/feed/101,110,111,112,113,114,115,116,117,118,118,119,120,121,122,123,124,125,126,127,128,129,1212,104,107,108`,
        {
          method: "GET",
          credentials: "same-origin",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setMostPopular(data[0].cards);
      setTopAction(data[1].cards);
      setTopAdventure(data[2].cards);
      setTopAnimation(data[3].cards);
      setTopBiography(data[4].cards);
      setTopComedy(data[5].cards);
      setTopCrime(data[6].cards);
      setTopDocumentary(data[7].cards);
      setTopDrama(data[8].cards);
      setTopFamily(data[9].cards);
      setTopFantasy(data[10].cards);
      setTopHistory(data[11].cards);
      setTopHorror(data[12].cards);
      setTopMusical(data[13].cards);
      setTopMystery(data[14].cards);
      setTopRomance(data[15].cards);
      setTopSciFi(data[16].cards);
      setTopSport(data[17].cards);
      setTopThriller(data[18].cards);
      setTopWar(data[19].cards);
      setTopWestern(data[20].cards);
      setTopFilmNews(data[21].cards);
      setNowPlaying(data[22].cards);
      setTopRated(data[23].cards);
      setLoading(false);
      setError(false);
      //console.log(data);
      setAppIsLoading(false);
      setError(false);
    } catch (e) {
      setError(true);
      setAppIsLoading(false);
      //console.error(e);
    }
  };

  //Render Data
  useEffect(() => {
    fetchFilms();
  }, []);

  //Error Logic
  if (error) {
    return (
      <Fragment>
        <TopHeader onLogoPress={fetchFilms} onRefreshPress={fetchFilms} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          key={"App Scrollview"}
          style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
          contentContainerStyle={{ alignItems: "center" }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 180,
            }}
          >
            <Text>
              Unable to fetch feeds. Please hit app tab icon to refresh
            </Text>
          </View>
        </ScrollView>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <TopHeader onLogoPress={fetchFilms} onRefreshPress={fetchFilms} />
      <ScrollView
        style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
        contentContainerStyle={{ alignItems: "center" }}
      >
        {/* <TopFilmNewsFeed
          data={topFilmNews}
          isError={error}
          isLoading={appIsLoading}
          headerTitle="News"
        /> */}
        <FilmFeed
          data={mostPopular}
          isError={error}
          isLoading={appIsLoading}
          headerTitle="Most Popular"
        />
        <FilmFeed
          data={topNowPlaying}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Now Playing"}
        />
        <FilmFeed
          data={topAction}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Action"}
        />
        <FilmFeed
          data={topAdventure}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Adventure"}
        />
        <FilmFeed
          data={topAnimation}
          isError={error}
          isLoading={appIsLoading}
          headerTitle="Top Animation"
        />
        <FilmFeed
          data={topBiography}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Biography"}
        />
        <FilmFeed
          data={topComedy}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Comedy"}
        />
        <FilmFeed
          data={topCrime}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Crime"}
        />
        <FilmFeed
          data={topDocumentary}
          isError={error}
          isLoading={appIsLoading}
          headerTitle="Top Documentary"
        />
        <FilmFeed
          data={topDrama}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Drama"}
        />
        <FilmFeed
          data={topFamily}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Family"}
        />
        <FilmFeed
          data={topFantasy}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Fantasy"}
        />
        <FilmFeed
          data={topHistory}
          isError={error}
          isLoading={appIsLoading}
          headerTitle="Top History"
        />
        <FilmFeed
          data={topHorror}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Horror"}
        />
        <FilmFeed
          data={topMusical}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Musical"}
        />
        <FilmFeed
          data={topMystery}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Mystery"}
        />
        <FilmFeed
          data={topRomance}
          isError={error}
          isLoading={appIsLoading}
          headerTitle="Top Romance"
        />
        <FilmFeed
          data={topSciFi}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Sci Fi"}
        />
        <FilmFeed
          data={topSport}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Sports"}
        />
        <FilmFeed
          data={topThriller}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Thriller"}
        />
        <FilmFeed
          data={topWar}
          isError={error}
          isLoading={appIsLoading}
          headerTitle="Top War"
        />

        <FilmFeed
          data={topWestern}
          isLoading={appIsLoading}
          isError={error}
          headerTitle={"Top Western"}
        />
        <View style={{ marginBottom: 120 }} />
      </ScrollView>
    </Fragment>
  );
};
const styles = StyleSheet.create({
  bottomWrap: {
    marginBottom: 180,
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 10,
    borderRadius: 30,
    padding: 10,
  },
});

export default FilmScreen;
