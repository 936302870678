import React, { useState, useEffect, Fragment } from "react";
import { ScrollView, Text, StyleSheet, View } from "react-native";
import TopHeader from "../navigation/TopHeader";
import ProductFeed from "../components/feeds/products/ProductFeed";

export default function ProductsScreen() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isProductScreenActive, setIsProductScreenActive] = useState(true);
  const [topTrendingProducts, setTopTrendingProducts] = useState([]);
  const [topPopularProducts, setTopPopularProducts] = useState([]);
  const [topProductNews, setTopProductNews] = useState([]);
  const [topPopularAppliances, setTopPopularAppliances] = useState([]);
  const [topTrendingAppliances, setTopTrendingAppliances] = useState([]);
  const [topTvHomeTheatre, setTopTvHomeTheatre] = useState([]);
  const [topComputerTablets, setTopComputerTablets] = useState([]);
  const [topCamerasDrones, setTopCamerasDrones] = useState([]);
  const [topCellPhones, setTopCellPhones] = useState([]);
  const [topAudio, setTopAudio] = useState([]);
  const [topVideoGames, setTopVideoGames] = useState([]);
  const [topMoviesMusic, setTopMoviesMusic] = useState([]);
  const [topCarElectronics, setTopCarElectronics] = useState([]);
  const [topWearableTech, setTopWearableTech] = useState([]);
  const [topHealthFitness, setTopHealthFitness] = useState([]);
  const [topApple, setTopApple] = useState([]);
  const [topHomeOffice, setTopHomeOffice] = useState([]);
  const [topSmartHome, setTopSmartHome] = useState([]);
  const [topToysGames, setTopToysGames] = useState([]);

  //Fetch Products
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://youtapia-temp8.uc.r.appspot.com/v1/api/feed/501,502,503,504,505,506,507,508,509,510,511,512,513,514,515,516,517,518`,
        {
          method: "GET",
          credentials: "same-origin",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      const trendingProducts = data[0].cards;
      const trendingAppliances = data[1].cards;
      const popularProducts = data[2].cards;
      const popularAppliances = data[3].cards;
      const topTVHomeTheatreData = data[4].cards;
      const topComputerTabletData = data[5].cards;
      const topCamerasDronesData = data[6].cards;
      const topCellPhonesData = data[7].cards;
      const topAudioData = data[8].cards;
      const topVideoGameData = data[9].cards;
      const topMoviesMusicData = data[10].cards;
      const topCarElectronicsData = data[11].cards;
      const topWearableTechData = data[12].cards;
      const topHealthFitnessData = data[13].cards;
      const topAppleData = data[14].cards;
      const topHomeOfficeData = data[15].cards;
      const topSmartHomeData = data[16].cards;
      const topToysGamesData = data[17].cards;
      //console.log(data);
      setTopTrendingProducts(trendingProducts);
      setTopTrendingAppliances(trendingAppliances);
      setTopPopularProducts(popularProducts);
      setTopPopularAppliances(popularAppliances);
      setTopTvHomeTheatre(topTVHomeTheatreData);
      setTopComputerTablets(topComputerTabletData);
      setTopCamerasDrones(topCamerasDronesData);
      setTopCellPhones(topCellPhonesData);
      setTopAudio(topAudioData);
      setTopVideoGames(topVideoGameData);
      setTopMoviesMusic(topMoviesMusicData);
      setTopCarElectronics(topCarElectronicsData);
      setTopWearableTech(topWearableTechData);
      setTopHealthFitness(topHealthFitnessData);
      setTopApple(topAppleData);
      setTopHomeOffice(topHomeOfficeData);
      setTopSmartHome(topSmartHomeData);
      setTopToysGames(topToysGamesData);

      setLoading(false);
      setError(false);
    } catch (e) {
      setError(true);
      setLoading(false);
      //console.error(e);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  if (error) {
    return (
      <Fragment>
        <TopHeader onLogoPress={fetchProducts} onRefreshPress={fetchProducts} />
        <ScrollView
          key={"App Scrollview"}
          style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 180,
            }}
          >
            <Text>
              Unable to fetch feeds. Please hit app tab icon to refresh
            </Text>
          </View>
        </ScrollView>
      </Fragment>
    );
  }
  return (
    <>
      <TopHeader onLogoPress={fetchProducts} onRefreshPress={fetchProducts} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        key={"App Scrollview"}
        style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
        contentContainerStyle={{ alignItems: "center" }}
      >
        <ProductFeed
          data={topTrendingProducts}
          loading={loading}
          error={error}
          headerTitle={"Top 100"}
        />
        <ProductFeed
          data={topPopularProducts}
          loading={loading}
          error={error}
          headerTitle={"Popular Products"}
        />
        <ProductFeed
          data={topApple}
          loading={loading}
          error={error}
          headerTitle={"Top Apple"}
        />
        <ProductFeed
          data={topHealthFitness}
          loading={loading}
          error={error}
          headerTitle={"Top Health"}
        />
        <ProductFeed
          data={topPopularAppliances}
          loading={loading}
          error={error}
          headerTitle={"Top Appliances"}
        />
        <ProductFeed
          data={topTvHomeTheatre}
          loading={loading}
          error={error}
          headerTitle={"Top TV"}
        />
        <ProductFeed
          data={topComputerTablets}
          loading={loading}
          error={error}
          headerTitle={"Top Computers + Tablets"}
        />
        <ProductFeed
          data={topCamerasDrones}
          loading={loading}
          error={error}
          headerTitle={"Top Cameras + Drones"}
        />
        <ProductFeed
          data={topCellPhones}
          loading={loading}
          error={error}
          headerTitle={"Top Mobile"}
        />
        <ProductFeed
          data={topWearableTech}
          loading={loading}
          error={error}
          headerTitle={"Top Wearable Tech"}
        />
        <ProductFeed
          data={topSmartHome}
          loading={loading}
          error={error}
          headerTitle={"Top Smart Home"}
        />

        <ProductFeed
          data={topAudio}
          loading={loading}
          error={error}
          headerTitle={"Top Audio"}
        />

        {/* <ProductFeed
              data={topVideoGames}
              loading={loading}
              error={error}
              headerTitle={'Top Video Games'}
            />
              <ProductFeed
              data={topMoviesMusic}
              loading={loading}
              error={error}
              headerTitle={'Top Movies and Music'}
            />
             <ProductFeed
              data={topCarElectronics}
              loading={loading}
              error={error}ß
              headerTitle={'Top Car Electronics'}
            /> */}
        <View style={{ marginBottom: 120 }} />
      </ScrollView>
    </>
  );
}
const styles = StyleSheet.create({
  bottomWrap: {
    marginBottom: 180,
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 10,
    borderRadius: 30,
    padding: 10,
  },
});
