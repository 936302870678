import { StyleSheet } from "react-native";

export default StyleSheet.create({
  linkContainer: {
    borderRadius: 9,
    width: 210,
    paddingLeft: 12,
    paddingRight: 10,
    paddingBottom: 8,
  },
});
