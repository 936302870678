import React, { useState, useEffect, Fragment } from "react";
import { ScrollView, Text, StyleSheet, View } from "react-native";
import TopHeader from "../navigation/TopHeader";
import AppFeed from "../components/feeds/apps/AppFeed";
import TopAppNewsFeed from "../components/feeds/apps/AppNewsFeed";
import BookFeed from "../components/feeds/books/BookFeed";

export default function BooksScreen() {
  const [loading, setLoading] = useState(true);
  const [appIsLoading, setAppIsLoading] = useState(true);
  const [newsLoading, setNewsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isBookScreenActive, setIsBookScreenActive] = useState(true);
  const [topTeenYA, setTopTeenYA] = useState([]);
  const [topBookNews, setTopBookNews] = useState([]);
  const [topNewReleases, setTopNewReleases] = useState([]);
  const [topComingSoon, setTopComingSoon] = useState([]);
  const [topFiction, setTopFiction] = useState([]);
  const [topMystery, setTopMystery] = useState([]);
  const [topAudio, setTopAudio] = useState([]);
  const [topTech, setTopTech] = useState([]);
  const [topSelfHelp, setTopSelfHelp] = useState([]);
  const [topLiteraryFiction, setTopLiteraryFiction] = useState([]);
  const [topHistory, setTopHistory] = useState([]);
  const [topHealth, setTopHealth] = useState([]);
  const [topGraphicNovel, setTopGraphicNovel] = useState([]);
  const [topCookBook, setTopCookBook] = useState([]);
  const [topBusiness, setTopBusiness] = useState([]);
  const [topBiograhy, setTopBiography] = useState([]);
  const [topThriller, setTopThriller] = useState([]);
  const [topReligion, setTopReligion] = useState([]);
  const [topScifi, setTopSciFi] = useState([]);
  const [topRomance, setTopRomance] = useState([]);
  const [topPolitics, setTopPolitics] = useState([]);
  const [topPoetry, setTopPoetry] = useState([]);
  const [topNature, setTopNature] = useState([]);
  const [top100, setTop100] = useState([]);
  const [newYorkTimesFiction, setNewYorkTimesFiction] = useState([]);
  const [newYorkTimesNonFiction, setNewYorkTimesNonFiction] = useState([]);

  //Fetch logic per feed
  const fetchApps = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://youtapia-temp8.uc.r.appspot.com/v1/api/feed/311,312,313,314,315,316,317,318,319,320,321,322,323,324,325,326,327,328,329,330,331,332,333,1218,301,302?pageNumber=0&pageSize=40`,
        {
          method: "GET",
          credentials: "same-origin",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setTopTeenYA(data[0].cards);
      setTopNewReleases(data[1].cards);
      setTopComingSoon([2].cards);
      setTopFiction(data[3].cards);
      setTopMystery(data[4].cards);
      setTopAudio(data[5].cards);
      setTopTech(data[6].cards);
      setTopSelfHelp(data[7].cards);
      setTopLiteraryFiction(data[8].cards);
      setTopHistory(data[9].cards);
      setTopHealth(data[10].cards);
      setTopGraphicNovel(data[11].cards);
      setTopCookBook(data[12].cards);
      setTopBusiness(data[13].cards);
      setTopBiography(data[14].cards);
      setTopThriller(data[15].cards);
      setTopReligion(data[16].cards);
      setTopSciFi(data[17].cards);
      setTopRomance(data[18].cards);
      setTopPolitics(data[19].cards);
      setTopPoetry(data[20].cards);
      setTopNature(data[21].cards);
      setTop100(data[22].cards);
      setTopBookNews(data[23].cards);
      setNewYorkTimesFiction(data[24].cards);
      setNewYorkTimesNonFiction(data[25].cards);
      setLoading(false);
      setError(false);
      // console.log(data);

      setError(false);
    } catch (e) {
      setError(true);
      setLoading(false);
      //console.error(e);
    }
  };

  //Render Data
  useEffect(() => {
    fetchApps();
  }, []);

  //Error Logic
  if (error) {
    return (
      <Fragment>
        <TopHeader onLogoPress={fetchApps} onRefreshPress={fetchApps} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          key={"App Scrollview"}
          style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
          contentContainerStyle={{ alignItems: "center" }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 180,
            }}
          >
            <Text>
              Unable to fetch feeds. Please hit app tab icon to refresh
            </Text>
          </View>
        </ScrollView>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <TopHeader onLogoPress={fetchApps} onRefreshPress={fetchApps} />
      <ScrollView
        style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
        contentContainerStyle={{ alignItems: "center" }}
      >
        {/* <View style={styles.headerWrap}>
        <Text style={styles.header}>Top Charts</Text>
        </View> */}
        {/* <TopAppNewsFeed
          data={topAppNews}
          isLoading={appIsLoading}
          error={error}
          headerTitle={'News'}
        /> */}
        <BookFeed
          data={top100}
          loading={loading}
          error={error}
          headerTitle={"Top 100"}
        />
        <BookFeed
          data={newYorkTimesFiction}
          loading={loading}
          error={error}
          headerTitle={"New York Times Fiction"}
        />
        <BookFeed
          data={newYorkTimesNonFiction}
          loading={loading}
          error={error}
          headerTitle={"New York Times Non-Fiction"}
        />
        {/* <BookFeed
          data={topComingSoon}
          loading={loading}
          error={error}
          headerTitle={"Coming Soon"}
        /> */}
        <BookFeed
          data={topNewReleases}
          loading={loading}
          error={error}
          headerTitle={"New Releases"}
        />
        <BookFeed
          data={topAudio}
          loading={loading}
          error={error}
          headerTitle={"Top Audio"}
        />
        <BookFeed
          data={topBiograhy}
          loading={loading}
          error={error}
          headerTitle={"Top Biography"}
        />
        <BookFeed
          data={topBusiness}
          loading={loading}
          error={error}
          headerTitle={"Top Business"}
        />
        <BookFeed
          data={topCookBook}
          loading={loading}
          error={error}
          headerTitle={"Top Cook Book"}
        />
        <BookFeed
          data={topFiction}
          loading={loading}
          error={error}
          headerTitle={"Top Fiction"}
        />
        <BookFeed
          data={topGraphicNovel}
          loading={loading}
          error={error}
          headerTitle={"Top Graphic Novel"}
        />
        <BookFeed
          data={topHistory}
          loading={loading}
          error={error}
          headerTitle={"Top History"}
        />
        <BookFeed
          data={topHealth}
          loading={loading}
          error={error}
          headerTitle={"Top Health"}
        />
        <BookFeed
          data={topLiteraryFiction}
          loading={loading}
          error={error}
          headerTitle={"Top Literary Fiction"}
        />
        <BookFeed
          data={topMystery}
          loading={loading}
          error={error}
          headerTitle={"Top Mystery"}
        />
        <BookFeed
          data={topNature}
          loading={loading}
          error={error}
          headerTitle={"Top Nature"}
        />
        <BookFeed
          data={topPoetry}
          loading={loading}
          error={error}
          headerTitle={"Top Poetry"}
        />
        <BookFeed
          data={topPolitics}
          loading={loading}
          error={error}
          headerTitle={"Top Politics"}
        />
        <BookFeed
          data={topReligion}
          loading={loading}
          error={error}
          headerTitle={"Top Religion"}
        />
        <BookFeed
          data={topRomance}
          loading={loading}
          error={error}
          headerTitle={"Top Romance"}
        />
        <BookFeed
          data={topScifi}
          loading={loading}
          error={error}
          headerTitle={"Top Sci-Fi"}
        />
        <BookFeed
          data={topSelfHelp}
          loading={loading}
          error={error}
          headerTitle={"Top Self Help"}
        />
        <BookFeed
          data={topTech}
          loading={loading}
          error={error}
          headerTitle={"Top Tech"}
        />
        <BookFeed
          data={topTeenYA}
          loading={loading}
          error={error}
          headerTitle={"Top Teen"}
        />

        <BookFeed
          data={topThriller}
          loading={loading}
          error={error}
          headerTitle={"Top Thriller"}
        />
        <View style={{ marginBottom: 120 }} />
      </ScrollView>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  wrap: {
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 20,
    borderRadius: 30,
    padding: 40,
  },
  bottomWrap: {
    marginBottom: 180,
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 10,
    borderRadius: 30,
    padding: 10,
  },
  title: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 18,
    color: "#FFF",
    padding: 6,
  },
  header: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 16,
    color: "#393d3d",
    padding: 6,
  },
  titleWrap: {
    alignItems: "center",
    alignContent: "center",
  },
  headerWrap: {
    paddingLeft: 20,
  },
});
