import React from "react";
import {
  StyleSheet,
  View,
  Text,
  Linking,
  Platform,
  TouchableOpacity,
} from "react-native";
import {
  faTv,
  faBolt,
  faBook,
  faPizzaSlice,
  faMicrophone,
  faGuitar,
  faTags,
  faVideo,
  faGamepad,
  faMobile,
  faFemale,
  faMale,
  faFingerprint,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

import { useNavigation } from "@react-navigation/core";

function TopHeader({ Title, onRefreshPress, onLogoPress }) {
  const navigation = useNavigation();

  return (
    <>
      <View style={styles.scrollView}>
        <TouchableOpacity onPress={onLogoPress} style={styles.titleWrap}>
          <FontAwesomeIcon icon={faFingerprint} color={"#000"} size={36} />
        </TouchableOpacity>
        <View style={styles.buttonWrap}>
          <TouchableOpacity onPress={onRefreshPress} style={styles.button}>
            <FontAwesomeIcon icon={faRefresh} color={"#000"} size={24} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL(
                "https://apps.apple.com/ph/app/youtapia/id6444806099"
              );
            }}
            style={styles.button}
          >
            <FontAwesomeIcon icon={faMobile} color={"#000"} size={24} />
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    backgroundColor: "#FFF",
    position: "absolute",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    top: 0,
    zIndex: 1,
    opacity: 0.8,
    height: Platform.OS === "ios" ? 70 : 60,
  },
  titleWrap: {
    flex: 1,
    alignContent: "flex-start",
    //paddingTop: Platform.OS === "ios" ? 30 : 10,
    paddingLeft: 14,
  },
  titleText: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 28,
    color: "#fff",
  },
  buttonWrap: {
    flexDirection: "row",
    alignSelf: "center",
    // paddingTop: Platform.OS === "ios" ? 20 : 10,
    marginRight: 10,
  },
  button: {
    // paddingTop: Platform.OS === "ios" ? 20 : 10,
    padding: 10,
  },
});

export default TopHeader;
