import React, { useCallback, Fragment } from "react";
import {
  FlatList,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
} from "react-native";

//cards

//styles
import styles from "../../../../styles/filter.feed.style";
import FilterLoading from "../filter.feed.loading";
import { MemoizedGoogleCard } from "../../../cards/google/GoogleCard";
import { MemoizedWikipediaCard } from "../../../cards/news/WikipediaCard";

function GoogleFeed({
  //data
  googleData,
  wikipediaData,

  //on press
  onGooglePress,
  onWikipediaPress,

  //show
  showGoogle,
  showWikipedia,

  //loading + error
  loading,
  error,
}) {
  const renderItem = ({ item }) => <MemoizedGoogleCard google={item} />;
  const renderWikiItem = ({ item }) => (
    <MemoizedWikipediaCard wikipedia={item} />
  );
  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const ITEM_HEIGHT = 350;

  const getItemLayout = useCallback(
    (data, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    []
  );

  if (loading === true) {
    return <FilterLoading />;
  }
  if (error === true || googleData.length < 0 || wikipediaData.length < 0) {
    return null;
  }

  return (
    <View style={styles.wrap}>
      <View>
        <Text style={styles.headerText}>SEARCH</Text>
      </View>
      <View style={styles.headerWrap}>
        <View style={styles.filterButtonWrap}>
          {showGoogle ? (
            <TouchableOpacity
              onPress={onGooglePress}
              style={styles.linearButton}
            >
              <Text style={[styles.header, { color: "#fff" }]}>Google</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity onPress={onGooglePress} style={[styles.button]}>
              <Text style={[styles.header, { color: "#fff" }]}>Google</Text>
            </TouchableOpacity>
          )}
          {showWikipedia ? (
            <TouchableOpacity
              onPress={onWikipediaPress}
              style={styles.linearButton}
            >
              <Text style={[styles.header, { color: "#fff" }]}>Wikipedia</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              onPress={onWikipediaPress}
              style={[styles.button]}
            >
              <Text style={[styles.header, { color: "#fff" }]}>Wikipedia</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
      {showGoogle && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          keyExtractor={keyExtractor}
          data={googleData}
          renderItem={renderItem}
        />
      )}
      {showWikipedia && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={wikipediaData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          keyExtractor={keyExtractor}
          renderItem={renderWikiItem}
        />
      )}
    </View>
  );
}

export default GoogleFeed;
