import React from 'react';
import { View, Text, Image, TouchableOpacity, ScrollView, StyleSheet, Linking } from 'react-native';



//Navigation
import { useNavigation } from '@react-navigation/native';

//Linking
import Linker from '../../linking/linker';

//Styles


function AmazonCard({ product, loading }) {
    const navigation = useNavigation();
    return (
        <View style={styles.descriptionContainer}>
            <View style={styles.imageContainer}>
                <Image
                    style={styles.image}
                    source={{
                        uri: product.imageUrl,
                    }}
                    resizeMode='contain' />
            </View>
            <View
                style={styles.bottomContainer}>
                <View style={styles.titleWrap}>
                    <Text style={styles.title} numberOfLines={2}>
                        {product.title}
                    </Text>
                </View>
                <ScrollView
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    style={styles.linkContainer}>
                    {product.links.map((link, index) => {
                        return (
                            <TouchableOpacity
                                key={index.toString()}
                                onPress={() => {
                                    Linking.openURL(`${link.link}`);
                                }}
                                style={{ padding: 2 }}>
                                <Linker link={link.type} />
                            </TouchableOpacity>
                        );
                    })}
                </ScrollView>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    bottomContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000',
        borderBottomRightRadius: 9,
        borderBottomLeftRadius: 9,
        flex: 1,
        flexDirection: 'column',
        height: 150,
        paddingBottom: 2,
        paddingTop: 8,
        width: 282,


    },
    descriptionContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: .5,
        borderColor: '#dadada',
        elevation: 1,
        margin: 16,
        shadowColor: '#000',
        shadowOffset: {
            width: 2,
            height: 1,
        },
        shadowOpacity: 0.58,
        shadowRadius: 2.0,
        borderRadius: 9
    },
    image: {
        height: 168,
        width: 168,
    },
    imageContainer: {
        alignContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        height: 200,
        paddingTop: 12,
        width: 280,
    },
    linkContainer: {
        padding: 12,
    },
    title: {
        color: '#FFF',
        fontFamily: 'AvenirNextCondensed-Bold',
        fontSize: 16,
        padding: 6,
    },
    titleWrap: {
        alignItems: 'center',
        alignContent: 'center',
    },
})
export const MemoizedAmazonCard = React.memo(AmazonCard);