import React, { useCallback, Fragment } from "react";
import {
  FlatList,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { MemoizedUniversalSocialCard } from "../../cards/universal/UniversalSocialCard";

//styles
import styles from "../../../styles/filter.feed.style";
import FilterLoading from "./filter.feed.loading";

function FilterFeed({
  data,
  twitterData,
  redditData,
  onSocialPress,
  onTwitterPress,
  twitterLoading,
  onRedditPress,
  headerTitle,
  showSocial,
  showReddit,
  showTwitter,
  showYouTube,
  youtubeData,
  onYouTubePress,
  loading,
  error,
}) {
  const renderItem = ({ item }) => <MemoizedUniversalSocialCard card={item} />;

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const ITEM_HEIGHT = 350;

  const getItemLayout = useCallback(
    (data, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    []
  );
  if (twitterLoading) {
    return <FilterLoading />;
  }
  if (loading === true) {
    return <FilterLoading />;
  }
  if (error === true) {
    return null;
  }
  return (
    <View style={styles.wrap}>
      <View>
        <Text style={styles.headerText}>SOCIAL</Text>
      </View>
      <View style={styles.headerWrap}>
        <View style={styles.filterButtonWrap}>
          <TouchableOpacity
            onPress={onSocialPress}
            style={[
              styles.button,
              {
                backgroundImage: showSocial
                  ? "linear-gradient(to top right, rgba(0,0,0) 60%,rgb(247, 220, 111  ))"
                  : null,
                shadowOffset: showSocial
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showSocial ? 0.98 : 0,
                shadowRadius: showSocial ? 3.0 : 0,
                elevation: showSocial ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[styles.header, { color: showSocial ? "#fff" : "#fff" }]}
            >
              Social
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={onTwitterPress}
            style={[
              styles.button,
              {
                backgroundImage: showTwitter
                  ? "linear-gradient(to top right, rgba(0,0,0) 44%, rgba(0,204,204))"
                  : null,
                shadowOffset: showTwitter
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showTwitter ? 0.98 : 0,
                shadowRadius: showTwitter ? 3.0 : 0,
                elevation: showTwitter ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[styles.header, { color: showTwitter ? "#fff" : "#fff" }]}
            >
              Twitter
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onRedditPress}
            style={[
              styles.button,
              {
                backgroundImage: showReddit
                  ? "linear-gradient(to top right, rgba(0,0,0), rgba(255,0,0,1))"
                  : null,
                shadowOffset: showReddit
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showReddit ? 0.98 : 0,
                shadowRadius: showReddit ? 3.0 : 0,
                elevation: showReddit ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[styles.header, { color: showReddit ? "#fff" : "#fff" }]}
            >
              Reddit
            </Text>
          </TouchableOpacity>
        </View>

        {/* <TouchableOpacity
                    onPress={onRedditPress}
                    style={[styles.button,]}
                >
                    <Text style={[styles.header, { color: showReddit ? '#000' : '#dadada' }]}>Reddit</Text>
                </TouchableOpacity> */}
      </View>
      {showSocial && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={data}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showTwitter && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={twitterData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          //keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showReddit && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={redditData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          //keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
    </View>
  );
}

export default FilterFeed;
