import React, { useCallback } from "react";
import {
  FlatList,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import "react-loading-skeleton/dist/skeleton.css";

//styles
import styles from "../../../../styles/filter.feed.style";
import BreakingFilterLoading from "../breaking/breaking.feed.loading";
import { MemoizedTelevisionCard } from "../../../cards/television/TelevisionCard";

function TelevisionFilterFeed({
  //data
  topTelevisionData,
  onTvTodayData,
  topHuluData,
  topNetflixData,

  //error
  error,

  //onPress
  onTopTelevisionPress,
  onTvTodayPress,
  onHuluPress,
  onNetflixPress,

  //loading
  loading,

  //show
  showTopTelevision,
  showOnTvToday,
  showHulu,
  showNetflix,
}) {
  const renderItem = ({ item }) => <MemoizedTelevisionCard tv={item} />;

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const ITEM_HEIGHT = 350;

  const getItemLayout = useCallback(
    (data, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    []
  );

  if (loading) {
    return <BreakingFilterLoading />;
  }

  if (error === true) {
    return null;
  }
  return (
    <View style={styles.wrap}>
      <View>
        <Text style={styles.headerText}>TELEVISION</Text>
      </View>
      <View style={styles.headerWrap}>
        <View style={styles.filterButtonWrap}>
          <TouchableOpacity
            onPress={onTopTelevisionPress}
            style={[
              styles.button,
              {
                backgroundImage: showTopTelevision
                  ? "linear-gradient(to top right, rgba(0,0,0) 60%,rgb(247, 220, 111  ))"
                  : null,
                shadowOffset: showTopTelevision
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showTopTelevision ? 0.98 : 0,
                shadowRadius: showTopTelevision ? 3.0 : 0,
                elevation: showTopTelevision ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showTopTelevision ? "#fff" : "#fff" },
              ]}
            >
              Trending
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onTvTodayPress}
            style={[
              styles.button,
              {
                backgroundImage: showOnTvToday
                  ? "linear-gradient(to top right, rgba(0,0,0), rgba(91, 44, 111))"
                  : null,
                shadowOffset: showOnTvToday
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showOnTvToday ? 0.98 : 0,
                shadowRadius: showOnTvToday ? 3.0 : 0,
                elevation: showOnTvToday ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showOnTvToday ? "#fff" : "#fff" },
              ]}
            >
              On Tv
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={onNetflixPress}
            style={[
              styles.button,
              {
                backgroundImage: showNetflix
                  ? "linear-gradient(to top right, rgba(0,0,0) 44%, rgba(27, 79, 114))"
                  : null,
                shadowOffset: showNetflix
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showNetflix ? 0.98 : 0,
                shadowRadius: showNetflix ? 3.0 : 0,
                elevation: showNetflix ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[styles.header, { color: showNetflix ? "#fff" : "#fff" }]}
            >
              Top Netflix
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onHuluPress}
            style={[
              styles.button,
              {
                backgroundImage: showHulu
                  ? "linear-gradient(to top right, rgba(0,0,0) 44%, rgba(27, 79, 114))"
                  : null,
                shadowOffset: showHulu
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showHulu ? 0.98 : 0,
                shadowRadius: showHulu ? 3.0 : 0,
                elevation: showHulu ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[styles.header, { color: showHulu ? "#fff" : "#fff" }]}
            >
              Top Hulu
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      {showTopTelevision && (
        <FlatList
          showsHorizontalScrollIndicator={false}
          disableVirtualization={true}
          horizontal
          data={topTelevisionData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showOnTvToday && (
        <FlatList
          showsHorizontalScrollIndicator={false}
          disableVirtualization={true}
          horizontal
          data={onTvTodayData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showNetflix && (
        <FlatList
          showsHorizontalScrollIndicator={false}
          disableVirtualization={true}
          horizontal
          data={topNetflixData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showHulu && (
        <FlatList
          showsHorizontalScrollIndicator={false}
          disableVirtualization={true}
          horizontal
          data={topHuluData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
    </View>
  );
}

export default TelevisionFilterFeed;
