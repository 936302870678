import React, { Component } from "react";
import { Animated, StyleSheet } from "react-native";

class VideoLoadingBar extends Component {
  state = {
    AnimatedOpacity: new Animated.Value(1),
  };

  componentDidMount() {
    Animated.sequence([
      Animated.delay(this.props.animationOffset),

      Animated.loop(
        Animated.sequence([
          Animated.timing(this.state.AnimatedOpacity, {
            toValue: 0,
            duration: 3000,
            useNativeDriver: true,
          }),

          Animated.timing(this.state.AnimatedOpacity, {
            toValue: 1,
            duration: 3000,
            useNativeDriver: true,
          }),
        ])
      ),
    ]).start();
  }

  render() {
    let { AnimatedOpacity } = this.state;

    return (
      <Animated.View style={[styles.wrap, { opacity: AnimatedOpacity }]}>
        {this.props.children}
      </Animated.View>
    );
  }
}

const styles = StyleSheet.create({
  wrap: {
    backgroundColor: "#000",
    width: 250,
    height: 100,
    margin: 10,
    borderRadius: 9,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.58,
    shadowRadius: 2.0,
    elevation: 6,
  },
});

export default VideoLoadingBar;
