import React, { useCallback } from "react";
import { FlatList, View, Text, TouchableOpacity } from "react-native";
import "react-loading-skeleton/dist/skeleton.css";

//styles
import styles from "../../../../styles/filter.feed.style";
import BreakingFilterLoading from "../breaking/breaking.feed.loading";
import { MemoizedGameCard } from "../../../cards/gaming/GameCard";

function GamingFilterFeed({
  //data
  topGamingData,
  topMobileGamingData,
  recentlyReleasedData,
  topSteamData,
  topGamingVideosData,

  //error
  error,

  //onPress
  onTopGamingPress,
  onMobileGamingPress,
  onRecentlyReleasedPress,
  onSteamPress,
  onGamingVideosPress,

  //loading
  loading,

  //show
  showTopGaming,
  showMobileGaming,
  showRecentlyReleasedGaming,
  showTopSteam,
  showGamingVideos,
}) {
  const renderItem = ({ item }) => <MemoizedGameCard game={item} />;

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const ITEM_HEIGHT = 350;

  const getItemLayout = useCallback(
    (data, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    []
  );

  if (loading) {
    return <BreakingFilterLoading />;
  }

  if (error === true) {
    return null;
  }
  return (
    <View style={styles.wrap}>
      <View>
        <Text style={styles.headerText}>GAMING</Text>
      </View>
      <View style={styles.headerWrap}>
        <View style={styles.filterButtonWrap}>
          <TouchableOpacity
            onPress={onTopGamingPress}
            style={[
              styles.button,
              {
                backgroundImage: showTopGaming
                  ? "linear-gradient(to top right, rgba(0,0,0) 60%,rgb(247, 220, 111  ))"
                  : null,
                shadowOffset: showTopGaming
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showTopGaming ? 0.98 : 0,
                shadowRadius: showTopGaming ? 3.0 : 0,
                elevation: showTopGaming ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showTopGaming ? "#fff" : "#fff" },
              ]}
            >
              Trending
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onSteamPress}
            style={[
              styles.button,
              {
                backgroundImage: showTopSteam
                  ? "linear-gradient(to top right, rgba(0,0,0) 60%,rgb(247, 220, 111  ))"
                  : null,
                shadowOffset: showTopSteam
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showTopSteam ? 0.98 : 0,
                shadowRadius: showTopSteam ? 3.0 : 0,
                elevation: showTopSteam ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[styles.header, { color: showTopSteam ? "#fff" : "#fff" }]}
            >
              Steam
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onMobileGamingPress}
            style={[
              styles.button,
              {
                backgroundImage: showMobileGaming
                  ? "linear-gradient(to top right, rgba(0,0,0) 44%, rgba(27, 79, 114))"
                  : null,
                shadowOffset: showMobileGaming
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showMobileGaming ? 0.98 : 0,
                shadowRadius: showMobileGaming ? 3.0 : 0,
                elevation: showMobileGaming ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showMobileGaming ? "#fff" : "#fff" },
              ]}
            >
              Mobile
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onRecentlyReleasedPress}
            style={[
              styles.button,
              {
                backgroundImage: showRecentlyReleasedGaming
                  ? "linear-gradient(to top right, rgba(0,0,0), rgba(91, 44, 111))"
                  : null,
                shadowOffset: showRecentlyReleasedGaming
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showRecentlyReleasedGaming ? 0.98 : 0,
                shadowRadius: showRecentlyReleasedGaming ? 3.0 : 0,
                elevation: showRecentlyReleasedGaming ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showRecentlyReleasedGaming ? "#fff" : "#fff" },
              ]}
            >
              New
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      {showTopGaming && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          data={topGamingData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showTopSteam && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          data={topSteamData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}

      {showMobileGaming && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          data={topMobileGamingData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showRecentlyReleasedGaming && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          data={recentlyReleasedData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
    </View>
  );
}

export default GamingFilterFeed;
