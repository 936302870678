import React, { useState, useEffect, Fragment } from "react";
import { ScrollView, Text, View, StyleSheet } from "react-native";

import GameFeed from "../components/feeds/gaming/GameFeed";
import TopHeader from "../navigation/TopHeader";
import TopGamingVideosFeed from "../components/feeds/gaming/TopGamingVideosFeed";

export default function GamingScreen() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isGamingScreenActive, setIsGamingScreenActive] = useState(true);
  const [isBottomFeedActive, setIsBottomFeedActive] = useState(true);
  const [recentlyReleased, setRecentlyReleased] = useState([]);
  const [upComing, setUpComing] = useState([]);
  const [topAll, setTopAll] = useState([]);
  const [topMobileFreeGaming, setTopMobileFreeGaming] = useState([]);
  const [topMobilePaidGaming, setTopMobilePaidGaming] = useState([]);
  const [topFighting, setTopFighting] = useState([]);
  const [topShooter, setTopShooter] = useState([]);
  const [topMusic, setTopMusic] = useState([]);
  const [topPuzzle, setTopPuzzle] = useState([]);
  const [topPlatform, setTopPlatform] = useState([]);
  const [topRacing, setTopRacing] = useState([]);
  const [topSimulator, setTopSimulator] = useState([]);
  const [topSport, setTopSport] = useState([]);
  const [topStrategy, setTopStrategy] = useState([]);
  const [topTBS, setTopTBS] = useState([]);
  const [topRPG, setTopRPG] = useState([]);
  const [topAdventure, setTopAdventure] = useState([]);
  const [topArcade, setTopArcade] = useState([]);
  const [topMoba, setTopMoba] = useState([]);
  const [topGamingNews, setTopGamingNews] = useState([]);
  const [topGamingYouTube, setTopGamingYouTube] = useState([]);
  const [recentlyReleasedXbox, setRecentlyReleasedXbox] = useState([]);
  const [recentlyReleasedPlaystation, setRecentlyReleasedPlaystation] =
    useState([]);
  const [recentlyReleasedNintendo, setRecentlyReleasedNintendo] = useState([]);
  const [recentlyReleasedPC, setRecentlyReleasedPC] = useState([]);
  const [mostAnticipated, setMostAnticipated] = useState([]);

  const fetchGaming = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://youtapia-temp8.uc.r.appspot.com/v1/api/Feed/410,411,412,413,414,415,416,417,418,419,420,421,422,423,424,425,426,427,428,429,1216,1107,609,636,401,402,403,404,409`,
        {
          method: "GET",
          credentials: "same-origin",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setRecentlyReleased(data[0].cards);
      setUpComing(data[1].cards);
      setTopAll(data[2].cards);
      setTopFighting(data[3].cards);
      setTopShooter(data[4].cards);
      setTopMusic(data[5].cards);
      setTopPlatform(data[6].cards);
      setTopPuzzle(data[7].cards);
      setTopRacing(data[8].cards);
      setTopSimulator(data[9].cards);
      setTopSport(data[10].cards);
      setTopStrategy(data[11].cards);
      setTopTBS(data[12].cards);
      setTopAdventure(data[13].cards);
      setTopArcade(data[14].cards);
      setTopMoba(data[15].cards);
      setTopRPG(data[16].cards);
      setTopGamingNews(data[17].cards);
      setTopGamingYouTube(data[18].cards);
      setTopMobileFreeGaming(data[19].cards);
      setTopMobilePaidGaming(data[20].cards);
      setRecentlyReleasedXbox(data[21].cards);
      setRecentlyReleasedPlaystation(data[22].cards);
      setRecentlyReleasedNintendo(data[23].cards);
      setRecentlyReleasedPC(data[24].cards);
      setMostAnticipated(data[25].cards);

      setLoading(false);
      setError(false);
      //console.log(data);
    } catch (e) {
      setError(true);
      setLoading(false);
      //console.error(e);
    }
  };

  useEffect(() => {
    fetchGaming();
  }, []);

  if (error) {
    return (
      <Fragment>
        <TopHeader onLogoPress={fetchGaming} onRefreshPress={fetchGaming} />
        <ScrollView
          style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
          contentContainerStyle={{ alignItems: "center" }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 180,
            }}
          >
            <Text>
              Unable to fetch feeds. Please hit gaming tab icon to refresh
            </Text>
          </View>
        </ScrollView>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <TopHeader onLogoPress={fetchGaming} onRefreshPress={fetchGaming} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        key={"App Scrollview"}
        style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
        contentContainerStyle={{ alignItems: "center" }}
      >
        <TopGamingVideosFeed
          data={topGamingYouTube}
          isLoading={loading}
          isError={error}
          headerTitle={"Videos"}
        />
        <GameFeed
          data={topMobileFreeGaming}
          isLoading={loading}
          error={error}
          headerTitle={"Top Mobile Free"}
        />
        <GameFeed
          data={topMobilePaidGaming}
          isLoading={loading}
          error={error}
          headerTitle={"Top Mobile Paid"}
        />
        <GameFeed
          data={topAll}
          isLoading={loading}
          error={error}
          headerTitle={"Top All"}
        />
        <GameFeed
          data={mostAnticipated}
          isLoading={loading}
          error={error}
          headerTitle={"Most Anticipated"}
        />
        <GameFeed
          data={recentlyReleased}
          isLoading={loading}
          error={error}
          headerTitle={"Recently Released"}
        />
        <GameFeed
          data={recentlyReleasedXbox}
          isLoading={loading}
          error={error}
          headerTitle={"Recently Released Xbox"}
        />
        <GameFeed
          data={recentlyReleasedPlaystation}
          isLoading={loading}
          error={error}
          headerTitle={"Recently Released Playstation"}
        />
        <GameFeed
          data={recentlyReleasedNintendo}
          isLoading={loading}
          error={error}
          headerTitle={"Recently Released Nintendo"}
        />
        <GameFeed
          data={recentlyReleasedPC}
          isLoading={loading}
          error={error}
          headerTitle={"Recently Released PC"}
        />
        <GameFeed
          data={upComing}
          isLoading={loading}
          error={error}
          headerTitle={"Up Coming"}
        />
        <GameFeed
          data={topArcade}
          isLoading={loading}
          error={error}
          headerTitle={"Top Arcade"}
        />
        <GameFeed
          data={topAdventure}
          isLoading={loading}
          error={error}
          headerTitle={"Top Adventure"}
        />
        <GameFeed
          data={topFighting}
          isLoading={loading}
          error={error}
          headerTitle={"Top Fighting"}
        />
        <GameFeed
          data={topMoba}
          isLoading={loading}
          error={error}
          headerTitle={"Top MOBA"}
        />
        <GameFeed
          data={topMusic}
          isLoading={loading}
          error={error}
          headerTitle={"Top Music"}
        />
        <GameFeed
          data={topPlatform}
          isLoading={loading}
          error={error}
          headerTitle={"Top Platform"}
        />
        <GameFeed
          data={topPuzzle}
          isLoading={loading}
          error={error}
          headerTitle={"Top Puzzle"}
        />
        <GameFeed
          data={topRacing}
          isLoading={loading}
          error={error}
          headerTitle={"Top Racing"}
        />
        <GameFeed
          data={topRPG}
          isLoading={loading}
          error={error}
          headerTitle={"Top RPG"}
        />
        <GameFeed
          data={topShooter}
          isLoading={loading}
          error={error}
          headerTitle={"Top Shooter"}
        />
        <GameFeed
          data={topSimulator}
          isLoading={loading}
          error={error}
          headerTitle={"Top Simulator"}
        />
        <GameFeed
          data={topStrategy}
          loading={loading}
          error={error}
          headerTitle={"Top Sport"}
        />
        <GameFeed
          data={topSport}
          isLoading={loading}
          error={error}
          headerTitle={"Top Strategy"}
        />

        <GameFeed
          data={topTBS}
          isLoading={loading}
          error={error}
          headerTitle={"Top TBS"}
          isBottomFeed={isBottomFeedActive}
        />
        <View style={{ marginBottom: 120 }} />
      </ScrollView>
    </Fragment>
  );
}
const styles = StyleSheet.create({
  bottomWrap: {
    marginBottom: 180,
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 10,
    borderRadius: 30,
    padding: 10,
  },
});
