import { StyleSheet } from "react-native";

export default StyleSheet.create({
  button: {
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",

    borderRadius: 30,
    paddingHorizontal: 12,
    paddingVertical: 1,
    marginRight: 2,
  },
  wrap: {
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 10,
    borderRadius: 30,
    padding: 10,
  },
  loadingWrap: {
    flexDirection: "row",
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 20,
    borderRadius: 30,
    padding: 20,
  },
  title: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 16,
    color: "#FFF",
    padding: 6,
  },
  header: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 16,
    //color: "#000",
    paddingVertical: 4,
  },
  titleWrap: {
    alignItems: "center",
    alignContent: "center",
  },
  headerWrap: {
    flexDirection: "row",
    marginTop: 12,
    marginBottom: 8,
    marginLeft: 8,
  },
  filterButtonWrap: {
    flexDirection: "row",
    backgroundColor: "#dadada",
    borderRadius: 30,
    shadowColor: "#dadada",
    shadowOffset: {
      width: 1,
      height: 2,
    },
    shadowOpacity: 0.98,
    shadowRadius: 2.0,
    elevation: 3,
  },
});
