import React, { Fragment } from "react";
import { ScrollView, Text, View } from "react-native";
import TopHeader from "../navigation/TopHeader";
import MusicFeed from "../components/feeds/music/MusicFeed";
import TvFeedNoWrap from "../components/feeds/television/TvFeedNoWrap";
import LocalFeed from "../components/feeds/local/LocalFeed";
import BannerFeed from "../components/feeds/banner/BannerFeed";
import TopSocialContentFeed from "../components/feeds/trending/TopSocialContent";
import ProductFeed from "../components/feeds/products/ProductFeed";
import AppFeed from "../components/feeds/apps/AppFeed";
import BookFeed from "../components/feeds/books/BookFeed";
import FilmFeed from "../components/feeds/film/FilmFeed";
import PodcastFeed from "../components/feeds/podcast/PodcastFeed";
import MensFashionFeed from "../components/feeds/fashion/MensFashionFeed";
import TvFeed from "../components/feeds/television/TvFeed";
import GameFeed from "../components/feeds/gaming/GameFeed";
import NoWrapFilmFeed from "../components/feeds/film/NoWrapFilmFeed";
import TopGamingVideosFeed from "../components/feeds/gaming/TopGamingVideosFeed";
import FilterFeed from "../components/feeds/filter/filter.feed";

import { useTrendingScreen } from "./trending/useTrending";
import styles from "./trending/trending.screen.styles";
import BreakingFilterFeed from "../components/feeds/filter/breaking/breaking.feed";
import AppFilterFeed from "../components/feeds/filter/apps/apps.filter.feed";
import ProductFilterFeed from "../components/feeds/filter/products/product.filter.feed";
import BookFilterFeed from "../components/feeds/filter/books/books.filter.feed";
import FilmFilterFeed from "../components/feeds/filter/film/film.filter.feed";
import FashionFilterFeed from "../components/feeds/filter/fashion/fashion.filter.feed";
import GamingFilterFeed from "../components/feeds/filter/gaming/gaming.filter.feed";
import TelevisionFilterFeed from "../components/feeds/filter/television/television.filter.feed";
import MusicFilterFeed from "../components/feeds/filter/music/music.filter.feed";
import LocalFilterFeed from "../components/feeds/filter/local/local.filter.feed";
import PodcastFilterFeed from "../components/feeds/filter/podcasts/podcasts.filter.feed";
import YouTubeFilterFeed from "../components/feeds/filter/youtube/youtube.filter.feed";
import GoogleFeed from "../components/feeds/filter/google/google.filter.feed";

export default function TrendingScreen() {
  const {
    //location
    location,

    //error
    error,
    eventError,
    localError,

    //loading
    loading,
    localLoading,
    eventLoading,

    //modal
    modalVisible,
    setModalVisible,
    bookModalVisible,
    setBookModalVisible,
    televisionModalVisible,
    setTelevisionModalVisible,
    productModalVisible,
    setProductModalVisible,

    //data recieved
    data,
    topEventsData,
    topLocalData,

    //feed data
    topRestaurants,
    topEvents,
    topProducts,
    mostPopular,
    topSongs,
    topTwitterHashtag,
    topYouTube,
    topYouTapiaCards,
    topTikTok,
    top100Books,
    breakingContent,
    topSocialContent,
    topAmazon,
    topApps,
    topFilm,
    topPodcastEpisodes,
    topFashion,
    topGaming,
    newToNetflix,
    onTvNow,
    nowPlaying,
    topFreeApps,
    topPaidApps,
    bookComingSoon,
    bookNewReleases,
    topBoxOffice,
    upComingSoon,
    mensFashion,
    womensFashion,
    recentlyReleasedGaming,
    mobileGaming,
    topNetflix,
    topHulu,
    topAlbums,
    musicVideos,
    topReservations,
    topPodcasts,
    newMusic,
    topSteam,
    youTubeNews,
    youTubeEntertainment,
    youTubePeople,
    topReddit,
    topWiki,
    topGoogle,

    //social filter feed state
    showSocial,
    setShowSocial,
    showTwitter,
    setShowTwitter,
    twitterLoading,
    setTwitterLoading,
    twitterData,
    setTwitterData,
    showReddit,
    setShowReddit,
    showYouTube,
    setShowYoutube,
    showBreaking,
    showMostViewed,
    showAddedToday,
    mostViewedData,
    recentlyAddedData,
    showTopFilm,
    showUpComingSoon,
    showBoxOffice,
    showTopGaming,
    showMobileGaming,
    showRecentlyReleasedGaming,
    showTopTelevision,
    showOnTvNow,
    showNetflix,
    showHulu,
    showTopApps,
    showFreeApps,
    showPaidApps,
    showTopAmazon,
    showTopProducts,
    showTopBooks,
    showBookComingSoon,
    showBookNewReleases,
    showTopFashion,
    showMensFashion,
    showWomensFashion,
    showTopSongs,
    showTopAlbums,
    showMusicVideos,
    showTopRestaurants,
    showReservations,
    showEvents,
    showTopPodcasts,
    showTopPodcastsEpisodes,
    showNewMusic,
    showSteam,
    showYouTubeNews,
    showYouTubeEntertainment,
    showYouTubePeople,
    showYouTubeTop,
    showWiki,
    showGoogle,

    //onPress
    onShowBreaking,
    onShowMostViewed,
    onShowAddedToday,
    onShowSocial,
    fetchTwitter,
    onShowYouTube,
    onShowFreeApps,
    onShowTopApps,
    onShowPaidApps,
    onShowTopProducts,
    onShowTopAmazon,
    onShowTopBooks,
    onShowBookComingSoon,
    onShowBookNewReleases,
    onShowTopFilms,
    onShowBoxOffice,
    onShowUpComingSoon,
    onShowTopFashion,
    onShowMensFashion,
    onShowWomensFashion,
    onShowTopGaming,
    onShowMobileGaming,
    onShowRecentlyReleasedGaming,
    onShowTopTelevision,
    onShowOnTvNow,
    onShowHulu,
    onShowNetflix,
    onShowTopSongs,
    onShowTopAlbums,
    onShowMusicVideos,
    onShowTopRestaurants,
    onShowReservations,
    onShowEvents,
    onShowTopPodcasts,
    onShowTopPodcastEpisodes,
    onShowNewMusic,
    onShowSteam,
    onShowYouTubeNews,
    onShowYouTubeEntertainment,
    onShowYouTubePeople,
    onShowYouTubeTop,
    onShowReddit,
    onShowWiki,
    onShowGoogle,
    onShowWikipedia,

    //refresh data
    refreshData,
  } = useTrendingScreen();

  //Loading & Error Logic
  if (error) {
    return (
      <Fragment>
        <TopHeader onLogoPress={refreshData} onRefreshPress={refreshData} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          key={"App Scrollview"}
          style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 60 }}
          contentContainerStyle={{ alignItems: "center" }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 180,
            }}
          >
            <Text>
              Unable to fetch feeds. Please hit app tab icon to refresh
            </Text>
          </View>
        </ScrollView>
      </Fragment>
    );
  }

  return (
    <>
      <TopHeader onLogoPress={refreshData} onRefreshPress={refreshData} />
      <ScrollView
        style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 60 }}
        contentContainerStyle={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FilterFeed
          data={topSocialContent}
          loading={loading}
          error={error}
          showSocial={showSocial}
          onSocialPress={onShowSocial}
          showTwitter={showTwitter}
          onTwitterPress={fetchTwitter}
          twitterData={topTwitterHashtag}
          showReddit={showReddit}
          showWiki={showWiki}
          onWikiPress={onShowWiki}
          wikidata={topWiki}
          redditData={topReddit}
          showReddit={showReddit}
          onRedditPress={onShowReddit}
        />
        <BreakingFilterFeed
          breakingData={breakingContent}
          loading={loading}
          showBreaking={showBreaking}
          onBreakingPress={onShowBreaking}
          mostViewedData={topYouTapiaCards}
          showMostViewed={showMostViewed}
          onMostViewedPress={onShowMostViewed}
          addedTodayData={recentlyAddedData}
          showAddedToday={showAddedToday}
          onAddedTodayPress={onShowAddedToday}
          error={error}
        />
        <GoogleFeed
          error={error}
          loading={loading}
          googleData={topGoogle}
          showGoogle={showGoogle}
          onGooglePress={onShowGoogle}
          wikipediaData={topWiki}
          showWikipedia={showWiki}
          onWikipediaPress={onShowWikipedia}
        />
        <YouTubeFilterFeed
          error={error}
          loading={loading}
          youTubeTopData={topYouTube}
          showYouTubeTop={showYouTubeTop}
          onYouTubeTopPress={onShowYouTubeTop}
          youTubeNewsData={youTubeNews}
          onYouTubeNewsPress={onShowYouTubeNews}
          showYouTubeNews={showYouTubeNews}
          youTubeEntertainmentData={youTubeEntertainment}
          onYouTubeEntPress={onShowYouTubeEntertainment}
          showYouTubeEnt={showYouTubeEntertainment}
          youTubePeopleData={youTubePeople}
          OnYouTubePeoplePress={onShowYouTubePeople}
          showYouTubePeople={showYouTubePeople}
        />
        <MusicFilterFeed
          error={error}
          loading={loading}
          newMusicData={newMusic}
          topMusicData={topSongs}
          topAlbumsData={topAlbums}
          musicVideosData={musicVideos}
          showNewMusic={showNewMusic}
          showTopMusic={showTopSongs}
          showTopAlbums={showTopAlbums}
          showMusicVideos={showMusicVideos}
          onNewMusicPress={onShowNewMusic}
          onTopMusicPress={onShowTopSongs}
          onTopAlbumsPress={onShowTopAlbums}
          onMusicVideosPress={onShowMusicVideos}
        />
        <LocalFilterFeed
          error={error}
          loading={loading}
          topRestaurantsData={topRestaurants}
          topReservationsData={topReservations}
          eventsData={topEvents}
          onTopRestaurantsPress={onShowTopRestaurants}
          onTopEventsPress={onShowEvents}
          onTopReservationsPress={onShowReservations}
          showTopRestaurants={showTopRestaurants}
          showTopEvents={showEvents}
          showTopReservations={showReservations}
        />
        <ProductFilterFeed
          error={error}
          loading={loading}
          showAmazonTrending={showTopAmazon}
          amazonTrendingData={topAmazon}
          onAmazonTrendingPress={onShowTopAmazon}
          topProductsData={topProducts}
          showTopProducts={showTopProducts}
          onTopProductsPress={onShowTopProducts}
        />
        <TelevisionFilterFeed
          error={error}
          loading={loading}
          topTelevisionData={mostPopular}
          onTvTodayData={onTvNow}
          topHuluData={topHulu}
          topNetflixData={topNetflix}
          onTopTelevisionPress={onShowTopTelevision}
          onTvTodayPress={onShowOnTvNow}
          onHuluPress={onShowHulu}
          onNetflixPress={onShowNetflix}
          showTopTelevision={showTopTelevision}
          showOnTvToday={showOnTvNow}
          showHulu={showHulu}
          showNetflix={showNetflix}
        />
        <PodcastFilterFeed
          error={error}
          loading={loading}
          topPodcastsData={topPodcasts}
          podcastEpisodesData={topPodcastEpisodes}
          onTopPodcastsPress={onShowTopPodcasts}
          onPodcastEpisodesPress={onShowTopPodcastEpisodes}
          showTopPodcasts={showTopPodcasts}
          showPodcastEpisodes={showTopPodcastsEpisodes}
        />
        {/* <BannerFeed
          data={breakingContent}
          loading={loading}
          isError={error}
          headerTitle={"Breaking"}
        /> */}
        {/* <ProductFeed
          data={topAmazon}
          loading={loading}
          error={error}
          headerTitle={"Amazon Best Sellers"}
        /> */}
        <AppFilterFeed
          error={error}
          loading={loading}
          topAppsData={topApps}
          showTopApps={showTopApps}
          onTopAppsPress={onShowTopApps}
          freeAppsData={topFreeApps}
          showFreeApps={showFreeApps}
          onFreeAppsPress={onShowFreeApps}
          paidAppsData={topPaidApps}
          showPaidApps={showPaidApps}
          onPaidAppsPress={onShowPaidApps}
        />
        <BookFilterFeed
          error={error}
          loading={loading}
          topBooksData={top100Books}
          showTopBooks={showTopBooks}
          onTopBooksPress={onShowTopBooks}
          newReleasesBookData={bookNewReleases}
          showNewReleases={showBookNewReleases}
          onNewReleasesPress={onShowBookNewReleases}
          comingSoonBookData={bookComingSoon}
          showComingSoon={showBookComingSoon}
          onComingSoonPress={onShowBookComingSoon}
        />
        <FashionFilterFeed
          error={error}
          loading={loading}
          topFashionData={topFashion}
          mensFashionData={mensFashion}
          womensFashionData={womensFashion}
          onTopFashionPress={onShowTopFashion}
          onMensFashionPress={onShowMensFashion}
          onWomensFashionPress={onShowWomensFashion}
          showTopFashion={showTopFashion}
          showMensFashion={showMensFashion}
          showWomensFashion={showWomensFashion}
        />
        <FilmFilterFeed
          error={error}
          loading={loading}
          topFilmsData={topFilm}
          topBoxOfficeData={nowPlaying}
          upComingSoonData={upComingSoon}
          onTopFilmsPress={onShowTopFilms}
          onBoxOfficePress={onShowBoxOffice}
          onComingSoonPress={onShowUpComingSoon}
          showTopFilms={showTopFilm}
          showBoxOffice={showBoxOffice}
          showComingSoon={showUpComingSoon}
        />
        <GamingFilterFeed
          error={error}
          loading={loading}
          topGamingData={topGaming}
          topSteamData={topSteam}
          topMobileGamingData={mobileGaming}
          recentlyReleasedData={recentlyReleasedGaming}
          onTopGamingPress={onShowTopGaming}
          onSteamPress={onShowSteam}
          onMobileGamingPress={onShowMobileGaming}
          onRecentlyReleasedPress={onShowRecentlyReleasedGaming}
          showTopGaming={showTopGaming}
          showTopSteam={showSteam}
          showMobileGaming={showMobileGaming}
          showRecentlyReleasedGaming={showRecentlyReleasedGaming}
        />

        {/* <TopGamingVideosFeed
          data={topYouTube}
          isLoading={loading}
          isError={error}
          headerTitle={"Top YouTube"}
        /> */}
        {/* <AppFeed
          data={topApps}
          isLoading={loading}
          isError={error}
          headerTitle={"Apps"}
        /> */}
        {/* <BookFeed
          data={top100Books}
          loading={loading}
          error={error}
          headerTitle={"Books"}
        /> */}
        {/* <View style={styles.quickLinksWrap}>
          <View style={styles.headerWrap}>
            <Text style={styles.header}>Popular</Text>
          </View>
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{}}
          >
            <View
              style={{
                marginRight: 2,
                padding: 6,
              }}
            >
              <Modal
                animationType="slide"
                transparent={true}
                visible={productModalVisible}
                onRequestClose={() => {
                  setProductModalVisible(!productModalVisible);
                }}
              >
                <View style={styles.centeredView}>
                  <View style={styles.modalView}>
                    <View style={styles.youtubeWrap}>
                      <TvFeedNoWrap
                        data={newToNetflix}
                        loading={loading}
                        headerTitle={"New To Netflix"}
                      />
                    </View>
                    <Pressable
                      style={[styles.productButton, styles.buttonClose]}
                      onPress={() =>
                        setProductModalVisible(!productModalVisible)
                      }
                    >
                      <Text style={styles.textStyle}>Hide Modal</Text>
                    </Pressable>
                  </View>
                </View>
              </Modal>
              <View>
                <TouchableOpacity
                  style={[styles.productButton, styles.buttonOpen]}
                  onPress={() => setProductModalVisible(true)}
                >
                  <Text style={styles.textStyle}>New To Netflix</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View
              style={{
                marginRight: 2,
                padding: 6,
              }}
            >
              <Modal
                animationType="slide"
                transparent={true}
                visible={televisionModalVisible}
                onRequestClose={() => {
                  setTelevisionModalVisible(!televisionModalVisible);
                }}
              >
                <View style={styles.centeredView}>
                  <View style={styles.modalView}>
                    <View style={styles.youtubeWrap}>
                      <TvFeedNoWrap
                        data={onTvNow}
                        loading={loading}
                        headerTitle={"On Tv Today"}
                      />
                    </View>
                    <Pressable
                      style={[styles.button, styles.buttonClose]}
                      onPress={() =>
                        setTelevisionModalVisible(!televisionModalVisible)
                      }
                    >
                      <Text style={styles.textStyle}>Hide Modal</Text>
                    </Pressable>
                  </View>
                </View>
              </Modal>
              <View>
                <TouchableOpacity
                  style={[styles.televisonButton, styles.buttonOpen]}
                  onPress={() => setTelevisionModalVisible(true)}
                >
                  <Text style={styles.textStyle}>On TV Today</Text>
                </TouchableOpacity>
              </View>
            </View>

            <View
              style={{
                marginRight: 2,
                padding: 6,
              }}
            >
              <Modal
                animationType="slide"
                transparent={true}
                visible={bookModalVisible}
                onRequestClose={() => {
                  setBookModalVisible(!bookModalVisible);
                }}
              >
                <View style={styles.centeredView}>
                  <View style={styles.modalView}>
                    <View style={styles.youtubeWrap}>
                      <NoWrapFilmFeed
                        data={nowPlaying}
                        loading={loading}
                        headerTitle={"In Theatres"}
                      />
                    </View>
                    <Pressable
                      style={[styles.button, styles.buttonClose]}
                      onPress={() => setBookModalVisible(!bookModalVisible)}
                    >
                      <Text style={styles.textStyle}>Hide Modal</Text>
                    </Pressable>
                  </View>
                </View>
              </Modal>
              <View>
                <TouchableOpacity
                  style={[styles.button, styles.buttonOpen]}
                  onPress={() => setBookModalVisible(true)}
                >
                  <Text style={styles.textStyle}>In Theatres</Text>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
        </View> */}
        {/* <MensFashionFeed
          data={topFashion}
          loading={loading}
          error={error}
          headerTitle={"Fashion"}
        /> */}
        {/* <FilmFeed
          data={topFilm}
          isError={error}
          isLoading={loading}
          headerTitle="Film"
        /> */}
        {/* <GameFeed
          data={topGaming}
          isLoading={loading}
          error={error}
          headerTitle={"Gaming"}
        /> */}

        {/* <LocalFeed
          data={topRestaurants}
          isLoading={loading}
          isError={error}
          headerTitle={"Local"}
        /> */}
        {/* <MusicFeed
          data={topSongs}
          isError={error}
          isLoading={loading}
          headerTitle="Music"
        /> */}
        {/* <PodcastFeed
          data={topPodcastEpisodes}
          isError={error}
          isLoading={loading}
          headerTitle="Podcasts"
        /> */}
        {/* <TvFeed
          data={mostPopular}
          loading={loading}
          isError={error}
          headerTitle={"Television"}
        /> */}
        <View style={{ marginBottom: 120 }} />
      </ScrollView>
    </>
  );
}
