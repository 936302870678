import React, { useCallback, Fragment } from "react";
import {
  FlatList,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Linking,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import LoadingBar from "../../cards/loading/LoadingBar";
import { MemoizedTelevisionCard } from "../../cards/television/TelevisionCard";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function TvFeed({ data, loading, headerTitle, isError }) {
  const navigation = useNavigation();

  const renderItem = ({ item }) => <MemoizedTelevisionCard tv={item} />;

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const ITEM_HEIGHT = 350;

  const getItemLayout = useCallback(
    (data, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    []
  );

  if (loading === true) {
    return (
      <Fragment>
        <View style={styles.loadingWrap}>
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
          <Skeleton
            height={300}
            width={200}
            baseColor="#000"
            style={{ margin: 2 }}
          />
        </View>
      </Fragment>
    );
  }
  if (isError === true) {
    return null;
  }
  return (
    <View style={styles.wrap}>
      <View style={styles.headerWrap}>
        <Text style={styles.header}>{headerTitle}</Text>
      </View>
      <FlatList
        disableVirtualization={true}
        horizontal
        showsHorizontalScrollIndicator={false}
        decelerationRate={0}
        bounces={false}
        renderToHardwareTextureAndroid
        data={data}
        indicatorStyle
        getItemLayout={getItemLayout}
        //initialNumToRender={15}
        //maxToRenderPerBatch={10}
        removeClippedSubviews={true}
        scrollEventThrottle={16}
        //windowSize={50}
        lazy={false}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  wrap: {
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 10,
    borderRadius: 30,
    padding: 10,
  },
  loadingWrap: {
    flexDirection: "row",
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 12,
    borderRadius: 30,
    padding: 20,
  },
  title: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 18,
    color: "#FFF",
    padding: 6,
  },
  header: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 18,
    color: "#000",
    padding: 6,
  },
  titleWrap: {
    alignItems: "center",
    alignContent: "center",
  },
  headerWrap: {
    //marginTop: 20,
    paddingLeft: 20,
  },
});

export default TvFeed;
