import React from "react";
import { Image, Linking, View, Text, TouchableOpacity } from "react-native";

//Styles
import styles from "../../../styles/podcast.card.styles";
import LinkerView from "../../linking/LinkerView";

function PodcastCard({ podcast }) {
  const links = podcast.links;
  return (
    <View style={styles.linearGradientContainer}>
      <TouchableOpacity
        onPress={() => {
          Linking.openURL(
            `https://www.google.com/search?q=${podcast.title}+ podcast`
          );
        }}
      >
        <View style={styles.bottomContainer}>
          <Image
            style={styles.image}
            source={{
              uri: podcast.imageUrl
                ? podcast.imageUrl
                : "https://storage1.snappages.site/S79839/assets/images/9098070_1000x668_500.jpg",
            }}
          />
          <View style={{ flexDirection: "column" }}>
            <View style={styles.titleWrap}>
              <Text style={styles.title} numberOfLines={4}>
                {podcast.title}
              </Text>
              <Text style={styles.type} numberOfLines={1}>
                podcast
              </Text>
            </View>
            <View style={{ paddingTop: 20 }}>
              <Text style={styles.linksText} numberOfLines={1}>
                More Links:
              </Text>
              <LinkerView links={links} />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

export const MemoizedPodcastCard = React.memo(PodcastCard);
