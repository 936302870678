import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Linking,
  Image,
  Platform,
  ImageBackground,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import Linker from "../../linking/linker";
import { AiFillStar } from "react-icons/ai";
import { FaStar } from "react-icons/fa";
import {
  faStar,
  faMapMarker,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

function LocalCard({ local }) {
  const navigation = useNavigation();

  const image = { uri: local.imageUrl };

  const openDialScreen = (number) => {
    let phone = "";
    if (Platform.OS === "ios") {
      phone = `telprompt:${number}`;
    } else {
      phone = `tel:${number}`;
    }
    Linking.openURL(phone);
  };
  return (
    <TouchableOpacity
      onPress={() => {
        Linking.openURL(`${local.links[0].link}`);
      }}
      style={styles.descriptionContainer}
    >
      <ImageBackground source={image} resizeMode="cover" style={styles.image}>
        <View style={styles.bottomContainer}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#00000080",
              height: 30,
              width: "26%",
              borderRadius: 10,
              marginTop: 8,
            }}
          >
            <Text style={styles.ratings} numberOfLines={2}>
              {local.rating}
            </Text>
            <View style={{}}>
              {/* <AiFillStar color="yellow"/> */}
              <FontAwesomeIcon icon={faStar} color="yellow" size={15} />
            </View>
          </View>
          {/* <FastImage
          style={styles.image}
          source={{
            uri: event.imageUrl,
            priority: FastImage.priority.high,
          }}
        /> */}
          <View style={styles.titleWrap}>
            <Text style={styles.title} numberOfLines={1}>
              {local.title}
            </Text>
            <View
              style={{ flexDirection: "row", paddingLeft: 8, marginTop: 4 }}
            >
              <FontAwesomeIcon icon={faMapMarker} color="yellow" size={15} />
              <Text style={styles.type} numberOfLines={2}>
                {local.address}
              </Text>
            </View>
          </View>
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ flexGrow: 1 }}
          >
            <TouchableOpacity onPress={() => openDialScreen(local.phoneNumber)}>
              <FontAwesomeIcon icon={faMobile} color="white" size={46} />
            </TouchableOpacity>
            {local.links.map((link, index) => {
              return (
                <TouchableOpacity
                  key={index.toString()}
                  onPress={() => {
                    Linking.openURL(`${link.link}`);
                  }}
                  style={{ padding: 2 }}
                >
                  <Linker link={link.type} />
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </View>
      </ImageBackground>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  title: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 18,
    color: "#FFF",
    paddingTop: 18,
    paddingLeft: 14,
    paddingRight: 14,
    width: 240,
    height: 50,
  },
  header: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 16,
    color: "#dadada",
    padding: 6,
  },
  ratings: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 12,
    color: "yellow",
    paddingLeft: 8,
    paddingRight: 8,
  },
  titleWrap: {
    marginTop: 25,
    height: 115,
    //backgroundColor: 'red',
  },
  headerWrap: {
    marginTop: 20,
    paddingLeft: 20,
  },
  type: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 12,
    color: "#DADADA",
    paddingLeft: 14,
    width: 200,
  },
  date: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 12,
    color: "grey",
    paddingLeft: 14,
    paddingTop: 15,
    width: 200,
  },
  image: {
    height: 250,
    width: 250,
    flex: 1,
  },
  descriptionContainer: {
    alignItems: "center",
    alignContent: "center",
    margin: 6,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.58,
    shadowRadius: 2.0,
    elevation: 6,
  },
  bottomContainer: {
    flexDirection: "column",
    paddingHorizontal: 10,
    backgroundColor: "#00000080",
    height: 250,
    width: 250,
    // alignItems: 'center',
    // alignContent: 'center',
  },
});

export const MemoizedLocalCard = React.memo(LocalCard);
