import React, { useCallback } from "react";
import { FlatList, View, Text, TouchableOpacity } from "react-native";
import "react-loading-skeleton/dist/skeleton.css";

//styles
import styles from "../../../../styles/filter.feed.style";
import BreakingFilterLoading from "../breaking/breaking.feed.loading";
import { MemoizedLocalCard } from "../../../cards/local/LocalCard";
import { MemoizedEventCard } from "../../../cards/local/EventCard";

function LocalFilterFeed({
  //data
  topRestaurantsData,
  eventsData,
  topReservationsData,

  //error
  error,

  //onPress
  onTopRestaurantsPress,
  onTopEventsPress,
  onTopReservationsPress,

  //loading
  loading,

  //show
  showTopRestaurants,
  showTopEvents,
  showTopReservations,
}) {
  const renderItem = ({ item }) => <MemoizedLocalCard local={item} />;
  const renderEventItem = ({ item }) => <MemoizedEventCard event={item} />;

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const ITEM_HEIGHT = 350;

  const getItemLayout = useCallback(
    (data, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    []
  );

  if (loading) {
    return <BreakingFilterLoading />;
  }

  if (error === true) {
    return null;
  }
  return (
    <View style={styles.wrap}>
      <View>
        <Text style={styles.headerText}>LOCAL</Text>
      </View>
      <View style={styles.headerWrap}>
        <View style={styles.filterButtonWrap}>
          <TouchableOpacity
            onPress={onTopEventsPress}
            style={[
              styles.button,
              {
                backgroundImage: showTopEvents
                  ? "linear-gradient(to top right, rgba(0,0,0) 60%,rgb(247, 220, 111  ))"
                  : null,
                shadowOffset: showTopEvents
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showTopEvents ? 0.98 : 0,
                shadowRadius: showTopEvents ? 3.0 : 0,
                elevation: showTopEvents ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showTopEvents ? "#fff" : "#fff" },
              ]}
            >
              Events
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onTopRestaurantsPress}
            style={[
              styles.button,
              {
                backgroundImage: showTopRestaurants
                  ? "linear-gradient(to top right, rgba(0,0,0) 60%,rgb(247, 220, 111  ))"
                  : null,
                shadowOffset: showTopRestaurants
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showTopRestaurants ? 0.98 : 0,
                shadowRadius: showTopRestaurants ? 3.0 : 0,
                elevation: showTopRestaurants ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showTopRestaurants ? "#fff" : "#fff" },
              ]}
            >
              Restaurants
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={onTopReservationsPress}
            style={[
              styles.button,
              {
                backgroundImage: showTopReservations
                  ? "linear-gradient(to top right, rgba(0,0,0) 44%, rgba(27, 79, 114))"
                  : null,
                shadowOffset: showTopReservations
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showTopReservations ? 0.98 : 0,
                shadowRadius: showTopReservations ? 3.0 : 0,
                elevation: showTopReservations ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showTopReservations ? "#fff" : "#fff" },
              ]}
            >
              Reservations
            </Text>
          </TouchableOpacity>
          {/* <TouchableOpacity
            onPress={onHuluPress}
            style={[
              styles.button,
              {
                backgroundImage: showHulu
                  ? "linear-gradient(to top right, rgba(0,0,0) 44%, rgba(27, 79, 114))"
                  : null,
                shadowOffset: showHulu
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showHulu ? 0.98 : 0,
                shadowRadius: showHulu ? 3.0 : 0,
                elevation: showHulu ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[styles.header, { color: showHulu ? "#fff" : "#fff" }]}
            >
              Top Hulu
            </Text>
          </TouchableOpacity> */}
        </View>
      </View>
      {showTopEvents && (
        <FlatList
          showsHorizontalScrollIndicator={false}
          disableVirtualization={true}
          horizontal
          data={eventsData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          //keyExtractor={keyExtractor}
          renderItem={renderEventItem}
        />
      )}
      {showTopRestaurants && (
        <FlatList
          showsHorizontalScrollIndicator={false}
          disableVirtualization={true}
          horizontal
          data={topRestaurantsData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}

      {showTopReservations && (
        <FlatList
          showsHorizontalScrollIndicator={false}
          disableVirtualization={true}
          horizontal
          data={topReservationsData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {/* {showHulu && (
        <FlatList
          showsHorizontalScrollIndicator={false}
          disableVirtualization={true}
          horizontal
          data={topHuluData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )} */}
    </View>
  );
}

export default LocalFilterFeed;
