import React, { useCallback } from "react";
import { FlatList, View, Text, TouchableOpacity } from "react-native";
import "react-loading-skeleton/dist/skeleton.css";

//styles
import styles from "../../../../styles/filter.feed.style";
import BreakingFilterLoading from "../breaking/breaking.feed.loading";
import { MemoizedPodcastCard } from "../../../cards/podcast/PodcastCard";

function PodcastFilterFeed({
  //data
  topPodcastsData,
  podcastEpisodesData,

  //error
  error,

  //onPress
  onTopPodcastsPress,
  onPodcastEpisodesPress,

  //loading
  loading,

  //show
  showTopPodcasts,
  showPodcastEpisodes,
}) {
  const renderItem = ({ item }) => <MemoizedPodcastCard podcast={item} />;

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const ITEM_HEIGHT = 350;

  const getItemLayout = useCallback(
    (data, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    []
  );

  if (loading) {
    return <BreakingFilterLoading />;
  }

  if (error === true) {
    return null;
  }
  return (
    <View style={styles.wrap}>
      <View>
        <Text style={styles.headerText}>PODCASTS</Text>
      </View>
      <View style={styles.headerWrap}>
        <View style={styles.filterButtonWrap}>
          <TouchableOpacity
            onPress={onTopPodcastsPress}
            style={[
              styles.button,
              {
                backgroundImage: showTopPodcasts
                  ? "linear-gradient(to top right, rgba(0,0,0) 60%,rgb(247, 220, 111  ))"
                  : null,
                shadowOffset: showTopPodcasts
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showTopPodcasts ? 0.98 : 0,
                shadowRadius: showTopPodcasts ? 3.0 : 0,
                elevation: showTopPodcasts ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showTopPodcasts ? "#fff" : "#fff" },
              ]}
            >
              Popular
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onPodcastEpisodesPress}
            style={[
              styles.button,
              {
                backgroundImage: showPodcastEpisodes
                  ? "linear-gradient(to top right, rgba(0,0,0), rgba(91, 44, 111))"
                  : null,
                shadowOffset: showPodcastEpisodes
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showPodcastEpisodes ? 0.98 : 0,
                shadowRadius: showPodcastEpisodes ? 3.0 : 0,
                elevation: showPodcastEpisodes ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showPodcastEpisodes ? "#fff" : "#fff" },
              ]}
            >
              Top Podcast Episodes
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      {showTopPodcasts && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          data={topPodcastsData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showPodcastEpisodes && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          data={podcastEpisodesData}
          getItemLayout={getItemLayout}
          removeClippedSubviews={true}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
    </View>
  );
}

export default PodcastFilterFeed;
