import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Linking,
  Image,
} from "react-native";
import { useNavigation } from "@react-navigation/native";

function YouTubeCard({ video }) {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      // onPress={() =>
      //   navigation.navigate('Test', {
      //     link: video.links[0].link,
      //   })
      // }
      onPress={() => {
        Linking.openURL(`${video.links[0].link}`);
      }}
    >
      <View style={styles.linearGradientContainer}>
        <View style={styles.bottomContainer}>
          <Image
            style={styles.image}
            source={{
              uri: video.imageUrl ? video.imageUrl : "",
            }}
          />
          <View style={styles.titleWrap}>
            <Text style={styles.title} numberOfLines={3}>
              {video.title}
            </Text>
            {/* <Text style={styles.type} numberOfLines={2}>{video.description}</Text> */}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  bottomContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    paddingHorizontal: 10,
    paddingVertical: 15,
    borderRadius: 20,
  },
  image: {
    borderRadius: 20,
    height: 80,
    width: 80,
  },
  linearGradientContainer: {
    alignItems: "center",
    alignContent: "center",
    backgroundImage: "linear-gradient(to right, rgba(0,0,0), rgba(255,0,0,1))",
    borderRadius: 20,
    elevation: 6,
    height: 120,
    margin: 6,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.58,
    shadowRadius: 2.0,
  },
  title: {
    color: "#FFF",
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 16,
    paddingTop: 12,
    paddingLeft: 6,
    width: 200,
    textAlign: "center",
  },
  titleWrap: {},
});

export const MemoizedYouTubeCard = React.memo(YouTubeCard);
