import { StyleSheet } from "react-native";

export default StyleSheet.create({
  bottomContainer: {
    flexDirection: "row",
    height: 170,
    padding: 10,
  },
  image: {
    flex: 1,
    borderRadius: 20,
    height: 140,
    width: 120,
    marginRight: 2,
  },
  linkContainer: {
    borderRadius: 9,
    width: 210,
    paddingLeft: 12,
  },
  linearGradientContainer: {
    flex: 1,
    alignItems: "center",
    alignContent: "center",
    paddingLeft: 10,
    paddingTop: 10,
    borderRadius: 20,
    elevation: 6,
    backgroundImage: "linear-gradient(to right, rgba(0,0,0) 30%, rgb(139,0,0))",
    height: 250,
    margin: 6,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.58,
    shadowRadius: 2.0,
  },

  title: {
    color: "#FFF",
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 16,
    padding: 8,
    width: 220,
    textAlign: "center",
  },
  type: {
    color: "#dadada",
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 12,
    width: 180,
    padding: 8,
  },
  linksText: {
    color: "#fff",
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 10,
    paddingLeft: 12,
  },
  titleWrap: {
    marginTop: 30,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});
