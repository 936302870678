import React from "react";
import { View, Text, Image, TouchableOpacity, Linking } from "react-native";

//Navigation
import { useNavigation } from "@react-navigation/native";

//Styles
import styles from "../../../styles/article.card.styles";

function BannerNewsCard({ banner }) {
  const navigation = useNavigation();
  let bannerLink = "";
  if (banner.links && banner.links.length > 0) {
    bannerLink = banner.links[0].link;
  }
  const bannerLinks = banner.links ? banner.links.length : 0;
  console.log("banner", banner);

  console.log(bannerLinks);
  return (
    <View style={styles.linearGradientContainer}>
      <TouchableOpacity
        onPress={() => {
          Linking.openURL(`https://www.google.com/search?q=${banner.title}`);
        }}
      >
        <View style={styles.bottomContainer}>
          <Image
            style={styles.image}
            source={{
              uri: banner.imageUrl
                ? banner.imageUrl
                : "https://media.istockphoto.com/id/1065782416/photo/online-news-in-mobile-phone-close-up-of-smartphone-screen-man-reading-articles-in-application.jpg?s=612x612&w=0&k=20&c=SuzjE60rEoTBe9TbpVKE2g0Q8JNn3Qf98wDgk37xZaI=",
            }}
          />
          <View style={{ flexDirection: "column" }}>
            <View style={styles.titleWrap}>
              <Text style={styles.title} numberOfLines={4}>
                {banner.title}
              </Text>
              {bannerLinks === 0 ? (
                <>
                  <View style={{}}>
                    <TouchableOpacity
                      onPress={() => {
                        Linking.openURL(
                          `https://news.google.com/search?q=${banner.title}&hl=en-US&gl=US&ceid=US%3Aen`
                        );
                      }}
                      style={{
                        backgroundColor: "#dadada",
                        width: 130,
                        height: 40,
                        borderRadius: 30,
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 30,
                      }}
                    >
                      <Text style={styles.linksText} numberOfLines={1}>
                        View Article
                      </Text>
                    </TouchableOpacity>
                  </View>
                </>
              ) : (
                <>
                  <View style={{}}>
                    <TouchableOpacity
                      onPress={() => {
                        Linking.openURL(bannerLink);
                      }}
                      style={{
                        backgroundColor: "#000",
                        width: 130,
                        height: 40,
                        borderRadius: 30,
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    >
                      <Text style={styles.linksText} numberOfLines={1}>
                        View Article
                      </Text>
                    </TouchableOpacity>
                  </View>
                </>
              )}
            </View>
          </View>
        </View>
        <View
          style={{
            borderWidth: 0.3,
            borderColor: "#000",
            marginTop: 25,
            width: "100%",
          }}
        />

        <Text style={styles.type}>{banner.newsSource}</Text>
        {/* <TimeAgo style={styles.type} time={banner.publishDate} /> */}
      </TouchableOpacity>
    </View>
  );
}

export default BannerNewsCard;
