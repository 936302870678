import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Linking,
  Image,
  ImageBackground,
} from "react-native";
//Image

import { useNavigation } from "@react-navigation/native";
import Linker from "../../linking/linker";

function BannerCard({ banner, loading, onPress }) {
  const navigation = useNavigation();

  const image = { uri: banner.imageUrl };

  return (
    <TouchableOpacity style={styles.wrap}>
      <View style={styles.bannerContainer}>
        <View style={styles.bannerTextContainer}>
          <Text style={styles.bannerText}>{banner.banner}</Text>
        </View>
      </View>
      <Image
        style={{
          width: 282,
          height: 201,
        }}
        source={{
          uri: banner.imageUrl,
        }}
        resizeMode="cover"
      />
      <View style={styles.bottomContainer}>
        <View style={styles.titleWrap}>
          <Text style={styles.title} numberOfLines={2}>
            {banner.title}
          </Text>
        </View>
        <View style={styles.titleWrap}>
          <Text style={styles.author} numberOfLines={1}>
            {banner.description}
          </Text>
        </View>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          style={styles.linkContainer}
        >
          {banner.links.map((link, index) => {
            return (
              <TouchableOpacity
                key={index.toString()}
                onPress={() => {
                  Linking.openURL(`${link.link}`);
                }}
                style={{ padding: 2 }}
              >
                <Linker link={link.type} />
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    backgroundColor: "#000",
    margin: 5,
    height: 400,
  },
  bannerText: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 16,
    color: "#fff",
    padding: 8,
    flexWrap: "wrap",
  },
  bannerTextContainer: {
    //alignItems: "flex-start",
    alignItems: "center",
    alignContent: "center",
    width: 282,
  },

  title: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 16,
    color: "#FFF",
    padding: 4,
  },
  author: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 12,
    color: "#FFF",
    padding: 4,
  },
  cardType: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 12,
    color: "yellow",
  },
  header: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 18,
    color: "#dadada",
    padding: 6,
  },
  titleWrap: {
    alignItems: "center",
    alignContent: "center",
    width: "95%",
  },
  headerWrap: {
    marginTop: 20,
    paddingLeft: 20,
  },
  type: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 14,
    color: "#DADADA",
    padding: 6,
  },
  image: {
    flex: 1,
    height: "100%",
    width: 302,
    borderRadius: 50,
  },
  descriptionContainer: {
    alignItems: "center",
    alignContent: "center",
    flex: 1,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.58,
    shadowRadius: 1.0,
    elevation: 6,
  },
  bannerContainer: {
    backgroundImage:
      "linear-gradient(to top right, rgba(0,0,0), rgba(255,0,0,1))",
    alignContent: "center",
    alignItems: "center",
    //backgroundColor: "red",
    //borderTopLeftRadius: 9,
    //borderTopRightRadius: 9,
    // height: 90,
    // flex: 1,
    //flexDirection: "column",
    padding: 4,
    width: 282,
    //height: 80,
  },
  linkContainer: {
    flex: 1,
    padding: 8,
    width: "95%",
  },
  bottomContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: 10,
    width: "100%",
    //backgroundColor: "#00000098",
    backgroundImage:
      "linear-gradient(to top right, rgba(0,0,0), rgba(0,0,0) 98%,rgba(105,105,105), rgba(255,255,255) )",
  },
});

export const MemoizedBannerCard = React.memo(BannerCard);
