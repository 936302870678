import { useCallback, useEffect, useState, useContext } from "react";
import LocationContext from "../../components/context/Location";

export const useTrendingScreen = () => {
  //location
  const location = useContext(LocationContext);

  //loading
  const [loading, setLoading] = useState(true);
  const [eventLoading, setEventLoading] = useState(true);
  const [localLoading, setLocalLoading] = useState(true);

  //error
  const [eventError, setEventError] = useState(false);
  const [localError, setLocalError] = useState(false);
  const [error, setError] = useState(false);

  //modal
  const [modalVisible, setModalVisible] = useState(false);
  const [bookModalVisible, setBookModalVisible] = useState(false);
  const [televisionModalVisible, setTelevisionModalVisible] = useState(false);
  const [productModalVisible, setProductModalVisible] = useState(false);

  //data recieved
  const [data, setData] = useState([]);

  const [newToNetflix, setNewToNetflix] = useState([]);

  //social filter feed data, state and on press
  const [topSocialContent, setTopSocialContent] = useState([]);
  const [topTikTok, setTopTikTok] = useState([]);
  const [topTwitterHashtag, setTopTwitterHashtag] = useState([]);
  const [topYouTube, setTopYoutube] = useState([]);
  const [topReddit, setTopReddit] = useState([]);

  const [topWiki, setTopWiki] = useState([]);
  const [topGoogle, setTopGoogle] = useState([]);
  const [showWiki, setShowWiki] = useState(false);
  const [showGoogle, setShowGoogle] = useState(true);

  const onShowGoogle = () => {
    setShowGoogle(true);
    setShowWiki(false);
  };
  const onShowWikipedia = () => {
    setShowGoogle(false);
    setShowWiki(true);
  };

  const [showSocial, setShowSocial] = useState(true);
  const [showTwitter, setShowTwitter] = useState(false);
  const [twitterLoading, setTwitterLoading] = useState(false);
  const [twitterData, setTwitterData] = useState(false);
  const [showReddit, setShowReddit] = useState(false);

  const [showYouTube, setShowYoutube] = useState(false);

  const onShowSocial = () => {
    setShowSocial(true);
    setShowTwitter(false);
    setShowReddit(false);
  };

  const fetchTwitter = () => {
    setShowSocial(false);
    setShowTwitter(true);
    setShowReddit(false);
  };
  const onShowYouTube = () => {
    setShowSocial(false);
    setShowTwitter(false);
    setShowReddit(false);
  };
  const onShowReddit = () => {
    setShowSocial(false);
    setShowTwitter(false);
    setShowReddit(true);
  };
  const onShowWiki = () => {
    setShowSocial(false);
    setShowTwitter(false);
    setShowReddit(false);
  };

  //banner filter feed state and on press
  const [breakingContent, setBreakingContent] = useState([]);
  const [mostViewedData, setMostViewedData] = useState([]);
  const [recentlyAddedData, setRecentlyAddedData] = useState([]);
  const [topYouTapiaCards, setTopYouTapiaCards] = useState([]);

  //state
  const [showBreaking, setShowBreaking] = useState(true);
  const [showMostViewed, setShowMostViewed] = useState(false);
  const [showAddedToday, setShowAddedToday] = useState(false);

  const onShowBreaking = () => {
    setShowBreaking(true);
    setShowMostViewed(false);
    setShowAddedToday(false);
  };

  const onShowMostViewed = () => {
    setShowBreaking(false);
    setShowMostViewed(true);
    setShowAddedToday(false);
  };

  const onShowAddedToday = () => {
    setShowBreaking(false);
    setShowMostViewed(false);
    setShowAddedToday(true);
  };

  //apps filter feed data
  const [topApps, setTopApps] = useState([]);
  const [topFreeApps, setTopFreeApps] = useState([]);
  const [topPaidApps, setTopPaidApps] = useState([]);

  //state
  const [showTopApps, setShowTopApps] = useState(true);
  const [showFreeApps, setShowFreeApps] = useState(false);
  const [showPaidApps, setShowPaidApps] = useState(false);

  //onpress
  const onShowTopApps = () => {
    setShowTopApps(true);
    setShowFreeApps(false);
    setShowPaidApps(false);
  };

  const onShowFreeApps = () => {
    setShowTopApps(false);
    setShowFreeApps(true);
    setShowPaidApps(false);
  };

  const onShowPaidApps = () => {
    setShowTopApps(false);
    setShowFreeApps(false);
    setShowPaidApps(true);
  };

  //top products filter feed data
  const [topAmazon, setTopAmazon] = useState([]);
  const [topProducts, setTopProducts] = useState([]);

  //state
  const [showTopAmazon, setShowTopAmazon] = useState(true);
  const [showTopProducts, setShowTopProducts] = useState(false);

  //onpress
  const onShowTopAmazon = () => {
    setShowTopAmazon(true);
    setShowTopProducts(false);
  };

  const onShowTopProducts = () => {
    setShowTopAmazon(false);
    setShowTopProducts(true);
  };

  //top books filter feed data
  const [top100Books, setTop100Books] = useState([]);
  const [bookNewReleases, setBookNewReleases] = useState([]);
  const [bookComingSoon, setBookComingSoon] = useState([]);

  //state
  const [showTopBooks, setShowTopBooks] = useState(true);
  const [showBookNewReleases, setShowBookNewReleases] = useState(false);
  const [showBookComingSoon, setShowBookComingSoon] = useState(false);

  //onpress
  const onShowTopBooks = () => {
    setShowTopBooks(true);
    setShowBookNewReleases(false);
    setShowBookComingSoon(false);
  };

  const onShowBookNewReleases = () => {
    setShowTopBooks(false);
    setShowBookNewReleases(true);
    setShowBookComingSoon(false);
  };

  const onShowBookComingSoon = () => {
    setShowTopBooks(false);
    setShowBookNewReleases(false);
    setShowBookComingSoon(true);
  };

  //top films filter feed data
  const [topFilm, setTopFilm] = useState([]);
  const [topBoxOffice, setTopBoxOffice] = useState([]);
  const [upComingSoon, setUpComingSoon] = useState([]);
  const [nowPlaying, setNowPlaying] = useState([]);

  //state
  const [showTopFilm, setShowTopFilm] = useState(true);
  const [showBoxOffice, setShowBoxOffice] = useState(false);
  const [showUpComingSoon, setShowUpComingSoon] = useState(false);

  //onpress
  const onShowTopFilms = () => {
    setShowTopFilm(true);
    setShowBoxOffice(false);
    setShowUpComingSoon(false);
  };

  const onShowBoxOffice = () => {
    setShowTopFilm(false);
    setShowBoxOffice(true);
    setShowUpComingSoon(false);
  };

  const onShowUpComingSoon = () => {
    setShowTopFilm(false);
    setShowBoxOffice(false);
    setShowUpComingSoon(true);
  };

  //top fashion filter feed data
  const [topFashion, setTopFashion] = useState([]);
  const [mensFashion, setMensFashion] = useState([]);
  const [womensFashion, setWomensFashion] = useState([]);

  //state
  const [showTopFashion, setShowTopFashion] = useState(true);
  const [showWomensFashion, setShowWomensFashion] = useState(false);
  const [showMensFashion, setShowMensFashion] = useState(false);

  //onpress
  const onShowTopFashion = () => {
    setShowTopFashion(true);
    setShowMensFashion(false);
    setShowWomensFashion(false);
  };

  const onShowMensFashion = () => {
    setShowTopFashion(false);
    setShowMensFashion(true);
    setShowWomensFashion(false);
  };

  const onShowWomensFashion = () => {
    setShowTopFashion(false);
    setShowMensFashion(false);
    setShowWomensFashion(true);
  };

  //top gaming filter feed data
  const [topGaming, setTopGaming] = useState([]);
  const [topSteam, setTopSteam] = useState([]);
  const [mobileGaming, setMobileGaming] = useState([]);
  const [recentlyReleasedGaming, setRecentlyReleasedGaming] = useState([]);

  //state
  const [showTopGaming, setShowTopGaming] = useState(true);
  const [showSteam, setShowSteam] = useState(false);
  const [showMobileGaming, setShowMobileGaming] = useState(false);
  const [showRecentlyReleasedGaming, setShowRecentlyReleasedGaming] =
    useState(false);

  //onpress
  const onShowTopGaming = () => {
    setShowTopGaming(true);
    setShowSteam(false);
    setShowMobileGaming(false);
    setShowRecentlyReleasedGaming(false);
  };
  const onShowSteam = () => {
    setShowTopGaming(false);
    setShowSteam(true);
    setShowMobileGaming(false);
    setShowRecentlyReleasedGaming(false);
  };
  const onShowMobileGaming = () => {
    setShowTopGaming(false);
    setShowSteam(false);
    setShowMobileGaming(true);
    setShowRecentlyReleasedGaming(false);
  };

  const onShowRecentlyReleasedGaming = () => {
    setShowTopGaming(false);
    setShowSteam(false);
    setShowMobileGaming(false);
    setShowRecentlyReleasedGaming(true);
  };

  //top tv filter feed data
  const [mostPopular, setMostPopular] = useState([]);
  const [onTvNow, setOnTvNow] = useState([]);
  const [topNetflix, setTopNetflix] = useState([]);
  const [topHulu, setTopHulu] = useState([]);
  //state
  const [showTopTelevision, setShowTopTelevision] = useState(true);
  const [showOnTvNow, setShowOnTvNow] = useState(false);
  const [showNetflix, setShowNetflix] = useState(false);
  const [showHulu, setShowHulu] = useState(false);

  //onpress
  const onShowTopTelevision = () => {
    setShowTopTelevision(true);
    setShowOnTvNow(false);
    setShowNetflix(false);
    setShowHulu(false);
  };

  const onShowOnTvNow = () => {
    setShowTopTelevision(false);
    setShowOnTvNow(true);
    setShowNetflix(false);
    setShowHulu(false);
  };

  const onShowNetflix = () => {
    setShowTopTelevision(false);
    setShowOnTvNow(false);
    setShowNetflix(true);
    setShowHulu(false);
  };

  const onShowHulu = () => {
    setShowTopTelevision(false);
    setShowOnTvNow(false);
    setShowNetflix(false);
    setShowHulu(true);
  };

  //top music filter feed data
  const [topSongs, setTopSongs] = useState([]);
  const [newMusic, setNewMusic] = useState([]);
  const [topAlbums, setTopAlbums] = useState([]);
  const [musicVideos, setTopMusicVideos] = useState([]);
  //state
  const [showTopSongs, setShowTopSongs] = useState(true);
  const [showNewMusic, setShowNewMusic] = useState(false);
  const [showTopAlbums, setShowTopAlbums] = useState(false);
  const [showMusicVideos, setShowMusicVideos] = useState(false);

  //onpress
  const onShowTopSongs = () => {
    setShowTopSongs(true);
    setShowNewMusic(false);
    setShowTopAlbums(false);
    setShowMusicVideos(false);
  };

  const onShowNewMusic = () => {
    setShowTopSongs(false);
    setShowNewMusic(true);
    setShowTopAlbums(false);
    setShowMusicVideos(false);
  };

  const onShowTopAlbums = () => {
    setShowTopSongs(false);
    setShowNewMusic(false);
    setShowTopAlbums(true);
    setShowMusicVideos(false);
  };

  const onShowMusicVideos = () => {
    setShowTopSongs(false);
    setShowNewMusic(false);
    setShowTopAlbums(false);
    setShowMusicVideos(true);
  };

  //top local filter feed data
  const [topEventsData, setTopEventsData] = useState([]);
  const [topLocalData, setTopLocalData] = useState([]);
  const [topRestaurants, setTopRestaurants] = useState([]);
  const [topReservations, setTopReservations] = useState([]);
  const [topEvents, setTopEvents] = useState([]);

  //state
  const [showTopRestaurants, setShowTopRestaurants] = useState(false);
  const [showEvents, setShowEvents] = useState(true);
  const [showReservations, setShowReservations] = useState(false);

  //onpress
  const onShowTopRestaurants = () => {
    setShowTopRestaurants(true);
    setShowEvents(false);
    setShowReservations(false);
  };

  const onShowEvents = () => {
    setShowTopRestaurants(false);
    setShowEvents(true);
    setShowReservations(false);
  };

  const onShowReservations = () => {
    setShowTopRestaurants(false);
    setShowEvents(false);
    setShowReservations(true);
  };

  //top podcasts filter feed data
  const [topPodcastEpisodes, setTopPodcastEpisodes] = useState([]);
  const [topPodcasts, setTopPodcasts] = useState([]);

  //state
  const [showTopPodcasts, setShowTopPodcasts] = useState(true);
  const [showTopPodcastsEpisodes, setShowTopPodcastsEpisodes] = useState(false);

  //onpress
  const onShowTopPodcasts = () => {
    setShowTopPodcasts(true);
    setShowTopPodcastsEpisodes(false);
  };

  const onShowTopPodcastEpisodes = () => {
    setShowTopPodcasts(false);
    setShowTopPodcastsEpisodes(true);
  };

  //youtube filter feed data
  const [youTubeNews, setYouTubeNews] = useState([]);
  const [youTubePeople, setYouTubePeople] = useState([]);
  const [youTubeComedy, setYouTubeComedy] = useState([]);
  const [youTubeEntertainment, setYouTubeEntertainment] = useState([]);
  const [youTubeTech, setYouTubeTech] = useState([]);

  //state
  const [showYouTubeTop, setShowYouTubeTop] = useState(true);
  const [showYouTubeNews, setShowYouTubeNews] = useState(false);
  const [showYouTubePeople, setShowYouTubePeople] = useState(false);
  const [showYouTubeComedy, setShowYouTubeComedy] = useState(false);
  const [showYouTubeEntertainment, setShowYouTubeEntertainment] =
    useState(false);
  const [showYouTubeTech, setShowYouTubeTech] = useState(false);

  //onpress
  const onShowYouTubeTop = () => {
    setShowYouTubeTop(true);
    setShowYouTubeNews(false);
    setShowYouTubeEntertainment(false);
    setShowYouTubePeople(false);
  };
  const onShowYouTubeNews = () => {
    setShowYouTubeNews(true);
    setShowYouTubeTop(false);
    setShowYouTubeEntertainment(false);
    setShowYouTubePeople(false);
  };
  const onShowYouTubeEntertainment = () => {
    setShowYouTubeNews(false);
    setShowYouTubeTop(false);
    setShowYouTubeEntertainment(true);
    setShowYouTubePeople(false);
  };
  const onShowYouTubePeople = () => {
    setShowYouTubeNews(false);
    setShowYouTubeTop(false);
    setShowYouTubeEntertainment(false);
    setShowYouTubePeople(true);
  };
  //Fetching Data Logic
  const fetchApps = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://youtapia-temp8.uc.r.appspot.com/v1/api/feed/99900,1211,1101,1113,1,333,205,503,99904,99903,100021,88800,105,740,88801,88802,100009,207,104,99901,601,628,312,313,103,1301,1351,410,609,242,241,3,1104,701,27,430,1111,1110,1106,1219,1222,1224?pageNumber=0&pageSize=50`,
        {
          method: "GET",
          credentials: "same-origin",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setData(data);
      console.log("data", data);
      setTopYouTapiaCards(data[0].cards);
      setTopTwitterHashtag(data[1].cards);
      setTopYoutube(data[2].cards);
      setTopTikTok(data[3].cards);
      setTopSongs(data[4].cards);
      setTop100Books(data[5].cards);
      setMostPopular(data[6].cards);
      setTopProducts(data[7].cards);
      setBreakingContent(data[8].cards);
      setTopSocialContent(data[9].cards);
      setTopAmazon(data[10].cards);
      setTopApps(data[11].cards);
      setTopFilm(data[12].cards);
      setTopPodcastEpisodes(data[13].cards);
      setTopFashion(data[14].cards);
      setTopGaming(data[15].cards);
      setNewToNetflix(data[16].cards);
      setOnTvNow(data[17].cards);
      setNowPlaying(data[18].cards);
      setRecentlyAddedData(data[19].cards);
      setTopFreeApps(data[20].cards);
      setTopPaidApps(data[21].cards);
      setBookNewReleases(data[22].cards);
      setBookComingSoon(data[23].cards);
      setUpComingSoon(data[24].cards);
      setMensFashion(data[25].cards);
      setWomensFashion(data[26].cards);
      setRecentlyReleasedGaming(data[27].cards);
      setMobileGaming(data[28].cards);
      setTopNetflix(data[29].cards);
      setTopHulu(data[30].cards);
      setTopAlbums(data[31].cards);
      setTopMusicVideos(data[32].cards);
      setTopPodcasts(data[33].cards);
      setNewMusic(data[34].cards);
      setTopSteam(data[35].cards);
      setYouTubeNews(data[36].cards);
      setYouTubeEntertainment(data[37].cards);
      setYouTubePeople(data[38].cards);
      setTopReddit(data[39].cards);
      setTopWiki(data[40].cards);
      setTopGoogle(data[41].cards);
      setLoading(false);
      setError(false);
    } catch (e) {
      setError(true);
      setLoading(false);
      //console.error(e);
    }
  };

  const fetchEvents = async () => {
    setEventLoading(true);
    try {
      const response = await fetch(
        `https://youtapia-temp8.uc.r.appspot.com/v1/api/feed/902?latitude=${location.coordinates.lat}&longitude=${location.coordinates.lng}`,
        {
          method: "GET",
          credentials: "same-origin",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const events = await response.json();
      setTopEventsData(events);
      //console.log("events", events);
      setTopEvents(events[0].cards);
      setEventLoading(false);
      setEventError(false);
    } catch (e) {
      setEventLoading(false);
      setEventError(true);
      //console.error(e);
    }
  };

  const fetchLocal = async () => {
    setLocalLoading(true);
    try {
      const response = await fetch(
        `https://youtapia-temp8.uc.r.appspot.com/v1/api/feed/801,803?latitude=${location.coordinates.lat}&longitude=${location.coordinates.lng}`,
        {
          method: "GET",
          credentials: "same-origin",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const local = await response.json();
      setTopLocalData(local);
      //console.log('local',local)
      setTopRestaurants(local[0].cards);
      setTopReservations(local[1].cards);
      setLocalLoading(false);
      setLocalError(false);
    } catch (e) {
      setLocalLoading(false);
      setLocalError(true);
      //console.error(e);
    }
  };
  const fetchFeeds = async () => {
    try {
      const response = await fetch(
        `https://youtapia-temp8.uc.r.appspot.com/v1/api/admin/feeds`,
        {
          method: "GET",
          credentials: "same-origin",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const feeds = await response.json();
      console.log(feeds);
    } catch (e) {
      console.error(e);
    }
  };

  const refreshData = () => {
    if (location) {
      fetchApps();
      fetchEvents();
      fetchLocal();
    } else {
      fetchApps();
    }
  };

  //Render Logic
  useEffect(() => {
    console.log("mounted");
    fetchApps();
  }, []);

  useEffect(() => {
    console.log("mounted Feeds");
    fetchFeeds();
  }, []);

  useEffect(() => {
    console.log("mounted again");
    if (location) {
      fetchEvents();
      fetchLocal();
    }
  }, [location]);

  return {
    //location
    location,

    //error
    error,
    eventError,
    localError,

    //loading
    loading,
    localLoading,
    eventLoading,

    //modal
    modalVisible,
    setModalVisible,
    bookModalVisible,
    setBookModalVisible,
    televisionModalVisible,
    setTelevisionModalVisible,
    productModalVisible,
    setProductModalVisible,

    //data recieved
    data,
    topEventsData,
    topLocalData,

    //feed data
    topRestaurants,
    topEvents,
    topProducts,
    mostPopular,
    topSongs,
    topTwitterHashtag,
    topYouTube,
    topYouTapiaCards,
    topTikTok,
    top100Books,
    breakingContent,
    topSocialContent,
    topAmazon,
    topApps,
    topFilm,
    topPodcastEpisodes,
    topFashion,
    topGaming,
    newToNetflix,
    onTvNow,
    nowPlaying,
    topFreeApps,
    topPaidApps,
    bookComingSoon,
    bookNewReleases,
    topBoxOffice,
    upComingSoon,
    mensFashion,
    womensFashion,
    recentlyReleasedGaming,
    mobileGaming,
    topNetflix,
    topHulu,
    topAlbums,
    musicVideos,
    topReservations,
    topPodcasts,
    newMusic,
    topSteam,
    youTubeNews,
    youTubeEntertainment,
    youTubePeople,
    topReddit,
    topWiki,
    topGoogle,

    //social filter feed state
    showSocial,
    setShowSocial,
    showTwitter,
    setShowTwitter,
    twitterLoading,
    setTwitterLoading,
    twitterData,
    setTwitterData,
    showReddit,
    setShowReddit,
    showYouTube,
    setShowYoutube,
    showBreaking,
    showMostViewed,
    showAddedToday,
    mostViewedData,
    recentlyAddedData,
    showTopFilm,
    showUpComingSoon,
    showBoxOffice,
    showTopGaming,
    showMobileGaming,
    showRecentlyReleasedGaming,
    showTopTelevision,
    showOnTvNow,
    showNetflix,
    showHulu,
    showTopApps,
    showFreeApps,
    showPaidApps,
    showTopAmazon,
    showTopProducts,
    showTopBooks,
    showBookComingSoon,
    showBookNewReleases,
    showTopFashion,
    showMensFashion,
    showWomensFashion,
    showTopSongs,
    showTopAlbums,
    showMusicVideos,
    showTopRestaurants,
    showReservations,
    showEvents,
    showTopPodcasts,
    showTopPodcastsEpisodes,
    showNewMusic,
    showSteam,
    showYouTubeNews,
    showYouTubeEntertainment,
    showYouTubePeople,
    showYouTubeTop,
    showWiki,
    showGoogle,

    //onPress
    onShowBreaking,
    onShowMostViewed,
    onShowAddedToday,
    onShowSocial,
    fetchTwitter,
    onShowYouTube,
    onShowFreeApps,
    onShowTopApps,
    onShowPaidApps,
    onShowTopProducts,
    onShowTopAmazon,
    onShowTopBooks,
    onShowBookComingSoon,
    onShowBookNewReleases,
    onShowTopFilms,
    onShowBoxOffice,
    onShowUpComingSoon,
    onShowTopFashion,
    onShowMensFashion,
    onShowWomensFashion,
    onShowTopGaming,
    onShowMobileGaming,
    onShowRecentlyReleasedGaming,
    onShowTopTelevision,
    onShowOnTvNow,
    onShowHulu,
    onShowNetflix,
    onShowTopSongs,
    onShowTopAlbums,
    onShowMusicVideos,
    onShowTopRestaurants,
    onShowReservations,
    onShowEvents,
    onShowTopPodcasts,
    onShowTopPodcastEpisodes,
    onShowNewMusic,
    onShowSteam,
    onShowYouTubeNews,
    onShowYouTubeEntertainment,
    onShowYouTubePeople,
    onShowYouTubeTop,
    onShowReddit,
    onShowWiki,
    onShowGoogle,
    onShowWikipedia,

    //refresh data
    refreshData,
  };
};
