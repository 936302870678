const assets = {
  Amazon_Link: require("./amazon.png"),
  IMDb_Link: require("./IMDb.png"),
  Rotten_Tomatoes_Link: require("./rottentomatoes.png"),
  google_play_link: require("./googleplay.png"),
  itunes_link: require("./itunesLink.png"),
  roku_link: require("./rokuIcon.png"),
  spotify_link: require("./spotifylink.png"),
  bestbuy_link: require("./bestbuylink.png"),
  walmart_link: require("./walmart.png"),
  target_link: require("./targeticon.png"),
  barnesnoble_link: require("./barnesnoble.png"),
  login_background: require("./SplashbackgroundImage.png"),
  finger_tap: require("./fingerTap.png"),
  news_icon: require("./newsIcon.png"),
  twitter_yellow_icon: require("./twitterIcon.png"),
  reddit_yellow_icon: require("./redditIcon.png"),
  wikipedia_yellow_icon: require("./wikipediaYellowIcon.png"),
  instagram_yellow_icon: require("./instagramYellowIcon.png"),
  white_headphones_icon: require("./musicIcon.png"),
  yelp_icon: require("./yelp.png"),
  ios_appstore_icon: require("./appStoreIcon.png"),
  login_background_dark: require("./BackgroundImage.png"),
  google_shopping_icon: require("./googleshopping.png"),
  google_news_icon: require("./googleNews.png"),
  asos_shopping_icon: require("./asosshopping.png"),
  hbo_icon: require("./hbomax.png"),
  wikipedia_icon: require("./wikipedia.png"),
  youtube_icon: require("./youtube.png"),
  uber_eats_icon: require("./Uber-Eats-Transparent.png"),
  door_dash_icon: require("./doordash.png"),
  netflix_icon: require("./netflix.png"),
  facebook_icon: require("./facebook.png"),
  instagram_icon: require("./instagram.png"),
  direct_tv_icon: require("./directTv.png"),
  amazon_prime_icon: require("./primeVideo.png"),
  twitter_icon: require("./twitter.png"),
  apple_tv_icon: require("./appletv.png"),
  vudu_icon: require("./vudu.png"),
  hulu_icon: require("./hulu.png"),
  redbox_icon: require("./redbox.png"),
  spectrum_icon: require("./spectrum.png"),
  fubotv_icon: require("./fubotv.png"),
  amcplus_icon: require("./amcplus.png"),
  steam_icon: require("./steam.png"),
  twitch_icon: require("./twitch.png"),
  parmaount_plus_icon: require("./paramountplus.png"),
  showtime_icon: require("./showtime.png"),
  twitter_hashtag_icon: require("./twitterhashtag.png"),
  ebay_icon: require("./ebay.png"),
  ticketmaster_icon: require("./ticketmaster.png"),
  stubhub_icon: require("./stubhub.png"),
  vivid_seats_icon: require("./vividseats.png"),
  fandango_icon: require("./fandango.png"),
  espn_icon: require("./espn.png"),
  home_icon: require("./home.png"),
};

export default assets;
