import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Linking,
  Image,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import assets from "../../../assets";

function TwitterHashtagCard({ hashtag }) {
  const navigation = useNavigation();
  return (
    <View>
      <TouchableOpacity
        onPress={() => {
          Linking.openURL(`${hashtag.links[0].link}`);
        }}>
        <View
          style={styles.descriptionContainer}>
          <Image
            style={{ height: 24, width: 24 }}
            source={assets.twitter_yellow_icon}
          />
          <Text style={styles.title} numberOfLines={2}>
            {hashtag.title}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    backgroundColor: "#fff",
  },
  title: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 16,
    color: "#FFF",
    padding: 6,
  },
  header: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 18,
    color: "#dadada",
    padding: 6,
  },
  titleWrap: {
    alignItems: "center",
    alignContent: "center",
  },
  headerWrap: {
    marginTop: 20,
    paddingLeft: 20,
  },
  type: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 14,
    color: "#DADADA",
  },
  image: {
    height: 200,
    width: 200,
    borderTopLeftRadius: 9,
    borderTopRightRadius: 9,
  },
  descriptionContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: 6,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.58,
    shadowRadius: 2.0,
    elevation: 6,
    borderRadius: 25,
    backgroundImage: "linear-gradient(to top right, rgba(0,0,0) 44%, rgba(0,204,204))",
    paddingVertical: 8,
    paddingHorizontal: 20,
    height: 120,
    borderRadius: 9,
  },
  linkContainer: {
    padding: 12,
  },
});

export const MemoizedTwitterCard = React.memo(TwitterHashtagCard);
