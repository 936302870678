import React from "react";
import { Linking, Image, View, Text, TouchableOpacity } from "react-native";

//Styles
import styles from "../../../styles/music.card.styles";
import LinkerView from "../../linking/LinkerView";

function MusicCard({ song }) {
  const webViewLink = `https://www.google.com/search?q=${song.artists[0].name}+${song.title}`;

  const links = song.links;
  return (
    <View style={styles.linearGradientContainer}>
      <TouchableOpacity
        onPress={() => {
          Linking.openURL(webViewLink);
        }}
      >
        <View style={styles.bottomContainer}>
          <Image
            style={styles.image}
            source={{
              uri: song.imageUrl
                ? song.imageUrl
                : "https://static8.depositphotos.com/1044234/879/i/450/depositphotos_8790321-stock-photo-retro-microphone-on-stage.jpg",
            }}
          />
          <View style={{ flexDirection: "column" }}>
            <View style={styles.titleWrap}>
              <Text style={styles.title} numberOfLines={4}>
                {song.title}
              </Text>
              <Text style={styles.type} numberOfLines={1}>
                {song.artists[0].name}
              </Text>
            </View>
            <View style={{ paddingTop: 20 }}>
              <Text style={styles.linksText} numberOfLines={1}>
                More Links:
              </Text>
              <LinkerView links={links} />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

export const MemoizedMusicCard = React.memo(MusicCard);
