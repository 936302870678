import React, { useRef, useState } from "react";
import { View, StyleSheet, Dimensions, Animated } from "react-native";
import Tab from "./Tab";

const { width } = Dimensions.get("screen");
const { height } = Dimensions.get("screen");

const TabBarWidth = width < 800 ? width * 0.96 : 780;

const TabBarHeight = height < 400 ? 65 : 75;

//console.log(width)
//console.log(TabBarWidth)

const TabBar = ({ state, navigation }) => {
  const [selected, setSelected] = useState("Trending");
  const { routes } = state;
  //console.log(selected)
  const renderColor = (currentTab) =>
    currentTab === selected ? "#F4F269" : "#fff";

  const animation = useRef(new Animated.Value(0)).current;

  const handlePress = (activeTab, index) => {
    if (state.index !== index) {
      setSelected(activeTab);
      navigation.navigate(activeTab);
    }
  };

  return (
    <View style={styles.wrapper}>
      <Animated.ScrollView
        showsHorizontalScrollIndicator={false}
        horizontal
        style={[styles.container, { transform: [{ translateY: animation }] }]}
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
        }}
      >
        {routes.map((route, index) => (
          <Tab
            tab={route}
            onPress={() => handlePress(route.name, index)}
            color={renderColor(route.name)}
            key={route.key}
            active={selected}
            type={route.name}
            index={index}
          />
        ))}
      </Animated.ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    position: "absolute",
    bottom: 0.1,
    alignSelf: "center",
  },
  container: {
    backgroundColor: "#000",
    width: TabBarWidth,
    height: TabBarHeight,

    padding: 14,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 10,
    borderRadius: 10,
    //adding: 40,
    opacity: "95%",
  },
});

export default TabBar;
