import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Linking,
  Image,
  ImageBackground,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import Linker from "../../linking/linker";

function WomensFashionCard({ womens, loading, onPress }) {
  const navigation = useNavigation();

  const image = { uri: womens.imageUrl };
  return (
    <View style={{ marginRight: 8 }}>
      <View>
        <ImageBackground
          style={styles.image}
          source={{
            uri: womens.imageUrl,

          }}
          resizeMode='cover'
        >
          <View
            style={styles.priceContainer}>
            <Text style={styles.priceText}>$ {womens.priceCurrent}</Text>
          </View>
        </ImageBackground>
      </View>
      <View
        style={styles.linearGradientContainer}>
        <View style={styles.titleDescriptionWrap}>
          <Text style={styles.titleText} numberOfLines={2}>
            {womens.title}
          </Text>
        </View>
        <View style={{ padding: 8, marginBottom: 2 }}>
          <Text style={styles.brandNameText} numberOfLines={1}>
            {womens.brandName}
          </Text>
        </View>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          style={styles.linkContainer}>
          {womens.links.map((link, index) => {
            return (
              <TouchableOpacity
                key={index.toString()}
                onPress={() => {
                  Linking.openURL(`${link.link}`);
                }}
                style={{ padding: 2 }}>
                <Linker link={link.type} />
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  brandNameText: {
    color: '#DADADA',
    fontFamily: 'AvenirNextCondensed-Bold',
    fontSize: 14,
  },
  cardContainer: {
    alignContent: 'center',
    alignItems: 'center',
    elevation: 6,
    margin: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: -2,
      height: 1,
    },
    shadowOpacity: 0.48,
    shadowRadius: 1.0,
  },
  image: {
    borderTopLeftRadius: 9,
    borderTopRightRadius: 9,
    height: 300,
    width: 300,
  },
  linearGradientContainer: {
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    borderBottomRightRadius: 9,
    borderBottomLeftRadius: 9,
    flex: 1,
    flexDirection: 'column',
    paddingBottom: 2,
    paddingTop: 8,
    width: 302,
  },
  linkContainer: {
    padding: 12,
  },
  priceContainer: {
    backgroundColor: '#ffff0080',
    height: 25,
    width: 54,
    paddingVertical: 4,
    paddingHorizontal: 6,
    borderRadius: 10,
    margin: 10,
  },
  priceText: {
    fontFamily: 'AvenirNextCondensed-Bold',
    fontSize: 12,
    color: '#000',
    alignSelf: 'center',
  },
  titleDescriptionWrap: {
    alignContent: 'center',
    alignItems: 'center',
  },
  titleText: {
    color: '#FFF',
    fontFamily: 'AvenirNextCondensed-Bold',
    fontSize: 16,
    padding: 6,
  },
})

export const MemoizedWomensFashionCard = React.memo(WomensFashionCard);
