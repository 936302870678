import React, { useState, useEffect, Fragment } from "react";
import { ScrollView, Text, StyleSheet, View } from "react-native";
import TopHeader from "../navigation/TopHeader";
import AppFeed from "../components/feeds/apps/AppFeed";
import TopAppNewsFeed from "../components/feeds/apps/AppNewsFeed";
import FilmFeed from "../components/feeds/film/FilmFeed";
import TopFilmNewsFeed from "../components/feeds/film/FilmNewsFeed";
import TvFeed from "../components/feeds/television/TvFeed";

export default function TelevisionScreen() {
  const [loading, setLoading] = useState(true);
  const [mostPopular, setMostPopular] = useState([]);
  const [error, setError] = useState(false);
  const [trendingDay, setTrendingDay] = useState([]);
  const [upComing, setUpComing] = useState([]);
  const [trendingWeek, setTrendingWeek] = useState([]);
  const [topRated, setTopRated] = useState([]);
  const [topAction, setTopAction] = useState([]);
  const [topAdventure, setTopAdventure] = useState([]);
  const [topAnimation, setTopAnimation] = useState([]);
  const [topBiography, setTopBiography] = useState([]);
  const [topComedy, setTopComedy] = useState([]);
  const [topCrime, setTopCrime] = useState([]);
  const [topDocumentary, setTopDocumentary] = useState([]);
  const [topDrama, setTopDrama] = useState([]);
  const [topFamily, setTopFamily] = useState([]);
  const [topFantasy, setTopFantasy] = useState([]);
  const [topGameShow, setTopGameShow] = useState([]);
  const [topHistory, setTopHistory] = useState([]);
  const [topHorror, setTopHorror] = useState([]);
  const [topMusic, setTopMusic] = useState([]);
  const [topMusical, setTopMusical] = useState([]);
  const [topMystery, setTopMystery] = useState([]);
  const [topNews, setTopNews] = useState([]);
  const [topReality, setTopReality] = useState([]);
  const [topRomance, setTopRomance] = useState([]);
  const [topSciFi, setTopSciFi] = useState([]);
  const [topShort, setTopShort] = useState([]);
  const [topSport, setTopSport] = useState([]);
  const [topTalkShow, setTopTalkShow] = useState([]);
  const [topThriller, setTopThriller] = useState([]);
  const [topWar, setTopWar] = useState([]);
  const [topWestern, setTopWestern] = useState([]);
  const [topNowPlaying, setNowPlaying] = useState([]);

  //Fetch logic per feed
  const fetchTV = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://youtapia-temp8.uc.r.appspot.com/v1/api/Feed/209,210,211,212,213,214,215,216,217,218,219,220,221,222,223,224,225,226,227,228,229,230,231,232,233,234,235,236,1213,207`,
        {
          method: "GET",
          credentials: "same-origin",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setMostPopular(data[0].cards);
      setTopRated(data[1].cards);
      setTopAction(data[2].cards);
      setTopAdventure(data[3].cards);
      setTopAnimation(data[4].cards);
      setTopBiography(data[5].cards);
      setTopComedy(data[6].cards);
      setTopCrime(data[7].cards);
      setTopDocumentary(data[8].cards);
      setTopDrama(data[9].cards);
      setTopFamily(data[10].cards);
      setTopFantasy(data[11].cards);
      setTopGameShow(data[12].cards);
      setTopHistory(data[13].cards);
      setTopHorror(data[14].cards);
      setTopMusical(data[15].cards);
      setTopMusic(data[16].cards);
      setTopMystery(data[17].cards);
      setTopNews(data[18].cards);
      setTopReality(data[19].cards);
      setTopRomance(data[20].cards);
      setTopSciFi(data[21].cards);
      setTopShort(data[22].cards);
      setTopSport(data[23].cards);
      setTopTalkShow(data[24].cards);
      setTopThriller(data[25].cards);
      setTopWar(data[26].cards);
      setTopWestern(data[27].cards);
      setTopNews(data[28].cards);
      setNowPlaying(data[29].cards);
      setLoading(false);
      setError(false);
      //console.log(data);
      setLoading(false);
      setError(false);
    } catch (e) {
      setError(true);
      setLoading(false);
      //console.error(e);
    }
  };

  //Render Data
  useEffect(() => {
    fetchTV();
  }, []);

  //Error Logic
  if (error) {
    return (
      <Fragment>
        <TopHeader onLogoPress={fetchTV} onRefreshPress={fetchTV} />
        <ScrollView
          key={"App Scrollview"}
          style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 180,
            }}
          >
            <Text>
              Unable to fetch feeds. Please hit app tab icon to refresh
            </Text>
          </View>
        </ScrollView>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <TopHeader onLogoPress={fetchTV} onRefreshPress={fetchTV} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        key={"App Scrollview"}
        style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
        contentContainerStyle={{ alignItems: "center" }}
      >
        <TvFeed
          data={topNowPlaying}
          loading={loading}
          headerTitle={"New Tonight"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={mostPopular}
          loading={loading}
          headerTitle={"Most Popular"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topRated}
          loading={loading}
          headerTitle={"Top Rated"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topAction}
          loading={loading}
          headerTitle={"Top Action"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topAdventure}
          loading={loading}
          headerTitle={"Top Adventure"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topAnimation}
          loading={loading}
          headerTitle={"Top Animation"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topBiography}
          loading={loading}
          headerTitle={"Top Biography"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topComedy}
          loading={loading}
          headerTitle={"Top Comedy"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topCrime}
          loading={loading}
          headerTitle={"Top Crime"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topDocumentary}
          loading={loading}
          headerTitle={"Top Documentary"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topDrama}
          loading={loading}
          headerTitle={"Top Drama"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topFamily}
          loading={loading}
          headerTitle={"Top Family"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topFantasy}
          loading={loading}
          headerTitle={"Top Fantasy"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topGameShow}
          loading={loading}
          headerTitle={"Top Game Show"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topHistory}
          loading={loading}
          headerTitle={"Top History"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topHorror}
          loading={loading}
          headerTitle={"Top Horror"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topMusic}
          loading={loading}
          headerTitle={"Top Music"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topMusical}
          loading={loading}
          headerTitle={"Top Musical"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topMystery}
          loading={loading}
          headerTitle={"Top Mystery"}
          objectType={"TV Series"}
        />
        {/* <TvFeed
            data={topNews}
            loading={loading}
            headerTitle={'Top News'}
            objectType={'TV Series'}
          /> */}
        <TvFeed
          data={topReality}
          loading={loading}
          headerTitle={"Top Reality"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topRomance}
          loading={loading}
          headerTitle={"Top Romance"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topSciFi}
          loading={loading}
          headerTitle={"Top Sci-Fi"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topShort}
          loading={loading}
          headerTitle={"Top Short"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topSport}
          loading={loading}
          headerTitle={"Top Sport"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topTalkShow}
          loading={loading}
          headerTitle={"Top Talk Show"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topThriller}
          loading={loading}
          headerTitle={"Top Thriller"}
          objectType={"TV Series"}
        />
        <TvFeed
          data={topWar}
          loading={loading}
          headerTitle={"Top War"}
          objectType={"TV Series"}
        />

        <TvFeed
          data={topWestern}
          loading={loading}
          headerTitle={"Top Western"}
          objectType={"TV Series"}
        />
        <View style={{ marginBottom: 120 }} />
      </ScrollView>
    </Fragment>
  );
}
const styles = StyleSheet.create({
  bottomWrap: {
    marginBottom: 180,
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 10,
    borderRadius: 30,
    padding: 10,
  },
});
