import * as React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import TrendingScreen from "../pages/Trending";
import ApplicationsScreen from "../pages/Applications";
import BooksScreen from "../pages/Books";
import FilmScreen from "../pages/Film";
import GamingScreen from "../pages/Gaming";
import PodcastsScreen from "../pages/Podcasts";
import ProductsScreen from "../pages/Products";
import MensScreen from "../pages/MensFashion";
import MusicScreen from "../pages/Music";
import WomensScreen from "../pages/WomensFashion";
import TelevisionScreen from "../pages/Television";
import TabBar from "./TabBar";
import LocalScreen from "../pages/Local";

const Tab = createBottomTabNavigator();
const TabNavigator = () => {
  return (
    <Tab.Navigator
      screenOptions={{ headerShown: false }}
      tabBar={(props) => <TabBar {...props} />}
    >
      <Tab.Screen name="Trending" component={TrendingScreen} />
      <Tab.Screen name="Apps" component={ApplicationsScreen} />
      <Tab.Screen name="Books" component={BooksScreen} />
      <Tab.Screen name="Film" component={FilmScreen} />
      <Tab.Screen name="Gaming" component={GamingScreen} />
      <Tab.Screen name="Local" component={LocalScreen} />
      <Tab.Screen name="Mens" component={MensScreen} />
      <Tab.Screen name="Music" component={MusicScreen} />
      <Tab.Screen name="Podcast" component={PodcastsScreen} />
      <Tab.Screen name="Products" component={ProductsScreen} />

      <Tab.Screen name="Womens" component={WomensScreen} />
      <Tab.Screen name="Television" component={TelevisionScreen} />
    </Tab.Navigator>
  );
};

export default TabNavigator;
