import React, { useCallback } from "react";
import { FlatList, View, Text, TouchableOpacity } from "react-native";
import "react-loading-skeleton/dist/skeleton.css";

//styles
import styles from "../../../../styles/filter.feed.style";
import BreakingFilterLoading from "../breaking/breaking.feed.loading";
import { MemoizedFilmCard } from "../../../cards/film/FilmCard";

function FilmFilterFeed({
  //data
  topFilmsData,
  topBoxOfficeData,
  upComingSoonData,

  //error
  error,

  //onPress
  onTopFilmsPress,
  onBoxOfficePress,
  onComingSoonPress,

  //loading
  loading,

  //show
  showTopFilms,
  showBoxOffice,
  showComingSoon,
}) {
  const renderItem = ({ item }) => <MemoizedFilmCard film={item} />;

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const ITEM_HEIGHT = 350;

  const getItemLayout = useCallback(
    (data, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    []
  );

  if (loading) {
    return <BreakingFilterLoading />;
  }

  if (error === true) {
    return null;
  }
  return (
    <View style={styles.wrap}>
    <View>
      <Text style={styles.headerText}>FILM</Text>
    </View>
    <View style={styles.headerWrap}>
      <View style={styles.filterButtonWrap}>
          <TouchableOpacity
            onPress={onTopFilmsPress}
            style={[
              styles.button,
              {
                backgroundImage: showTopFilms
                  ? "linear-gradient(to top right, rgba(0,0,0) 60%,rgb(247, 220, 111  ))"
                  : null,
                shadowOffset: showTopFilms
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showTopFilms ? 0.98 : 0,
                shadowRadius: showTopFilms ? 3.0 : 0,
                elevation: showTopFilms ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[styles.header, { color: showTopFilms ? "#fff" : "#fff" }]}
            >
              Trending
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onBoxOfficePress}
            style={[
              styles.button,
              {
                backgroundImage: showBoxOffice
                  ? "linear-gradient(to top right, rgba(0,0,0), rgba(91, 44, 111))"
                  : null,
                shadowOffset: showBoxOffice
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showBoxOffice ? 0.98 : 0,
                shadowRadius: showBoxOffice ? 3.0 : 0,
                elevation: showBoxOffice ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showBoxOffice ? "#fff" : "#fff" },
              ]}
            >
              Box Office
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={onComingSoonPress}
            style={[
              styles.button,
              {
                backgroundImage: showComingSoon
                  ? "linear-gradient(to top right, rgba(0,0,0) 44%, rgba(27, 79, 114))"
                  : null,
                shadowOffset: showComingSoon
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showComingSoon ? 0.98 : 0,
                shadowRadius: showComingSoon ? 3.0 : 0,
                elevation: showComingSoon ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[
                styles.header,
                { color: showComingSoon ? "#fff" : "#fff" },
              ]}
            >
              Coming Soon
            </Text>
          </TouchableOpacity>
        </View>

        {/* <TouchableOpacity
                    onPress={onRedditPress}
                    style={[styles.button,]}
                >
                    <Text style={[styles.header, { color: showReddit ? '#000' : '#dadada' }]}>Reddit</Text>
                </TouchableOpacity> */}
      </View>
      {showTopFilms && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={topFilmsData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showBoxOffice && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={topBoxOfficeData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          //keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showComingSoon && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={upComingSoonData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          //keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
    </View>
  );
}

export default FilmFilterFeed;
