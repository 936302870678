import { StyleSheet } from "react-native";

export default StyleSheet.create({
  authorText: {
    color: "#DADADA",
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 14,
    padding: 6,
  },
  bottomContainer: {
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
  },
  image: {
    borderRadius: 20,
    height: 150,
    width: 140,
    marginRight: 8,
  },
  linkContainer: {
    borderRadius: 9,
    width: 210,
    paddingLeft: 12,
  },
  linearGradientContainer: {
    paddingHorizontal: 10,
    borderRadius: 20,
    elevation: 6,
    backgroundImage: "linear-gradient(to right, rgba(0,0,0), rgb(75,0,130))",
    height: 220,
    margin: 6,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.58,
    shadowRadius: 2.0,
  },

  title: {
    color: "#FFF",
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 16,
    paddingVertical: 12,
    width: 220,
    textAlign: "center",
  },
  type: {
    color: "#dadada",
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 12,
    width: 180,
    textAlign: "center",
  },
  linksText: {
    color: "#fff",
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 10,
    paddingLeft: 12,
  },
  titleWrap: {
    marginTop: 30,
    height: 80,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});
