import React from "react";
import { StyleSheet, TouchableOpacity, Text } from "react-native";
import {
  faTv,
  faBolt,
  faBook,
  faPizzaSlice,
  faMicrophone,
  faGuitar,
  faTags,
  faVideo,
  faGamepad,
  faMobile,
  faFemale,
  faMale,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

const Tab = ({ color, tab, onPress, icon, name, active, type, index }) => {
  if (tab.name === "Trending") {
    return (
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <FontAwesomeIcon
          icon={faBolt}
          color={tab.name === active ? "#FFFD37" : "#fff"}
          size={tab.name === active ? 36 : 28}
        />
        <Text
          style={{
            color,
            fontFamily: "AvenirNextCondensed-Bold",
            fontSize: 12,
            paddingTop: 3,
          }}
        >
          {tab.name}
        </Text>
      </TouchableOpacity>
    );
  }
  if (tab.name === "Apps") {
    return (
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <FontAwesomeIcon
          icon={faMobile}
          color={tab.name === active ? "#FFFD37" : "#fff"}
          size={tab.name === active ? 36 : 28}
        />
        <Text
          style={{
            color,
            fontFamily: "AvenirNextCondensed-Bold",
            fontSize: 12,
            paddingTop: 3,
          }}
        >
          {tab.name}
        </Text>
      </TouchableOpacity>
    );
  }
  if (tab.name === "Books") {
    return (
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <FontAwesomeIcon
          icon={faBook}
          color={tab.name === active ? "#FFFD37" : "#fff"}
          size={tab.name === active ? 36 : 24}
        />
        <Text
          style={{
            color,
            fontFamily: "AvenirNextCondensed-Bold",
            fontSize: 12,
            paddingTop: 3,
          }}
        >
          {tab.name}
        </Text>
      </TouchableOpacity>
    );
  }
  if (tab.name === "Film") {
    return (
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <FontAwesomeIcon
          icon={faVideo}
          color={tab.name === active ? "#FFFD37" : "#fff"}
          size={tab.name === active ? 36 : 28}
        />
        <Text
          style={{
            color,
            fontFamily: "AvenirNextCondensed-Bold",
            fontSize: 12,
            paddingTop: 3,
          }}
        >
          {tab.name}
        </Text>
      </TouchableOpacity>
    );
  }
  if (tab.name === "Gaming") {
    return (
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <FontAwesomeIcon
          icon={faGamepad}
          color={tab.name === active ? "#FFFD37" : "#fff"}
          size={tab.name === active ? 36 : 24}
        />
        <Text
          style={{
            color,
            fontFamily: "AvenirNextCondensed-Bold",
            fontSize: 12,
            paddingTop: 3,
          }}
        >
          {tab.name}
        </Text>
      </TouchableOpacity>
    );
  }
  if (tab.name === "Local") {
    return (
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <FontAwesomeIcon
          icon={faPizzaSlice}
          color={tab.name === active ? "#FFFD37" : "#fff"}
          size={tab.name === active ? 36 : 28}
        />
        <Text
          style={{
            color,
            fontFamily: "AvenirNextCondensed-Bold",
            fontSize: 12,
            paddingTop: 3,
          }}
        >
          {tab.name}
        </Text>
      </TouchableOpacity>
    );
  }
  if (tab.name === "Mens") {
    return (
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <FontAwesomeIcon
          icon={faMale}
          color={tab.name === active ? "#FFFD37" : "#fff"}
          size={tab.name === active ? 36 : 28}
        />
        <Text
          style={{
            color,
            fontFamily: "AvenirNextCondensed-Bold",
            fontSize: 12,
            paddingTop: 3,
          }}
        >
          {tab.name}
        </Text>
      </TouchableOpacity>
    );
  }
  if (tab.name === "Music") {
    return (
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <FontAwesomeIcon
          icon={faGuitar}
          color={tab.name === active ? "#FFFD37" : "#fff"}
          size={tab.name === active ? 36 : 28}
        />
        <Text
          style={{
            color,
            fontFamily: "AvenirNextCondensed-Bold",
            fontSize: 12,
            paddingTop: 3,
          }}
        >
          {tab.name}
        </Text>
      </TouchableOpacity>
    );
  }
  if (tab.name === "Podcast") {
    return (
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <FontAwesomeIcon
          icon={faMicrophone}
          color={tab.name === active ? "#FFFD37" : "#fff"}
          size={tab.name === active ? 36 : 28}
        />
        <Text
          style={{
            color,
            fontFamily: "AvenirNextCondensed-Bold",
            fontSize: 12,
            paddingTop: 3,
          }}
        >
          {tab.name}
        </Text>
      </TouchableOpacity>
    );
  }
  if (tab.name === "Products") {
    return (
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <FontAwesomeIcon
          icon={faTags}
          color={tab.name === active ? "#FFFD37" : "#fff"}
          size={tab.name === active ? 36 : 28}
        />
        <Text
          style={{
            color,
            fontFamily: "AvenirNextCondensed-Bold",
            fontSize: 12,
            paddingTop: 3,
          }}
        >
          {tab.name}
        </Text>
      </TouchableOpacity>
    );
  }
  if (tab.name === "Womens") {
    return (
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <FontAwesomeIcon
          icon={faFemale}
          color={tab.name === active ? "#FFFD37" : "#fff"}
          size={tab.name === active ? 36 : 28}
        />
        <Text
          style={{
            color,
            fontFamily: "AvenirNextCondensed-Bold",
            fontSize: 12,
            paddingTop: 3,
          }}
        >
          {tab.name}
        </Text>
      </TouchableOpacity>
    );
  }
  if (tab.name === "Television") {
    return (
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <FontAwesomeIcon
          icon={faTv}
          color={tab.name === active ? "#FFFD37" : "#fff"}
          size={tab.name === active ? 36 : 28}
        />
        <Text
          style={{
            color,
            fontFamily: "AvenirNextCondensed-Bold",
            fontSize: 12,
            paddingTop: 3,
          }}
        >
          {tab.name}
        </Text>
      </TouchableOpacity>
    );
  } else {
    return null;
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-evenly",
    width: 70,
  },
});

export default Tab;
