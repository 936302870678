import React, { useState, useEffect, Fragment } from "react";
import { ScrollView, Text, StyleSheet, View } from "react-native";
import TopHeader from "../navigation/TopHeader";
import AppFeed from "../components/feeds/apps/AppFeed";
import TopAppNewsFeed from "../components/feeds/apps/AppNewsFeed";
import FilmFeed from "../components/feeds/film/FilmFeed";
import TopFilmNewsFeed from "../components/feeds/film/FilmNewsFeed";
import MusicFeed from "../components/feeds/music/MusicFeed";
import TopYouTubeFeed from "../components/feeds/trending/TopYouTubeFeed";
import TopMusicVideosFeed from "../components/feeds/music/TopMusicVideosFeed";

export default function MusicScreen() {
  const [loading, setLoading] = useState(true);
  const [newMusic, setNewMusic] = useState(false);
  const [error, setError] = useState(false);
  const [isMusicScreenActive, setIsMusicScreenActive] = useState(true);
  const [topSongs, setTopsongs] = useState([]);
  const [topAlbums, setTopAlbums] = useState([]);
  const [topArtists, setTopArtists] = useState([]);
  const [topMusicVideos, setTopMusicVideos] = useState([]);
  const [topAlternative, setTopAlternative] = useState([]);
  const [topBlues, setTopBlues] = useState([]);
  const [topChildrenMusic, setTopChildrenMusic] = useState([]);
  const [topChristian, setTopChristian] = useState([]);
  const [topClassical, setTopClassical] = useState([]);
  const [topComedy, setTopComedy] = useState([]);
  const [topCountry, setTopCountry] = useState([]);
  const [topDance, setTopDance] = useState([]);
  const [topElectronic, setTopElectronic] = useState([]);
  const [topHipHop, setTopHipHop] = useState([]);
  const [topJazz, setTopJazz] = useState([]);
  const [topKPop, setTopKPop] = useState([]);
  const [topLatin, setTopLatin] = useState([]);
  const [topMetal, setTopMetal] = useState([]);
  const [topPop, setTopPop] = useState([]);
  const [topSoul, setTopSoul] = useState([]);
  const [topReggae, setTopReggae] = useState([]);
  const [topRock, setTopRock] = useState([]);
  const [topSingerSongwriter, setTopSingerSongwriter] = useState([]);
  const [topSoundtrack, setTopSoundtrack] = useState([]);
  const [topWorldwide, setTopWorldwide] = useState([]);
  const [topMusicNews, setTopMusicNews] = useState([]);
  const [topMusicYouTube, setTopMusicYouTube] = useState([]);

  //Fetch logic per feed
  const fetchMusic = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://youtapia-temp8.uc.r.appspot.com/v1/api/feed/1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,1214,1104?pageNumber=0&pageSize=15`,
        {
          method: "GET",
          credentials: "same-origin",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setTopsongs(data[0].cards);
      setTopAlbums(data[1].cards);
      setTopAlternative(data[2].cards);
      setTopBlues(data[3].cards);
      setTopChildrenMusic(data[4].cards);
      setTopChristian(data[5].cards);
      setTopClassical(data[6].cards);
      setTopComedy(data[7].cards);
      setTopCountry(data[8].cards);
      setTopDance(data[9].cards);
      setTopElectronic(data[10].cards);
      setTopHipHop(data[11].cards);
      setTopJazz(data[12].cards);
      setTopKPop(data[13].cards);
      setTopLatin(data[14].cards);
      setTopMetal(data[15].cards);
      setTopPop(data[16].cards);
      setTopSoul(data[17].cards);
      setTopReggae(data[18].cards);
      setTopRock(data[19].cards);
      setTopSingerSongwriter(data[20].cards);
      setTopSoundtrack(data[21].cards);
      setTopWorldwide(data[22].cards);
      setTopMusicNews(data[23].cards);
      setTopMusicYouTube(data[24].cards);
      setError(false);
      console.log(data);
      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
      // console.error(e);
    }
  };

  //Render Data
  useEffect(() => {
    fetchMusic();
    console.log("my effect is running");
  }, []);

  //Error Logic
  if (error) {
    return (
      <Fragment>
        <TopHeader onLogoPress={fetchMusic} onRefreshPress={fetchMusic} />
        <ScrollView
          style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
          contentContainerStyle={{ alignItems: "center" }}
          automaticallyAdjustContentInsets={false}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 180,
            }}
          >
            <Text>
              Unable to fetch feeds. Please hit app tab icon to refresh
            </Text>
          </View>
        </ScrollView>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <TopHeader onLogoPress={fetchMusic} onRefreshPress={fetchMusic} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, backgroundColor: "#FFF", paddingTop: 80 }}
        contentContainerStyle={{ alignItems: "center" }}
      >
        <TopMusicVideosFeed
          data={topMusicYouTube}
          isError={error}
          isLoading={loading}
          headerTitle="Popular Videos"
        />
        <MusicFeed
          data={topSongs}
          isError={error}
          isLoading={loading}
          headerTitle="Top 100"
        />
        <MusicFeed
          data={topAlbums}
          isError={error}
          isLoading={loading}
          headerTitle="Top Albums"
        />
        <MusicFeed
          data={topAlternative}
          isError={error}
          isLoading={loading}
          headerTitle="Top Alternative"
        />
        <MusicFeed
          data={topBlues}
          isError={error}
          isLoading={loading}
          headerTitle="Top Blues"
        />
        <MusicFeed
          data={topChildrenMusic}
          isError={error}
          isLoading={loading}
          headerTitle="Top Children"
        />
        <MusicFeed
          data={topChristian}
          isError={error}
          isLoading={loading}
          headerTitle="Top Christian"
        />
        <MusicFeed
          data={topClassical}
          isError={error}
          isLoading={loading}
          headerTitle="Top Classical"
        />
        <MusicFeed
          data={topComedy}
          isError={error}
          isLoading={loading}
          headerTitle="Top Comedy"
        />
        <MusicFeed
          data={topCountry}
          isError={error}
          isLoading={loading}
          headerTitle="Top Country"
        />
        <MusicFeed
          data={topDance}
          isError={error}
          isLoading={loading}
          headerTitle="Top Dance"
        />
        <MusicFeed
          data={topElectronic}
          isError={error}
          isLoading={loading}
          headerTitle="Top Electronic"
        />
        <MusicFeed
          data={topHipHop}
          isError={error}
          isLoading={loading}
          headerTitle="Top Hip Hop"
        />
        <MusicFeed
          data={topJazz}
          isError={error}
          isLoading={loading}
          headerTitle="Top Jazz"
        />
        <MusicFeed
          data={topKPop}
          isError={error}
          isLoading={loading}
          headerTitle="Top KPop"
        />
        <MusicFeed
          data={topLatin}
          isError={error}
          isLoading={loading}
          headerTitle="Top Latin"
        />
        <MusicFeed
          data={topMetal}
          isError={error}
          isLoading={loading}
          headerTitle="Top Metal"
        />
        <MusicFeed
          data={topPop}
          isError={error}
          isLoading={loading}
          headerTitle="Top Pop"
        />
        <MusicFeed
          data={topReggae}
          isError={error}
          isLoading={loading}
          headerTitle="Top Reggae"
        />
        <MusicFeed
          data={topRock}
          isError={error}
          isLoading={loading}
          headerTitle="Top Rock"
        />
        <MusicFeed
          data={topSingerSongwriter}
          isError={error}
          isLoading={loading}
          headerTitle="Top Singer Songwriter"
        />
        <MusicFeed
          data={topSoul}
          isError={error}
          isLoading={loading}
          headerTitle="Top Soul"
        />
        <MusicFeed
          data={topSoundtrack}
          isError={error}
          isLoading={loading}
          headerTitle="Top Soundtrack"
        />

        <MusicFeed
          data={topWorldwide}
          isError={error}
          isLoading={loading}
          headerTitle="Top Worldwide"
        />
        <View style={{ marginBottom: 120 }} />
      </ScrollView>
    </Fragment>
  );
}
const styles = StyleSheet.create({
  bottomWrap: {
    marginBottom: 180,
    backgroundColor: "#fff",
    width: "98%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.98,
    shadowRadius: 9.0,
    elevation: 8,
    margin: 10,
    borderRadius: 30,
    padding: 10,
  },
});
