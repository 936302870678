import React from "react";
import { Linking, TouchableOpacity, ScrollView, View } from "react-native";
import styles from "./linker.styles";
import Linker from "./linker";
//Navigation
import { useNavigation } from "@react-navigation/native";

const LinkerView = ({ links }) => {
  const navigation = useNavigation();
  return (
    <>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        nestedScrollEnabled={true}
        style={styles.linkContainer}
      >
        {links.map((link, index) => {
          if (
            link.type === "AmazonPrime" ||
            link.type === "Vudu" ||
            link.type === "iTunes" ||
            link.type === "GooglePlay" ||
            link.type === "Spotify" ||
            link.type === "NetFlix" ||
            link.type === "AppleTv"
          ) {
            return (
              <TouchableOpacity
                key={index.toString()}
                onPress={() => {
                  Linking.openURL(`${link.link}`);
                }}
                style={{ paddingVertical: 10, paddingHorizontal: 4 }}
              >
                <Linker link={link.type} />
              </TouchableOpacity>
            );
          } else {
            return (
              <TouchableOpacity
                key={index.toString()}
                onPress={() =>
                  navigation.navigate("Test", {
                    link: link.link,
                  })
                }
                style={{ paddingVertical: 10, paddingHorizontal: 4 }}
              >
                <Linker link={link.type} />
              </TouchableOpacity>
            );
          }
        })}
      </ScrollView>
    </>
  );
};

export default LinkerView;
