import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import TabNavigator from "./navigation/TabNavigator";
import useGeoLocation from "./hooks/useGeolocation";
import LocationContext from "./components/context/Location";
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-252589791-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);
export default function App() {
  const location = useGeoLocation();
  //console.log('loc', location)
  return (
    <LocationContext.Provider value={location}>
      <NavigationContainer>
        <TabNavigator />
      </NavigationContainer>
    </LocationContext.Provider>
  );
}
