import React, { Fragment } from "react";
import { View } from "react-native";
import styles from "./breaking.feed.style";
import Skeleton from "react-loading-skeleton";

function BreakingFilterLoading() {
  return (
    <Fragment>
      <View style={styles.loadingWrap}>
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={100}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={100}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
        <Skeleton
          height={200}
          width={200}
          baseColor="#000"
          style={{ margin: 2 }}
        />
      </View>
    </Fragment>
  );
}
export default BreakingFilterLoading;
