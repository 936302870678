import React from "react";
import {
  Linking,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

//components
import Linker from "../../linking/linker";

//navigation
import { useNavigation } from "@react-navigation/native";

//style

import { MemoizedTwitterCard } from "../news/TwitterHashtagCard";
import { MemoizedYouTubeCard } from "../video/YouTubeCard";
import { MemoizedRedditCard } from "../news/RedditCard";
import { MemoizedWikipediaCard } from "../news/WikipediaCard";
import { MemoizedBannerCard } from "../banner/BannerCard";
import { MemoizedUniversalCard } from "./UniversalAppCard";
import { MemoizedAppCard } from "../app/AppCard";

function UniversalSocialCard({ card, loading, onPress }) {
  const navigation = useNavigation();

  if (card.feedId === 1211) {
    return <MemoizedTwitterCard hashtag={card} />;
  }
  if (card.feedId === 1101) {
    return <MemoizedYouTubeCard video={card} />;
  }
  if (card.feedId === 1219) {
    return <MemoizedRedditCard reddit={card} />;
  }
  if (card.feedId === 1222) {
    return <MemoizedWikipediaCard wikipedia={card} />;
  }
  if (card.feedId === 100015) {
    return <MemoizedBannerCard banner={card} />;
  }
  if (card.feedId === 99901 || 99902) {
    return <MemoizedUniversalCard card={card} />;
  }
  if (card.feedId === 88800) {
    return <MemoizedAppCard app={card} />;
  } else {
    return null;
  }
}

export const MemoizedUniversalSocialCard = React.memo(UniversalSocialCard);
