import React, { useCallback, Fragment } from "react";
import { FlatList, View, Text, TouchableOpacity } from "react-native";
import "react-loading-skeleton/dist/skeleton.css";

//styles
import styles from "../../../../styles/filter.feed.style";
import BreakingFilterLoading from "../breaking/breaking.feed.loading";
import { MemoizedUniversalSocialCard } from "../../../cards/universal/UniversalSocialCard";
import { MemoizedAppCard } from "../../../cards/app/AppCard";

function AppFilterFeed({
  //data
  topAppsData,
  freeAppsData,
  paidAppsData,

  //error
  error,

  //onPress
  onTopAppsPress,
  onFreeAppsPress,
  onPaidAppsPress,

  //loading
  loading,

  //show
  showTopApps,
  showFreeApps,
  showPaidApps,
}) {
  const renderItem = ({ item }) => <MemoizedAppCard app={item} />;

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const ITEM_HEIGHT = 350;

  const getItemLayout = useCallback(
    (data, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    []
  );

  if (loading) {
    return <BreakingFilterLoading />;
  }

  if (error === true) {
    return null;
  }
  return (
    <View style={styles.wrap}>
      <View>
        <Text style={styles.headerText}>APPS</Text>
      </View>
      <View style={styles.headerWrap}>
        <View style={styles.filterButtonWrap}>
          <TouchableOpacity
            onPress={onTopAppsPress}
            style={[
              styles.button,
              {
                backgroundImage: showTopApps
                  ? "linear-gradient(to top right, rgba(0,0,0) 60%,rgb(247, 220, 111  ))"
                  : null,
                shadowOffset: showTopApps
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showTopApps ? 0.98 : 0,
                shadowRadius: showTopApps ? 3.0 : 0,
                elevation: showTopApps ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[styles.header, { color: showTopApps ? "#fff" : "#fff" }]}
            >
              Trending
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={onFreeAppsPress}
            style={[
              styles.button,
              {
                backgroundImage: showFreeApps
                  ? "linear-gradient(to top right, rgba(0,0,0), rgba(91, 44, 111))"
                  : null,
                shadowOffset: showFreeApps
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showFreeApps ? 0.98 : 0,
                shadowRadius: showFreeApps ? 3.0 : 0,
                elevation: showFreeApps ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[styles.header, { color: showFreeApps ? "#fff" : "#fff" }]}
            >
              Top Free
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={onPaidAppsPress}
            style={[
              styles.button,
              {
                backgroundImage: showPaidApps
                  ? "linear-gradient(to top right, rgba(0,0,0) 44%, rgba(27, 79, 114))"
                  : null,
                shadowOffset: showPaidApps
                  ? {
                      width: 0,
                      height: 1,
                    }
                  : {
                      width: 0,
                      height: 0,
                    },
                shadowOpacity: showPaidApps ? 0.98 : 0,
                shadowRadius: showPaidApps ? 3.0 : 0,
                elevation: showPaidApps ? 2 : 0,
              },
            ]}
          >
            <Text
              style={[styles.header, { color: showPaidApps ? "#fff" : "#fff" }]}
            >
              Top Paid
            </Text>
          </TouchableOpacity>
        </View>

        {/* <TouchableOpacity
                    onPress={onRedditPress}
                    style={[styles.button,]}
                >
                    <Text style={[styles.header, { color: showReddit ? '#000' : '#dadada' }]}>Reddit</Text>
                </TouchableOpacity> */}
      </View>
      {showTopApps && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={topAppsData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showFreeApps && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={freeAppsData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          //keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
      {showPaidApps && (
        <FlatList
          disableVirtualization={true}
          horizontal
          showsHorizontalScrollIndicator={false}
          decelerationRate={0}
          bounces={false}
          renderToHardwareTextureAndroid
          data={paidAppsData}
          indicatorStyle
          getItemLayout={getItemLayout}
          //initialNumToRender={15}
          //maxToRenderPerBatch={10}
          removeClippedSubviews={true}
          scrollEventThrottle={16}
          //windowSize={50}
          lazy={false}
          //keyExtractor={keyExtractor}
          renderItem={renderItem}
        />
      )}
    </View>
  );
}

export default AppFilterFeed;
