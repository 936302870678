import {Platform, StyleSheet} from 'react-native';

export default StyleSheet.create({
    wrap: {
        backgroundColor: "#fff",
        width: "98%",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.98,
        shadowRadius: 9.0,
        elevation: 8,
        margin: 10,
        borderRadius: 30,
        padding: 10,
      },
      quickLinksWrap: {
        backgroundColor: "#fff",
        //width:'98%',
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.98,
        shadowRadius: 9.0,
        elevation: 8,
        margin: 10,
        borderRadius: 30,
        padding: 10,
        // justifyContent: "center",
        // alignItems: "center",
      },
      youtubeWrap: {
        backgroundColor: "#fff",
        width: "98%",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.98,
        shadowRadius: 9.0,
        elevation: 8,
        margin: 10,
        borderRadius: 30,
        padding: 10,
      },
      bottomWrap: {
        marginBottom: 180,
        backgroundColor: "#fff",
        width: "98%",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.98,
        shadowRadius: 9.0,
        elevation: 8,
        margin: 10,
        borderRadius: 30,
        padding: 10,
      },
      title: {
        fontFamily: "AvenirNextCondensed-Bold",
        fontSize: 18,
        color: "#FFF",
        padding: 6,
      },
      header: {
        fontFamily: "AvenirNextCondensed-Bold",
        fontSize: 18,
        color: "#393d3d",
      },
      titleWrap: {
        alignItems: "center",
        alignContent: "center",
      },
      headerWrap: {
        paddingLeft: 20,
      },
      centeredView: {
        flex: 1,
        backgroundColor: "#00000099",
        justifyContent: "center",
        alignItems: "center",
      },
      modalView: {
        width: "100%",
        height: 430,
        backgroundColor: "transparent",
        padding: 5,
        alignItems: "center",
      },
      button: {
        borderRadius: 20,
        //width: 100,
        padding: 10,
        backgroundImage: "linear-gradient(to right, rgba(0,0,0) ,rgba(255,0,0,1))",
        elevation: 2,
      },
      televisonButton: {
        borderRadius: 20,
        //width: 100,
        padding: 10,
        backgroundImage: "linear-gradient(to right, rgba(0,0,0) ,	rgba(138,43,226))",
        elevation: 2,
      },
      songButton: {
        borderRadius: 20,
        //width: 100,
        padding: 10,
        backgroundImage: "linear-gradient(to right, rgba(0,0,0) ,	rgba(0,255,255)))",
        elevation: 2,
      },
      productButton: {
        borderRadius: 20,
    
        padding: 10,
        backgroundImage:
          "linear-gradient(to right, rgba(0,0,0) 60%,rgba(255, 87, 51))",
        elevation: 2,
      },
      buttonOpen: {
        backgroundColor: "#000",
      },
      buttonClose: {
        backgroundColor: "#000",
      },
      textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
      },
      modalText: {
        //marginBottom: 15,
        textAlign: "center",
      },
});