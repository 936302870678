import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Linking,
  Image,
} from "react-native";
import { useNavigation } from "@react-navigation/native";

function GoogleCard({ google }) {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      // onPress={() =>
      //   navigation.navigate('Test', {
      //     link: video.links[0].link,
      //   })
      // }
      onPress={() => {
        Linking.openURL(`https://www.google.com/search?q=${google.title}`);
      }}
    >
      <View style={styles.linearGradientContainer}>
        <View style={styles.bottomContainer}>
          <Image
            style={styles.image}
            source={{
              uri: google.imageUrl ? google.imageUrl : "",
            }}
          />
          <View style={styles.titleWrap}>
            <Text style={styles.title} numberOfLines={2}>
              {google.title}
            </Text>
            <View style={{ width: 180 }}>
              <Text style={styles.type} numberOfLines={1}>
                {google.description}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  bottomContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    paddingHorizontal: 10,
    paddingVertical: 15,
    borderRadius: 20,
  },
  image: {
    borderRadius: 20,
    height: 80,
    width: 80,
  },
  linearGradientContainer: {
    alignItems: "center",
    alignContent: "center",
    backgroundImage:
      "linear-gradient(to top right, rgba(0,0,0) 30%, rgba(15,157,88, 1) 73%, rgb(66,133,244), rgba(255,0,0,1)  )",
    borderRadius: 20,
    elevation: 6,
    height: 120,
    margin: 6,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.58,
    shadowRadius: 2.0,
  },
  title: {
    color: "#FFF",
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 16,
    padding: 12,
    width: 240,
    textAlign: "center",
  },
  type: {
    color: "#dadada",
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 12,
    width: 200,
    textAlign: "center",
  },
  titleWrap: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
  },
});

export const MemoizedGoogleCard = React.memo(GoogleCard);
