import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Linking,
  Image,
  ImageBackground,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import moment from "moment";

function EventCard({ event }) {
  const navigation = useNavigation();

  const startDate = moment(event.eventStartDate).format("MMMM Do YYYY, h:mm a");
  const image = { uri: event.imageUrl };
  return (
    <TouchableOpacity
      onPress={() => {
        Linking.openURL(`${event.links[0].link}`);
      }}
      style={styles.descriptionContainer}
    >
      <ImageBackground source={image} resizeMode="cover" style={styles.image}>
        <View style={styles.bottomContainer}>
          {/* <FastImage
          style={styles.image}
          source={{
            uri: event.imageUrl,
            priority: FastImage.priority.high,
          }}
        /> */}
          <View style={styles.titleWrap}>
            <Text style={styles.title} numberOfLines={3}>
              {event.title}
            </Text>
            <Text style={styles.type} numberOfLines={2}>
              {event.eventLocation}
            </Text>
            <Text style={styles.date} numberOfLines={2}>
              {startDate}
            </Text>
          </View>
          {/* <ScrollView 
                    horizontal 
                    showsHorizontalScrollIndicator={false} 
                    style={styles.linkContainer}
                >
                    {video.links.map((link, index) => {
                        return (
                            <TouchableOpacity key={index.toString()} onPress={ ()=>{ Linking.openURL(`${link.link}`)}}  style={{padding: 2}}>
                                <Linker link={link.type} />
                            </TouchableOpacity>
                            )
                    })}
                </ScrollView> */}
        </View>
      </ImageBackground>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  title: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 18,
    color: "#FFF",
    paddingTop: 12,
    paddingLeft: 14,
    paddingRight: 14,
    width: 240,
  },
  header: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 16,
    color: "#dadada",
    padding: 6,
  },
  titleWrap: {},
  headerWrap: {
    marginTop: 20,
    paddingLeft: 20,
  },
  type: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 12,
    color: "#DADADA",
    paddingLeft: 14,
    width: 200,
    paddingTop: 10,
  },
  date: {
    fontFamily: "AvenirNextCondensed-Bold",
    fontSize: 12,
    color: "grey",
    paddingLeft: 14,
    paddingTop: 15,
    width: 200,
  },
  image: {
    height: 250,
    width: 250,
    flex: 1,
  },
  descriptionContainer: {
    alignItems: "center",
    alignContent: "center",
    margin: 6,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.58,
    shadowRadius: 2.0,
    elevation: 6,
  },
  linkContainer: {
    // padding: 12,
  },
  bottomContainer: {
    flexDirection: "row",
    paddingHorizontal: 10,
    paddingVertical: 15,
    backgroundColor: "#000000c0",
    height: 250,
    width: 250,
    alignItems: "center",
    alignContent: "center",
  },
});

export const MemoizedEventCard = React.memo(EventCard);
